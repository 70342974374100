import React from 'react';
import MaterialDialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/es/DialogActions/DialogActions';
import DialogTitle from '@material-ui/core/es/DialogTitle/DialogTitle';
import {useTranslation} from 'react-i18next';
import {CloseButton, AddButton, CancelButton, CustomCircularProgress} from '..';
import styles from './Dialog.module.css';

type Props = {
    dialogTitle: string;
    submitButtonText?: string;
    submitButtonDisabled?: boolean;
    onSubmitHandler: () => void;
    handleClose: () => void;
    loading?: boolean;
    open?: boolean;
    children: React.ReactNode;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    dialogTitleRightElement?: React.ReactNode;
    cancelButtonVisible?: boolean;
    confirmButtonVisible?: boolean;
};

const Dialog = React.forwardRef<'div', Props>(
    (
        {cancelButtonVisible = true, confirmButtonVisible = true, ...props},
        ref,
    ) => {
        const {t} = useTranslation('common');

        return (
            <MaterialDialog
                classes={{
                    paper: styles.window,
                }}
                ref={ref}
                fullWidth={props.fullWidth ?? true}
                maxWidth={props.maxWidth || 'sm'}
                open={props.open ?? true}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                {props.loading && <CustomCircularProgress />}
                <CloseButton onClick={props.handleClose} />
                <DialogTitle id="form-dialog-title" className={styles.title}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {props.dialogTitle}
                        {props.dialogTitleRightElement &&
                            props.dialogTitleRightElement}
                    </div>
                </DialogTitle>
                <hr className={styles.hrInMobileForm} />
                <DialogContent className={styles.content}>
                    {props.children}
                </DialogContent>
                <DialogActions className={styles.actions}>
                    {cancelButtonVisible && (
                        <CancelButton
                            text={t('dialog.cancelBtn')}
                            onClick={props.handleClose}
                        />
                    )}
                    {confirmButtonVisible && (
                        <AddButton
                            text={
                                props.submitButtonText
                                    ? props.submitButtonText
                                    : t('dialog.submitBtn')
                            }
                            disabled={props.submitButtonDisabled}
                            onClick={props.onSubmitHandler}
                        />
                    )}
                </DialogActions>
            </MaterialDialog>
        );
    },
);

export default Dialog;
