export const ISSUES_REPORT = 'issuesReport';
export const CREATE_NEW_USER = 'createNewUser';
export const DELETE_USER = 'deleteSelectedUser';
export const UPDATE_USER_PASSWORD = 'updateSelectedUserPassword';
export const GET_USERS = 'getUsers';
export const UPDATE_ISSUE = 'updateIssue';
export const IMPORT_USERS = 'importUsers';
export const IMPORT_ENVIRONMENT = 'importEnvironment';
export const UPDATE_ENVIRONMENT = 'updateEnvironment';
export const DELETE_ENVIRONMENT = 'deleteEnvironment';
