import firebase from 'firebase/app';
import * as endpoints from '../../constants/endpoints';

export const updateCategory = (categoryId, category) =>
    firebase
        .firestore()
        .collection(endpoints.categories())
        .doc(categoryId)
        .update(category);

export const updateCategoryNameInUsers = (oldCategory, category, uids) =>
    deleteCategoryFromUsers(oldCategory, uids).then(() =>
        addCategoryToUsers(category, uids),
    );

export const updateCategoryNameInIssues = async (
    oldCategory,
    categorytoSet,
) => {
    const issues = await firebase
        .firestore()
        .collection(endpoints.issues())
        .where('category', '==', {name: oldCategory.name, id: oldCategory.id})
        .get();

    return Promise.all(
        issues.docs.map(issue =>
            firebase
                .firestore()
                .collection(endpoints.issues())
                .doc(issue.id)
                .update({
                    category: {id: oldCategory.id, name: categorytoSet.name},
                }),
        ),
    );
};

export const addCategory = category =>
    firebase.firestore().collection(endpoints.categories()).add(category);

export const deleteCategory = category =>
    firebase
        .firestore()
        .collection(endpoints.categories())
        .doc(category.id)
        .delete();

export const addCategoryToUsers = (category, uids) =>
    Promise.all(
        uids.map(uid =>
            endpoints
                .users()
                .doc(uid)
                .update({
                    categories:
                        firebase.firestore.FieldValue.arrayUnion(category),
                }),
        ),
    );

export const deleteCategoryInUser = (uid, category) =>
    endpoints
        .users()
        .doc(uid)
        .update({
            categories: firebase.firestore.FieldValue.arrayRemove(category),
        });

export const deleteCategoryFromUsers = (category, uids) =>
    Promise.all(uids.map(uid => deleteCategoryInUser(uid, category)));
