import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {BackNavigation} from '../../common/components/index';
import {users} from '../../constants/endpoints';
import {getItemFromDocumentAndPassToCallback} from '../../utils/firestoreDocumentUtils/firestoreDocumentUtils';
import UserDetailBox from '../../components/UserDetails/UserDetailsBox';
import {USERS} from '../../constants/routes';
import styles from '../shared/mobileStyle.module.css';

class UserDetails extends Component {
    state = {
        user: null,
    };

    componentDidMount() {
        this.fetchUserByDocumentId(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.selectedBranches.length !==
                prevProps.selectedBranches.length &&
            this.state.user &&
            !this.checkIfAnyUserBranchInSelected(this.state.user.branches)
        ) {
            this.props.history.push(USERS);
        }
    }

    setUserState = user => {
        this.setState({user});
    };

    checkIfAnyUserBranchInSelected = usersBranches => {
        this.props.selectedBranches.some(branch =>
            usersBranches.some(userBranch => userBranch.id === branch.id),
        );
    };

    fetchUserByDocumentId = documentId => {
        const userDocument = users().doc(documentId);
        getItemFromDocumentAndPassToCallback(userDocument, this.setUserState);
    };

    render() {
        const {user} = this.state;
        const {t} = this.props;

        return (
            user && (
                <div className={styles.mobileBackground}>
                    <BackNavigation
                        text={t('dialog.backNav')}
                        header={`${user.name} ${user.surname}`}
                        backUrl={USERS}
                    />
                    <UserDetailBox
                        key={user.uid}
                        user={user}
                        disabled={false}
                    />
                </div>
            )
        );
    }
}
const mapStateToProps = state => ({
    selectedBranches: state.branch.selectedBranches,
});

export default connect(
    mapStateToProps,
    null,
)(withTranslation('users')(UserDetails));
