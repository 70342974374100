import React from 'react';
import {useTranslation} from 'react-i18next';
import Picker from '../picker/Picker';

const UserPicker = ({
    label,
    selectedUser,
    users,
    handleChange,
    className,
    icon,
    disabled,
    clearable,
    menuPortalTarget,
}) => {
    const {t} = useTranslation('issues');
    const items = users?.map(user => ({
        value: user.uid,
        label: `${user.name} ${user.surname}`,
    }));
    const selectedValue = selectedUser && {
        value: selectedUser.uid,
        label: `${selectedUser.name} ${selectedUser.surname}`,
    };
    return (
        <Picker
            clearable={clearable}
            label={label}
            pickerItems={items}
            handleChange={handleChange}
            selectedValue={selectedValue}
            icon={icon}
            name="user"
            placeholder={t('dialog.searchForEmployee')}
            className={className}
            disabled={disabled}
            menuPortalTarget={menuPortalTarget}
        />
    );
};

export default UserPicker;
