import React from 'react';
import {getDeviceLocaleShortCode} from '../../../services/translation/getUserLocales';
import {LogoEasysolve} from './Logo.easysolve';
import {LogoUsterka} from './Logo.usterka';

export const Logo = () => {
    const LogoComponent =
        getDeviceLocaleShortCode() === 'pl' ? LogoUsterka : LogoEasysolve;

    return <LogoComponent />;
};
