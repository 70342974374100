import moment from 'moment';
import {instantDownload} from '../../../../utils/download/instantDownload';
import {ReportIssuesPayload} from '../fetchIssuesData';
import {type IssueGeneratorOptions} from '../generateIssueReport';
import {getTableDataRows} from './getTableDataRows';
import {getTableHeaderRow} from './getTableHeaderRow';

type GenerateIssueCsvReportOptions = IssueGeneratorOptions & {
    data: ReportIssuesPayload;
};

export const generateIssueCsvReport = ({
    payload: {startDate, endDate},
    data,
    hasCategoriesFeature,
}: GenerateIssueCsvReportOptions) => {
    const header = getTableHeaderRow(hasCategoriesFeature);
    const rows = getTableDataRows(data, hasCategoriesFeature);
    const csvRows = [header, ...rows].map(row => row.join(';')).join('\n');

    const blob = new Blob([csvRows], {type: 'text/csv'});
    instantDownload(
        blob,
        `report_${moment(startDate).format('DD.MM.YYYY')}-${moment(
            endDate,
        ).format('DD.MM.YYYY')}.csv`,
    );
};
