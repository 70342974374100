import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import styles from './Select.module.css';
import {defaultCustomStyles, globalBranchPickerStyle} from './styles';
import search from '../../../static/images/icons/search.svg';

const CustomSelect = ({
    options,
    className,
    handleChange,
    label,
    placeholder,
    selectedValue,
    searchable,
    disabled,
    multi,
    headerClassName,
    clearable,
    closeMenuOnSelect,
    componentsForPicker,
    globalBranchPicker,
    menuPlacement,
    menuPortalTarget,
    maxMenuHeight,
}) => {
    const [customSelectedValue, setSelectedValue] = useState(selectedValue);
    const onChange = event => {
        setSelectedValue(event);
        handleChange && handleChange(event);
    };
    useEffect(() => {
        if (selectedValue !== undefined) {
            setSelectedValue(selectedValue);
        }
    });

    const searchIcon = !customSelectedValue && (
        <img src={search} alt="" className={styles.searchIcon} />
    );

    const style = globalBranchPicker
        ? globalBranchPickerStyle(customSelectedValue, disabled)
        : defaultCustomStyles(customSelectedValue);

    return (
        <div className={classNames(styles.select, className)}>
            <header className={headerClassName}>{label}</header>
            <div style={{position: 'relative'}}>
                <Select
                    menuPlacement={menuPlacement}
                    placeholder={placeholder}
                    styles={style}
                    options={options}
                    isDisabled={disabled}
                    isSearchable={searchable}
                    isMulti={multi}
                    value={customSelectedValue}
                    onChange={onChange}
                    selectOption={onChange}
                    isClearable={clearable}
                    hideSelectedOptions={!multi}
                    closeMenuOnSelect={closeMenuOnSelect}
                    components={componentsForPicker}
                    menuPortalTarget={menuPortalTarget}
                    maxMenuHeight={maxMenuHeight}
                />
                {searchIcon}
            </div>
        </div>
    );
};

CustomSelect.defaultProps = {
    menuPlacement: 'auto',
};

export default CustomSelect;
