import React from 'react';
import {connect} from 'react-redux';
import UserName from '../../components/UserName/UserName';

const withUser = Component => {
    class WithUser extends React.Component {
        state = {
            menuExpanded: false,
        };

        render() {
            return this.props.userData ? (
                <UserName
                    userName={this.props.userData.email}
                    menuExpanded={this.state.menuExpanded}
                    onExpandButtonClick={this.onExpandButtonClick}
                />
            ) : (
                <Component {...this.props} />
            );
        }

        onExpandButtonClick = () => {
            this.setState({
                menuExpanded: !this.state.menuExpanded,
            });
        };
    }

    const mapStateToProps = state => ({
        userData: state.auth.userData,
    });

    const mapDispatchToProps = dispatch => ({});
    return connect(mapStateToProps, mapDispatchToProps)(WithUser);
};

export default withUser;
