import {generateIssueRaport} from './issue/generateIssueReport';
import {IssueGeneratorPayload} from './issue/issueGeneratorOptions';

export const REPORT_SUBJECTS = {
    issue: 'issue',
    unknown: 'unknown',
} as const;

export type ReportSubject = keyof typeof REPORT_SUBJECTS;

export const REPORT_TYPES = {
    detailed: 'detailed',
    general: 'general',
    branch_general: 'branch_general',
} as const;

export type ReportType = keyof typeof REPORT_TYPES;

export const REPORT_FORMATS = {
    pdf: 'pdf',
    csv: 'csv',
} as const;

export type ReportFormat = keyof typeof REPORT_FORMATS;

export type BaseReportOptions = {
    type: ReportType;
    format: ReportFormat;
};

export type IssueReportOptions = {
    subject: typeof REPORT_SUBJECTS.issue;
    payload: IssueGeneratorPayload;
    hasCategoriesFeature: boolean;
};

export type UnknownReportOptions = {
    subject: typeof REPORT_SUBJECTS.unknown;
    payload: unknown;
};

// Delete UnknownReportOptions after adding another report subject.
// UnknownReportOptions is only used as example of discriminated union.
export type ReportOptions = BaseReportOptions &
    (IssueReportOptions | UnknownReportOptions);

export const generateRaport = async (options: ReportOptions) => {
    switch (options.subject) {
        case REPORT_SUBJECTS.issue:
            return generateIssueRaport(options);
        default:
            throw new Error('Unknown report subject');
    }
};
