import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ListHeaderCellContent from './ListHeaderCellContent';

import './ListHeader.css';

const listHeader = props => {
    const {openedFilterNumber, columns, sortDirection, orderBy, sortHandler} =
        props;
    return (
        <TableHead className="label">
            <TableRow>
                {columns.map((column, index) =>
                    ListHeaderCellContent({
                        column,
                        sortDirection,
                        orderBy,
                        filterIsOpen: index === openedFilterNumber,
                        onFilterIconClick: props.onFilterIconClick(index),
                        sortHandler,
                    }),
                )}
            </TableRow>
        </TableHead>
    );
};

export default listHeader;
