import React from 'react';
import {useTranslation} from 'react-i18next';
import Picker from '../picker/Picker';

const CategoryPicker = ({
    label,
    selectedCategory,
    categories,
    handleChange,
    className,
    icon,
    disabled,
    clearable,
    placeholder,
    menuPortalTarget,
}) => {
    const items = categories?.map(category => ({
        value: category.id,
        label: category.name,
    }));
    const selectedValue =
        selectedCategory && selectedCategory.id !== 'null'
            ? {
                  value: selectedCategory.id,
                  label: selectedCategory.name,
              }
            : null;
    const {t} = useTranslation('common');

    return (
        <Picker
            clearable={clearable}
            label={label}
            pickerItems={items}
            handleChange={handleChange}
            selectedValue={selectedValue}
            icon={icon}
            name="category"
            placeholder={placeholder || t('categoryPicker.placeholder')}
            className={className}
            disabled={disabled}
            menuPortalTarget={menuPortalTarget}
        />
    );
};

export default CategoryPicker;
