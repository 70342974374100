export const getBrowserImageDimensions = async (
    imageFile: File,
): Promise<{width: number; height: number}> =>
    new Promise(async (resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(imageFile);

        fileReader.onload = () => {
            const imageElement = new Image();

            imageElement.onload = function onload() {
                resolve({
                    width: imageElement.width,
                    height: imageElement.height,
                });
                imageElement.remove();
            };

            imageElement.onerror = function onerror(err) {
                reject(err);
                imageElement.remove();
            };

            imageElement.src = fileReader.result as string;
        };
    });
