export const LOGIN = '/login';
export const HOME = '/';
export const REPORTS = '/reports';
export const ELEMENTS = '/elements';
export const ELEMENT = id => `/element/${id}`;
export const ISSUES = '/issues';
export const LOCATIONS = '/locations';
export const ARCHIVED_ISSUES = '/archived-issues';
export const USERS = '/users';
export const USER = id => `/user/${id}`;
export const TASKS = '/tasks';
export const TASK = id => `/task/${id}`;
export const BRANCHES = '/branches';
export const CATEGORIES = '/categories';
export const CRUD = '/crud';
export const ENVIRONMENT = '/environment';
