import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {CustomTextField} from '../../../common/components/index';
import Dialog from '../../../common/components/Dialog/Dialog';
import MultiUserPicker from '../../Tasks/multiUserPicker/MultiUserPicker';

class AddCategoryDialog extends Component {
    state = {
        newCategoryName: '',
    };

    componentDidMount() {
        this.props.setSelectedUsers([]);
    }

    onCategoryNameChange = event => {
        this.setState({newCategoryName: event.target.value});
    };

    onSubmitNewCategoryHandler = () => {
        const categoryToSet = {
            name: this.state.newCategoryName.trim(),
            uids: this.props.selectedUsers.map(user => user.uid),
        };

        this.props.onSubmitHandler(categoryToSet, this.props.selectedUsers);
    };

    render() {
        const {t, dialogTitle, handleClose, loading} = this.props;
        const {newCategoryName} = this.state;
        const submitButtonDisabled = !newCategoryName || loading;

        return (
            <Dialog
                loading={loading}
                handleClose={handleClose}
                dialogTitle={dialogTitle}
                submitButtonDisabled={submitButtonDisabled}
                onSubmitHandler={this.onSubmitNewCategoryHandler}
                submitButtonText={dialogTitle}
            >
                <CustomTextField
                    label={t('dialog.name')}
                    placeholder={t('dialog.namePlaceholder')}
                    onChange={this.onCategoryNameChange}
                    fullWidth
                />
                <div style={{height: '500px'}}>
                    <MultiUserPicker
                        users={this.props.users}
                        selectedUsers={this.props.selectedUsers}
                        setSelectedUsers={this.props.setSelectedUsers}
                        menuPlacement="bottom"
                        searchable
                        label={t('dialog.users')}
                        maxMenuHeight="400px"
                    />
                </div>
            </Dialog>
        );
    }
}

export default withTranslation('categories')(AddCategoryDialog);
