import React from 'react';
import checkCircle from '../../../../../static/images/icons/check-circle.svg';
import remove from '../../../../../static/images/icons/remove.svg';

const cyclicIcon = ({active}) =>
    active ? (
        <img src={checkCircle} width={15} alt="cykliczne" />
    ) : (
        <img src={remove} width={15} alt="niecykliczne" />
    );

export default cyclicIcon;
