import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import {ASC, DESC} from '../../../constants/sortingDirections';
import ListHeader from './listHeader/ListHeader';
import {descendingSort} from '../../../utils/StringUtils/StringUtils';
import {sortRows, getComparator} from '../../../utils/sorting/sorting';
import CustomScrollbar from '../CustomScrollbar/CustomScrollbar';
import styles from './List.module.css';
import {tableColumnsSortStorage} from '../../../storage/localStorage';

type Props = {
    className?: string;
    columns: any[];
    rows: any[];
    rowTemplate: (row: any) => React.ReactNode;
    name: string;
};

type State = {
    sortDirection: string;
    orderBy: string;
    openedFilterNumber: number;
    page?: number;
};

class List extends Component<Props, State> {
    state = {
        sortDirection: DESC,
        orderBy: '',
        openedFilterNumber: -1,
    };

    customComparator;

    componentDidMount(): void {
        const cachedSort =
            tableColumnsSortStorage.getTableColumnsSortFromStorage(
                this.props.name,
            );

        this.setState(initialState => ({
            ...initialState,
            ...cachedSort,
        }));
    }

    getSortedRows = () => {
        const {sortDirection, orderBy} = this.state;
        const {columns, rows} = this.props;

        if (orderBy) {
            const accessor =
                orderBy &&
                columns.find(item => item.id === orderBy).accessAttribute;

            return sortRows(
                this.getSortingComparator(sortDirection),
                accessor,
                rows,
            );
        } else {
            const cachedSort =
                tableColumnsSortStorage.getTableColumnsSortFromStorage(
                    this.props.name,
                );

            if (!cachedSort || !cachedSort.orderBy) {
                return [...rows];
            }

            const accessor =
                cachedSort.orderBy &&
                columns.find(item => item.id === cachedSort.orderBy)
                    .accessAttribute;

            if (cachedSort?.orderBy) {
                return sortRows(
                    this.getSortingComparator(cachedSort.sortDirection),
                    accessor,
                    rows,
                );
            }
        }
    };

    render() {
        const {sortDirection, orderBy, openedFilterNumber} = this.state;
        const {columns, rowTemplate} = this.props;
        const sortedRows = this.getSortedRows();

        return (
            <div
                className={`${styles.list} ${this.props.className} ${styles.noDisplayMobile}`}
            >
                {columns.length ? (
                    <CustomScrollbar>
                        <Table>
                            <ListHeader
                                columns={columns}
                                sortDirection={sortDirection}
                                orderBy={orderBy}
                                sortHandler={this.changeOrder}
                                onFilterIconClick={this.onFilterIconClick}
                                openedFilterNumber={openedFilterNumber}
                            />
                            <TableBody>
                                {sortedRows.map(row => rowTemplate(row))}
                            </TableBody>
                        </Table>
                    </CustomScrollbar>
                ) : (
                    'Loading...'
                )}
            </div>
        );
    }

    onFilterIconClick = index => () => {
        this.setState(prevState => ({
            openedFilterNumber:
                prevState.openedFilterNumber === index ? -1 : index,
        }));
    };

    changeOrder = (property, customComparator) => () => {
        const orderBy = property;
        let sortDirection = DESC;

        if (
            this.state.orderBy === property &&
            this.state.sortDirection === DESC
        ) {
            sortDirection = ASC;
        }

        this.customComparator = customComparator;
        this.setState({sortDirection, orderBy, page: 0});
        tableColumnsSortStorage.saveTableColumnsSort(this.props.name, {
            sortDirection,
            orderBy,
        });
    };

    getSortingComparator(sortDirection) {
        return getComparator(
            this.customComparator ? this.customComparator : descendingSort,
            sortDirection,
        );
    }
}

export default List;
