import React, {Component} from 'react';
import {isEqual} from 'lodash';
import * as moment from 'moment';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import ElementName from '../../../common/components/list/listItem/display/elementName/elementName';
import {
    List,
    MobileList,
    MobileDataDisplay,
    ListItem,
    ListItemMobile,
    ListColumnCreator,
} from '../../../common/components/index';

class ElementsList extends Component {
    state = {
        rows: [],
    };

    columns;

    columnIds;

    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.elements,
        };
        const {t} = this.props;

        this.columns = [
            new ListColumnCreator(
                'name',
                this.props.organizationData.eventBased
                    ? t('table.columns.eventBased.name')
                    : t('table.columns.default.name'),
                {},
            ),
            new ListColumnCreator(
                'createdDate',
                t('table.columns.default.creationDate'),
                {},
            ),
            new ListColumnCreator(
                'locationName',
                this.props.organizationData.eventBased
                    ? t('table.columns.eventBased.company')
                    : t('table.columns.default.location'),
                {
                    accessAttribute: element => element.location.name,
                },
            ),
            new ListColumnCreator(
                'branchName',
                this.props.organizationData.eventBased
                    ? t('table.columns.eventBased.expos')
                    : t('table.columns.default.branch'),
                {
                    accessAttribute: element => element.location.branch.name,
                },
            ),
        ];

        this.columnIds = this.columns.map(column => column.id);
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.elements, prevProps.elements)) {
            this.setState({rows: this.props.elements});
        }
    }

    render() {
        const {rows} = this.state;

        return (
            <>
                <List
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getElementListItemTemplate}
                    name="elements"
                />
                <MobileList
                    columns={['elementName', 'image']}
                    rows={rows}
                    rowTemplate={this.getElementMobileListItemTemplate}
                />
            </>
        );
    }

    getElementListItemTemplate = element => (
        <ListItem
            key={element.id}
            onClick={this.props.onElementClick(element.id)}
            columns={this.columnIds}
        >
            <ElementName elementName={element.name} iconUri={element.iconUri} />
            {moment(element.createdDate).format('DD/MM/YYYY')}
            {element.location.name}
            {element.location.branch.name}
        </ListItem>
    );

    getElementMobileListItemTemplate = element => (
        <ListItemMobile
            key={element.id}
            onClick={this.props.onElementClick(element.id)}
        >
            <MobileDataDisplay
                image={element.iconUri}
                title={element.name}
                left={element.location.name}
            />
        </ListItemMobile>
    );
}

const mapStateToProps = state => ({
    organizationData: state.auth.organizationData,
});

export default connect(mapStateToProps)(
    withTranslation('elements')(ElementsList),
);
