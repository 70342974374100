import React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '..';
import searchIcon from '../../../static/images/icons/search.svg';
import filterIcon from '../../../static/images/icons/filter.svg';

import styles from './SearchField.module.css';

const SearchField = props => {
    const {t} = useTranslation('issues');

    return (
        <div className={styles.container}>
            <div className={styles.header}>{t('search.title')}</div>
            <div className={styles.body}>
                <img src={searchIcon} alt="" className={styles.icon} />

                <TextField
                    variant="outlined"
                    className={styles.searchField}
                    placeholder={
                        props.placeholder
                            ? props.placeholder
                            : t('search.title')
                    }
                    InputProps={{
                        className: styles.innerContainer,
                        classes: {
                            notchedOutline: styles.outline,
                        },
                    }}
                    inputProps={{
                        className: styles.input,
                    }}
                    onChange={props.onSearchTextChange}
                    value={props.searchText}
                />
                {props.withFilter && (
                    <img
                        src={filterIcon}
                        alt=""
                        className={styles.filter}
                        onClick={props.onFilterIconClickHandler}
                    />
                )}
            </div>
            {props.withFilter && props.openFilter && (
                <>
                    <div className={styles.filterDrawer}>
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={t('statusLabels.open')}
                            checked={props.filterState.open}
                            onChange={props.onCheckboxClick('open')}
                        />
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={t('statusLabels.inProgress')}
                            checked={props.filterState.inProgress}
                            onChange={props.onCheckboxClick('inProgress')}
                        />
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={t('statusLabels.inReview')}
                            checked={props.filterState.inReview}
                            onChange={props.onCheckboxClick('inReview')}
                        />
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={`${t('priorityLabels.low')} ${t(
                                'priority',
                            )}`}
                            checked={props.filterState.low}
                            onChange={props.onCheckboxClick('low')}
                        />
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={`${t('priorityLabels.medium')} ${t(
                                'priority',
                            )}`}
                            checked={props.filterState.medium}
                            onChange={props.onCheckboxClick('medium')}
                        />
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={`${t('priorityLabels.high')} ${t(
                                'priority',
                            )}`}
                            checked={props.filterState.high}
                            onChange={props.onCheckboxClick('high')}
                        />
                    </div>
                    <div
                        className={styles.closeWrapper}
                        onClick={props.onFilterIconClickHandler}
                    />
                </>
            )}
        </div>
    );
};

export default SearchField;
