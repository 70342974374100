import getArchivedIssuesQuery from '../utils/queryBuilder/ArchivedIssueQueryBuilder';
import getIssuesQuery from '../utils/queryBuilder/IssueQueryBuilder';
import errorHandler from '../common/components/ExceptionReporting/ErrorReporting';
import {uniqBy} from 'lodash';

export const nullCategory = {
    id: 'null',
    name: 'null',
};

class IssuesService {
    issues = [];

    archivedIssues = [];

    issuesCreatedByUsers = [];

    issuesFromCategories = [];

    archivedIssuesFromCategories = [];

    mergeIssuesWithRecentlyArchivedIssues = (issues, archivedIssues) => {
        if (archivedIssues.length) {
            return [...issues, ...archivedIssues];
        }
        return issues;
    };

    mergeIssuesFromCategoriesWithArchivedIssuesFromCategories = () =>
        this.mergeIssuesWithRecentlyArchivedIssues(
            this.issuesFromCategories.flat(),
            this.archivedIssuesFromCategories.flat(),
        );

    getUsersIssues = ({
        userData,
        organizationData,
        branchIds,
        onSnapShotCallback = null,
    }) => {
        return getIssuesQuery(branchIds, organizationData, userData)
            .withUserId(userData.uid)
            .onSnapshot(issues => {
                this.issuesCreatedByUsers = issues;
                onSnapShotCallback && onSnapShotCallback(issues);
            }, errorHandler);
    };

    mergeWithUsersIssues = issues => {
        return uniqBy([...issues, ...this.issuesCreatedByUsers], 'id');
    };

    subscribeOnIssues = async (
        userData,
        organizationData,
        branchIds,
        setActivityIndicator,
        fetchIssues,
    ) => {
        const isCategoryFeatureEnabled =
            organizationData.isIssuesCategoriesFeatureEnabled;
        const isUserRestricted =
            !userData.roles.admin && !userData.roles.reporter;
        const hasUserCategories = userData.categories?.length;

        const unsubscribeSnapshotArray = unsubscribeArray => {
            unsubscribeArray?.forEach(unsubscribe => unsubscribe());
        };

        const subscribeToArchivedIssues = () => {
            this.unsubscribeArchivedIssues = getArchivedIssuesQuery(branchIds)
                .withClosedDate(
                    new Date(new Date().setDate(new Date().getDate() - 7)),
                )
                .onSnapshot(snapshotArchivedIssues => {
                    this.archivedIssues = snapshotArchivedIssues;
                    fetchIssues(
                        this.mergeIssuesWithRecentlyArchivedIssues(
                            this.issues,
                            this.archivedIssues,
                        ),
                        setActivityIndicator,
                    );
                }, errorHandler);
        };

        const subscribeToIssues = () => {
            this.unsubscribeIssues = getIssuesQuery(
                branchIds,
                organizationData,
                userData,
            ).onSnapshot(snapshotIssues => {
                this.issues = snapshotIssues;
                fetchIssues(
                    this.mergeIssuesWithRecentlyArchivedIssues(
                        this.issues,
                        this.archivedIssues,
                    ),
                    setActivityIndicator,
                );
            }, errorHandler);
        };

        const subscribeToCategoryIssues = () => {
            this.unsubscribeCategoriesIssues = [];
            [...userData.categories, nullCategory].forEach(
                (category, index) => {
                    this.unsubscribeCategoriesIssues[index] = getIssuesQuery(
                        branchIds,
                        organizationData,
                        userData,
                    )
                        .withCategory(category)
                        .onSnapshot(issues => {
                            this.issuesFromCategories[index] = issues;
                            fetchIssues(
                                this.mergeWithUsersIssues(
                                    this.issuesFromCategories.flat(),
                                ),
                                setActivityIndicator,
                            );
                        }, errorHandler);
                },
            );
        };

        const subscribeToCategoryArchivedIssues = () => {
            this.unsubscribeCategoriesArchivedIssues = [];
            [...userData.categories, nullCategory].forEach(
                (category, index) => {
                    this.unsubscribeCategoriesArchivedIssues[index] =
                        getArchivedIssuesQuery(branchIds)
                            .withClosedDate(
                                new Date(
                                    new Date().setDate(
                                        new Date().getDate() - 7,
                                    ),
                                ),
                            )
                            .withCategory(category)
                            .onSnapshot(snapshotArchivedIssues => {
                                this.archivedIssuesFromCategories[index] =
                                    snapshotArchivedIssues;
                                fetchIssues(
                                    this.mergeWithUsersIssues(
                                        this.mergeIssuesFromCategoriesWithArchivedIssuesFromCategories(),
                                    ),
                                    setActivityIndicator,
                                );
                            }, errorHandler);
                },
            );
        };

        if (organizationData.showRecentlyArchivedIssuesOnIssuesList) {
            if (isCategoryFeatureEnabled && isUserRestricted) {
                if (hasUserCategories) {
                    this.unsubscribeUsersIssues = this.getUsersIssues({
                        userData,
                        organizationData,
                        branchIds,
                    });
                    subscribeToCategoryArchivedIssues();
                    subscribeToCategoryIssues();
                } else {
                    setActivityIndicator(false);
                }
            } else {
                subscribeToArchivedIssues();
                subscribeToIssues();
            }
        } else if (isCategoryFeatureEnabled && isUserRestricted) {
            if (hasUserCategories) {
                this.unsubscribeUsersIssues = this.getUsersIssues({
                    userData,
                    organizationData,
                    branchIds,
                });
                subscribeToCategoryIssues();
            } else {
                this.unsubscribeUsersIssues = this.getUsersIssues({
                    userData,
                    organizationData,
                    branchIds,
                    onSnapShotCallback: issues =>
                        fetchIssues(issues, setActivityIndicator),
                });
            }
        } else {
            subscribeToIssues();
        }

        return () => {
            if (this.unsubscribeUsersIssues) this.unsubscribeUsersIssues();
            if (this.unsubscribeArchivedIssues)
                this.unsubscribeArchivedIssues();
            if (this.unsubscribeIssues) this.unsubscribeIssues();
            unsubscribeSnapshotArray(this.unsubscribeCategoriesIssues);
            unsubscribeSnapshotArray(this.unsubscribeCategoriesArchivedIssues);
        };
    };
}

export default new IssuesService();
