import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LocationsList from '../../components/Location/LocationsList/LocationsList';
import {mappings} from './LocationsPageRoleMapper';
import {
    fetchLocations,
    deleteLocation,
    addLocation,
    updateLocation,
} from '../../store/action';
import {CustomCircularProgress} from '../../common/components/index';
import LocationsPageLayout from './LocationsPageLayout';
import {withUserRole} from '../../hoc/User/WithUserRole';
import {NotificationContext} from '../../context/notifications';
import {normalizedStringEquals} from '../../utils/StringUtils/StringUtils';
import getLocationsQuery from '../../utils/queryBuilder/LocationQueryBuilder';
import {TYPES} from '../../constants/error';
import {checkIfElementsExists} from '../../utils/firestoreDocumentUtils/firestoreDocumentUtils';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

class LocationsPage extends Component {
    static contextType = NotificationContext;

    state = {
        locationSelectedForEdit: null,
        addDialogOpen: false,
        editDialogOpen: false,
        searchString: '',
        indicateActivity: true,
    };

    unsubscribeLocations;

    notificationSystem;

    constructor(props) {
        super(props);
        this.notificationSystem = null;
    }

    componentDidMount() {
        this.subscribeOnLocations();
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
            prevProps.selectedBranches.length
        ) {
            this.unsubscribeLocations && this.unsubscribeLocations();
            this.subscribeOnLocations();
        }
    }

    subscribeOnLocations = () => {
        const branchIds = this.props.selectedBranches.map(branch => branch.id);
        this.unsubscribeLocations = getLocationsQuery(branchIds).onSnapshot(
            locations =>
                this.props.fetchLocations(locations, this.setActivityIndicator),
            errorHandler,
        );
    };

    componentWillUnmount() {
        this.unsubscribeLocations();
    }

    render() {
        const locationsList = this.getLocationsList();
        const {locationSelectedForEdit} = this.state;
        this.t = this.props.t;

        return withUserRole(LocationsPageLayout, mappings, {
            locationsList,
            locationSelectedForEdit,
            onSearchTextChange: this.onSearchTextChange,
            onToggleAddDialogHandler: this.onToggleAddDialogHandler,
            addDialogOpen: this.state.addDialogOpen,
            onLocationSubmit: this.onLocationSubmit,
            editDialogOpen: this.state.editDialogOpen,
            onToggleEditDialogHandler: this.onToggleEditDialogHandler,
            onUpdateLocationEventHandler: this.onUpdateLocationEventHandler,
            showNotification: this.showNotification,
        });
    }

    onSearchTextChange = event => {
        this.setState({searchString: event.target.value.trim()});
    };

    onRemoveLocationHandler = async locationId => {
        const elementsExists = await checkIfElementsExists(locationId);
        if (elementsExists) {
            return this.showNotification(
                this.props.organizationData.eventBased
                    ? this.t('notifications.eventBased.subelementsExistError')
                    : this.t('notifications.default.subelementsExistError'),
                TYPES.error,
            );
        }
        this.props.deleteLocation(
            locationId,
            () =>
                this.showNotification(
                    this.props.organizationData.eventBased
                        ? this.t(
                              'notifications.eventBased.elementDeleteSuccess',
                          )
                        : this.t('notifications.default.elementDeleteSuccess'),
                    TYPES.success,
                ),
            () =>
                this.showNotification(
                    this.props.organizationData.eventBased
                        ? this.t('notifications.eventBased.elementDeleteError')
                        : this.t('notifications.default.elementDeleteError'),
                    TYPES.error,
                ),
        );
    };

    filterLocations = locations => {
        if (this.state.searchString) {
            locations = locations.filter(location =>
                location.name
                    .toUpperCase()
                    .includes(this.state.searchString.toUpperCase()),
            );
        }
        return locations;
    };

    onLocationsClickHandler = locationId => () => {
        const location = this.props.locations.find(
            loc => loc.id === locationId,
        );
        this.setState({
            locationSelectedForEdit: location,
            editDialogOpen: true,
        });
    };

    onToggleAddDialogHandler = () => {
        this.setState({addDialogOpen: !this.state.addDialogOpen});
    };

    onToggleEditDialogHandler = () => {
        this.setState({editDialogOpen: !this.state.editDialogOpen});
    };

    locationExists = location =>
        this.props.locations
            .filter(locationToCheck => locationToCheck.id !== location.id)
            .filter(locationToCheck =>
                normalizedStringEquals(locationToCheck.name, location.name),
            )
            .some(
                locationToCheck =>
                    locationToCheck.branch.id === location.branch.id,
            );

    onLocationSubmit = (locationToSet, locationMapFile) => {
        if (this.locationExists(locationToSet)) {
            this.showNotification(
                this.props.organizationData.eventBased
                    ? this.t('notifications.eventBased.elementExistsError')
                    : this.t('notifications.default.elementExistsError'),
                TYPES.error,
            );
        } else {
            this.props.addLocation(
                {location: locationToSet, locationMapFile},
                () =>
                    this.showNotification(
                        this.props.organizationData.eventBased
                            ? this.t(
                                  'notifications.eventBased.elementAddSuccess',
                              )
                            : this.t('notifications.default.elementAddSuccess'),
                        'success',
                    ),
                () =>
                    this.showNotification(
                        this.props.organizationData.eventBased
                            ? this.t('notifications.eventBased.elementAddError')
                            : this.t('notifications.default.elementAddError'),
                        TYPES.error,
                    ),
            );
        }
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    setActivityIndicator = showIndicator => {
        this.setState({indicateActivity: showIndicator});
    };

    getLocationsList = () =>
        this.state.indicateActivity ? (
            <CustomCircularProgress />
        ) : (
            <LocationsList
                locations={this.filterLocations(this.props.locations)}
                onRemoveLocation={this.onRemoveLocationHandler}
                onLocationClick={this.onLocationsClickHandler}
            />
        );

    onUpdateLocationEventHandler = (location, onSuccess, onError) => {
        if (this.locationExists(location)) {
            onError(
                this.props.organizationData.eventBased
                    ? this.t('notifications.eventBased.elementExistsError')
                    : this.t('notifications.default.elementExistsError'),
            );
        } else {
            this.props.updateLocation(location, location.id, onSuccess, () =>
                onError(
                    this.props.organizationData.eventBased
                        ? this.t('notifications.eventBased.elementUpdateError')
                        : this.t('notifications.default.elementUpdateError'),
                ),
            );
        }
    };
}

const mapStateToProps = state => ({
    locations: state.location.locations,
    selectedBranches: state.branch.selectedBranches,
    organizationData: state.auth.organizationData,
});

const mapDispatchToProps = dispatch => ({
    fetchLocations: (querySnapshot, callback) =>
        dispatch(fetchLocations(querySnapshot, callback)),
    deleteLocation: (locationId, onSuccess, onError) =>
        dispatch(deleteLocation(locationId, onSuccess, onError)),
    addLocation: (location, onSuccess, onError) =>
        dispatch(addLocation(location, onSuccess, onError)),
    updateLocation: (location, key, onSuccess, onError) =>
        dispatch(updateLocation(location, key, onSuccess, onError)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation('locations')(LocationsPage));
