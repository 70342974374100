import firebase from 'firebase/app';
import {getDownloadUrl} from '../../utils/storageUtils/storageUtils';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

export const downloadIconsAndSetItems =
    (items, accessor, setItems, onDownloadDone) => dispach => {
        Promise.all(
            items.map(item =>
                new Promise((resolve, reject) => {
                    const imagePath = accessor(item);
                    if (!imagePath) {
                        resolve(item);
                    }
                    const storageRef = firebase
                        .storage()
                        .ref()
                        .child(imagePath);

                    storageRef
                        .getDownloadURL()
                        .then(result => {
                            resolve({...item, iconUri: result});
                        })
                        .catch(err => {
                            errorHandler(err);
                            resolve(item);
                        });
                }).catch(err => {
                    errorHandler(err);
                }),
            ),
        )
            .then(result => {
                dispach(setItems(result));
                onDownloadDone();
            })
            .catch(err => {
                errorHandler(err);
                onDownloadDone();
            });
    };

export const downloadImagesAndSetElements =
    (items, setItems, onDownloadDone) => dispatch => {
        const promises = items.map(item => {
            if (!item.iconImagePath) {
                return Promise.resolve(item);
            }
            return new Promise(resolve => {
                const localPromises = [
                    getDownloadUrl(item.iconImagePath),
                    getDownloadUrl(item.patternImagePath),
                ];
                Promise.all(localPromises)
                    .then(([iconUri, patternUri]) => {
                        resolve({...item, iconUri, patternUri});
                    })
                    .catch(err => {
                        errorHandler(err, 'Bad element image paths');
                        resolve(item);
                    });
            });
        });
        Promise.all(promises).then(items => {
            dispatch(setItems(items));
            onDownloadDone();
        });
    };

export const downloadPhotos = (photoPaths: string[]) => {
    const downloadPromises = [];
    photoPaths.forEach(photoPath =>
        downloadPromises.push(
            firebase.storage().ref(photoPath).getDownloadURL(),
        ),
    );
    return new Promise((resolve, reject) => {
        Promise.all(downloadPromises)
            .then(photoUris => {
                const result = photoPaths.map((photoPath, index) => ({
                    fileName: photoPath,
                    uri: photoUris[index],
                }));
                resolve(result);
            })
            .catch(error => {
                errorHandler(error, 'failed to download photos');
                reject(error);
            });
    });
};

export const dispatchAndDownloadPhotos = dispatch => (photos, callback) => {
    photos && downloadPhotos(photos).then(callback);
};

export const removePhotos = photoPathsToRemove =>
    photoPathsToRemove.map(photo =>
        firebase.storage().ref().child(photo.fileName).delete(),
    );
