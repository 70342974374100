import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {NotificationContext} from '../../context/notifications';
import EnvironmentAdminForm from '../../components/Environment/EnvironmentAdminForm/EnvironmentAdminForm';
import {sendDataToUpdate} from '../../store/action/environment';
import {TYPES} from '../../constants/error';
import {ListTitle} from '../../common/components';

class AdminEnvironmentPage extends Component {
    state = {
        updateEnvironmentPending: false,
    };

    static contextType = NotificationContext;

    notificationSystem = this.context;

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message,
                type,
            });
        }
    };

    onSubmitUpdateEnvironment = formData => {
        this.setState({updateEnvironmentPending: true});
        sendDataToUpdate(formData)
            .then(() => {
                this.showNotification(
                    this.props.t('notifications.elementEditSuccess'),
                    TYPES.success,
                );
            })
            .catch(error => {
                console.error(error);
                this.showNotification(
                    this.props.t('notifications.elementEditError'),
                    TYPES.error,
                );
            })
            .finally(() => {
                this.setState({updateEnvironmentPending: false});
            });
    };

    render() {
        const {t, userData, organizationData} = this.props;
        const {updateEnvironmentPending} = this.state;
        const organization = {
            name: userData.organization,
            ...organizationData,
        };
        return (
            <div>
                <ListTitle title={t('main.environment')} />
                <EnvironmentAdminForm
                    onSubmitHandler={this.onSubmitUpdateEnvironment}
                    environment={organization}
                    dialogTitle={t('dialog.title.edit')}
                    loading={updateEnvironmentPending}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
    organizationData: state.auth.organizationData,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation('environments')(AdminEnvironmentPage));
