import * as React from 'react';
import {withRouter} from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

const withLayout = (Component, withBurger) => {
    const WithLayout = props => (
        <Layout withBurger={withBurger}>
            <Component {...props} />
        </Layout>
    );
    return withRouter(WithLayout);
};

export default withLayout;
