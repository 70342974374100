import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import ArchivedIssuesList from '../../components/Issues/ArchivedIssueList/ArchivedIssueList';
import {fetchIssues} from '../../store/action';
import {
    ListTitle,
    SearchField,
    CustomCircularProgress,
} from '../../common/components';
import {
    compareIssueWithQueryString,
    getSearchTextFromQueryParams,
} from '../../utils/filtering/filtering';
import {
    getComparator,
    issueClosedDateComparator,
} from '../../utils/sorting/sorting';
import {DESC} from '../../constants/sortingDirections';
import ArchivedIssuesService from '../../services/ArchivedIssuesService';

class ArchivedIssuePage extends Component {
    state = {
        indicateActivity: true,
    };

    unsubscribeArchivedIssues;

    async componentDidMount() {
        await this.subscribeOnArchivedIssues();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
            prevProps.selectedBranches.length
        ) {
            this.unsubscribeArchivedIssues && this.unsubscribeArchivedIssues();
            await this.subscribeOnArchivedIssues();
        }
    }

    subscribeOnArchivedIssues = async () => {
        this.unsubscribeArchivedIssues =
            await ArchivedIssuesService.subscribeOnArchivedIssues(
                this.props.userData,
                this.props.organizationData,
                this.props.selectedBranches.map(branch => branch.id),
                this.setActivityIndicator,
                this.props.fetchArchivedIssues,
            );
    };

    componentWillUnmount() {
        this.unsubscribeArchivedIssues();
    }

    onSearchTextChangeHandler = event => {
        const params = new URLSearchParams({search: event.target.value});
        this.props.history.replace({
            pathname: this.props.location.pathname,
            search: params.toString(),
        });
    };

    filterIssues = issues => {
        const searchString = getSearchTextFromQueryParams(
            this.props.location.search,
        );
        return issues.filter(issue =>
            compareIssueWithQueryString(issue, searchString),
        );
    };

    sortIssues = issues =>
        [...issues].sort(getComparator(issueClosedDateComparator, DESC));

    setActivityIndicator = showIndicator => {
        this.setState({indicateActivity: showIndicator});
    };

    render() {
        const issues = this.filterIssues(this.props.archivedIssues);
        const sortedIssues = this.sortIssues(issues);
        const searchText = getSearchTextFromQueryParams(
            this.props.location.search,
        );
        const {t} = this.props;

        const archivedIssueList = this.state.indicateActivity ? (
            <CustomCircularProgress />
        ) : (
            <ArchivedIssuesList
                issues={sortedIssues}
                onIssueClick={this.onIssueClickHandler}
            />
        );
        return (
            <>
                <ListTitle title={t('main.title')} />
                <SearchField
                    placeholder={t('main.searchPlaceholder')}
                    onSearchTextChange={this.onSearchTextChangeHandler}
                    searchText={searchText}
                />
                {archivedIssueList}
            </>
        );
    }

    onIssueClickHandler = issueId => () => {
        this.props.history.push(`/issue/archived/${issueId}`, {
            search: this.props.location.search,
        });
    };
}

const mapStateToProps = state => ({
    archivedIssues: state.issue.archivedIssues,
    selectedBranches: state.branch.selectedBranches,
    userData: state.auth.userData,
    organizationData: state.auth.organizationData,
});

const mapDispatchToProps = dispatch => ({
    fetchArchivedIssues: (snapshot, callback) =>
        dispatch(fetchIssues(snapshot, true, callback)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation('archive')(ArchivedIssuePage));
