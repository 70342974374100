import React from 'react';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {Card, CardContent, CardHeader} from '@material-ui/core';
import styles from './IssueDetails.module.css';
import {
    Box,
    BackNavigation,
    CustomTextField,
    CustomCircularProgress,
} from '../../common/components';
import {ISSUES, ARCHIVED_ISSUES} from '../../constants/routes';
import portraitLandscape from '../../static/images/icons/picture-landscape.svg';
import {LocalizationMapPicker} from '../../components/Issues/LocalizationMapPicker/LocalizationMapPicker';
import {ClickablePhoneNumberInput} from '../../common/components/clickablePhoneNumberInput/ClickablePhoneNumberInput';

const taskPriorityRadioGroupOptions = translatedPriorityLabels =>
    Object.entries(translatedPriorityLabels).map(([key, value]) => ({
        name: value,
        value: key,
    }));

const IssueDetailsLayout = ({
    innerComponents: [
        DeleteButton,
        SaveButton,
        LocationPicker,
        ElementPicker,
        ElementImageDisplay,
        WarrantyDatePicker,
        AddedOnDatePicker,
        AddedByTextField,
        CategoryPicker,
        StatusPicker,
        AssignedToUserPicker,
        ClosedAtDatePicker,
        AcceptedByUserPicker,
        PriorityRadioButtons,
        IssueDescriptionTextField,
        IssueImageDropzone,
        Hr,
        TextField,
        Dropzone,
    ],
    roleProps: {statuses},
    props,
}) => {
    const {t} = useTranslation('issues');
    const location = useLocation();
    const translatedPriorityLabels = {
        low: t('priorityLabels.low'),
        medium: t('priorityLabels.medium'),
        high: t('priorityLabels.high'),
    };

    const {
        isCurrentUserIssueReporter,
        maintainerHasNoPermissionToAssignUsersToIssue,
        reporterHasNoPermissionToEditIssueDescription,
        maintainerHasNoPermissionToEditIssueDescription,
        reporterCanSeeIssueSolution,
    } = props;

    const backNavText = props.archived
        ? t('details.archive.backNav')
        : t('details.default.backNav');

    const issueListBackUrl = `${ISSUES}${location.state?.search || ''}`;
    const archivedIssueListBackUrl = `${ARCHIVED_ISSUES}${
        location.state?.search || ''
    }`;

    const backUrl = props.archived
        ? archivedIssueListBackUrl
        : issueListBackUrl;

    if (!props?.issue && props?.loadingIssue) {
        return <CustomCircularProgress />;
    }

    if (!props?.issue && !props?.loadingIssue) {
        return (
            <div className={styles.mobileBackground}>
                <BackNavigation
                    text={backNavText}
                    backUrl={backUrl}
                    header={t('details.default.noIssueTitle')}
                />

                <div className={styles.noIssueContainer}>
                    <Card>
                        <CardHeader
                            title={t('details.default.noIssueCardTitle')}
                        />
                        <CardContent>
                            {t('details.default.noIssueCardContent')}
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.mobileBackground}>
            <BackNavigation
                text={backNavText}
                header={props.issue.element.name}
                backUrl={backUrl}
            />
            {props.uploadingInProgress && <CustomCircularProgress />}
            <div className={styles.root}>
                <Box className={styles.issueDetailsBox}>
                    <section className={styles.buttons}>
                        {DeleteButton({
                            className: classnames(
                                styles.button,
                                styles.deleteButton,
                            ),
                            text: t('details.default.deleteBtn'),
                            contained: false,
                            onClick: props.deleteIssue,
                        })}
                        {SaveButton({
                            className: classnames(
                                styles.button,
                                styles.saveButton,
                            ),
                            text: t('details.default.saveBtn'),
                            disabled: props.isSubmitButtonDisabled,
                            onClick: props.updateIssue,
                            isIssueArchived: props.archived,
                            isCurrentUserIssueReporter,
                        })}
                    </section>
                    <div className={styles.issueDetails}>
                        <CustomTextField
                            className={styles.textField}
                            label={t('details.default.issueId')}
                            defaultValue={`${props.issue.id
                                .toLowerCase()
                                .slice(0, 6)}`}
                            disabled
                        />
                        {LocationPicker({
                            selectedLocation: props.selectedLocation,
                            handleChange: props.onLocationChange,
                            locations: props.locations,
                            isIssueArchived: props.archived,
                            isCurrentUserIssueReporter,
                        })}
                        {props.isIssueLocalizationEnabled &&
                            props.selectedLocation?.locationMap && (
                                <LocalizationMapPicker
                                    selectedLocation={props.selectedLocation}
                                    onSave={props.onLocationMarkerSave}
                                    initialMarker={props.issue.locationMarker}
                                    onDelete={props.onLocationMarkerDelete}
                                />
                            )}
                        {ElementPicker({
                            selectedElement: props.selectedElement,
                            handleChange: props.onElementChange,
                            elements: props.elements,
                            isIssueArchived: props.archived,
                            isCurrentUserIssueReporter,
                        })}
                        {ElementImageDisplay({
                            templateSrc: portraitLandscape,
                            src:
                                props.selectedElement &&
                                props.selectedElement.iconUri,
                            title: props.organizationData.eventBased
                                ? t('details.eventBased.workStationImg')
                                : t('details.default.elementImg'),
                            fullSizeSrc:
                                props.selectedElement &&
                                props.selectedElement.patternUri,
                        })}
                        {WarrantyDatePicker({
                            label: t('details.default.warrantyDate'),
                            value: props.issue.element.warrantyTo,
                        })}
                        {AddedOnDatePicker({
                            label: t('details.default.creationDate'),
                            value: props.issue.createdDate,
                            maxDate: props.issue.closedDate,
                            onChange: props.onIssueCreationDateChange,
                        })}
                        {AddedByTextField({
                            className: styles.textField,
                            label: t('details.default.author'),
                            defaultValue: `${props.issue.reporter.name} ${props.issue.reporter.surname}`,
                        })}
                        <ClickablePhoneNumberInput
                            phoneNumber={props.issue.phoneNumber}
                            phoneNumberChange={props.onPhoneNumberChange}
                        />
                        {props.isIssuesCategoriesFeatureEnabled &&
                            CategoryPicker({
                                label: t('details.default.category'),
                                selectedCategory: props.selectedCategory,
                                handleChange: props.onSelectedCategoryChange,
                                categories: props.categories,
                                isCurrentUserIssueReporter,
                                isIssueArchived: props.archived,
                            })}
                        {StatusPicker({
                            selectedStatus: props.issue.status,
                            statuses: statuses,
                            handleChange: props.onStatusChange,
                            isIssueArchived: props.archived,
                        })}
                        {AssignedToUserPicker({
                            label: t('details.default.assignedTo'),
                            selectedUser: props.issue.assignedTo,
                            handleChange: props.onAssignedEmployeeChange,
                            users: props.users,
                            isIssueArchived: props.archived,
                            maintainerHasNoPermissionToAssignUsersToIssue,
                        })}
                        {props.archived &&
                            ClosedAtDatePicker({
                                label: t('details.default.closedAtDate'),
                                value: props.issue.closedDate,
                                minDate: props.issue.createdDate,
                                onChange: props.onIssueClosedDateChange,
                            })}
                        {props.archived &&
                            AcceptedByUserPicker({
                                label: t('details.default.acceptedBy'),
                                selectedUser: props.issue.acceptedBy,
                                handleChange: props.onAcceptedByChange,
                                users: props.users,
                                isIssueArchived: props.archived,
                            })}
                        {PriorityRadioButtons({
                            items: taskPriorityRadioGroupOptions(
                                translatedPriorityLabels,
                            ),
                            label: t('details.default.priority'),
                            onChange: props.onPriorityChange,
                            defaultValue: props.issue.priority,
                            isIssueArchived: props.archived,
                            isCurrentUserIssueReporter,
                        })}
                        {IssueDescriptionTextField({
                            className: styles.textField,
                            label: t('details.default.description'),
                            placeholder: t(
                                'details.default.descriptionPlaceholder',
                            ),
                            value: props.issue.issueDescription,
                            multiline: true,
                            onChange:
                                props.onTextChangeHandlerBuilder(
                                    'issueDescription',
                                ),
                            isIssueArchived: props.archived,
                            isCurrentUserIssueReporter,
                            reporterHasNoPermissionToEditIssueDescription,
                            maintainerHasNoPermissionToEditIssueDescription,
                        })}
                        {IssueImageDropzone({
                            label: t('details.default.issueImg'),
                            imagesSrc: props.issueImagesSrc,
                            onRemoveImage:
                                props.onRemovePhotoHandlerBuilder(
                                    'issueImages',
                                ),
                            onDrop: props.onAddNewImageHandlerBuilder(
                                'issueImages',
                            ),
                            isIssueArchived: props.archived,
                            isCurrentUserIssueReporter,
                        })}
                    </div>
                    {Hr({
                        className: styles.separator,
                        reporterCanSeeIssueSolution,
                    })}
                    <div className={styles.issueDetails}>
                        {TextField({
                            className: styles.textField,
                            label: t('details.default.solutionDescription'),
                            placeholder: t(
                                'details.default.solutionDescriptionPlaceholder',
                            ),
                            value: props.issue.solutionDescription,
                            multiline: true,
                            onChange: props.onTextChangeHandlerBuilder(
                                'solutionDescription',
                            ),
                            isIssueArchived: props.archived,
                            reporterCanSeeIssueSolution,
                        })}
                        {Dropzone({
                            label: t('details.default.solutionImg'),
                            imagesSrc: props.solutionImagesSrc,
                            onRemoveImage:
                                props.onRemovePhotoHandlerBuilder(
                                    'solutionImages',
                                ),
                            onDrop: props.onAddNewImageHandlerBuilder(
                                'solutionImages',
                            ),
                            isIssueArchived: props.archived,
                        })}
                    </div>
                </Box>
            </div>
        </div>
    );
};
export default IssueDetailsLayout;
