import axios from 'axios';
import firebase from 'firebase/app';
import packageJson from '../../../package.json';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

const {database} = packageJson;

const databaseType = Object.keys(database).find(field => database[field]);
const URLs = {
    develop: 'https://europe-central2-usterka-dev-bf1e0.cloudfunctions.net/',
    uat: 'https://europe-central2-usterka-uat-fff61.cloudfunctions.net/',
    production: 'https://europe-central2-usterka-prod.cloudfunctions.net/',
};

const defaultInstance = axios.create({
    baseURL: URLs[databaseType],
    headers: {},
});
defaultInstance.interceptors.response.use(
    response => response,
    error => {
        errorHandler(error);
        throw error;
    },
);

firebase.auth().onIdTokenChanged(async user => {
    if (user) {
        const token = await user.getIdToken();
        defaultInstance.defaults.headers.Authorization = `Bearer ${token}`;
    }
});

export default defaultInstance;
