import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Picker from '../picker/Picker';

const BranchPicker = props => {
    const eventBased = useSelector(
        state => state.auth.organizationData?.eventBased,
    );

    const {t} = useTranslation('common');

    const mapBranchToPicker = branch => ({
        value: branch.id,
        label: branch.name,
    });

    const items = props.branches?.map(mapBranchToPicker);

    const selectedValue = props.selectedBranch
        ? mapBranchToPicker(props.selectedBranch)
        : props.selectedBranches?.map(mapBranchToPicker);

    return (
        items.length > 1 && (
            <Picker
                label={
                    eventBased
                        ? t('branchPicker.eventBased.label')
                        : t('branchPicker.default.label')
                }
                selectedValue={selectedValue}
                pickerItems={items}
                name={
                    eventBased
                        ? t('branchPicker.eventBased.name')
                        : t('branchPicker.default.name')
                }
                multi={props.multi}
                first={props.first}
                placeholder={
                    eventBased
                        ? t('branchPicker.eventBased.searchPlaceholder')
                        : t('branchPicker.default.searchPlaceholder')
                }
                handleChange={props.handleChange}
                className={props.className}
                disabled={props.disabled}
                clearable={false}
                menuPlacement={props.menuPlacement}
                menuPortalTarget={props.menuPortalTarget}
            />
        )
    );
};

BranchPicker.defaultProps = {
    bottomLabelActive: true,
};

export default BranchPicker;
