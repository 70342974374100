import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import styles from './customCircularProgress.module.css';

const customCircularProgress = () => (
    <div className={styles.circle}>
        <CircularProgress />
    </div>
);

export default customCircularProgress;
