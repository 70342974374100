import React from 'react';
import BranchesList from '../../components/Branch/BranchesList/BranchesList';
import AddBranchDialog from '../../components/Branch/AddBranchDialog/AddBranchDialog';
import EditBranchDialog from '../../components/Branch/EditBranchDialog/EditBranchDialog';
import {ListActionPanel, CustomCircularProgress} from '../../common/components';
import {TFunction} from 'i18next';
import {Branch} from '../../models/branch';

type Props = {
    t: TFunction;
    addDialogOpen: boolean;
    eventBased: boolean;
    actionPending: boolean;
    listLoading: boolean;
    onSearchTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onToggleAddDialogHandler: () => void;
    onSubmitHandler: (branchName: string) => void;
    editDialogOpen: boolean;
    onToggleEditDialogHandler: () => void;
    branchSelectedForEdit: Branch | null;
    onUpdateBranch: (branchName: string) => void;
    onBranchClickHandler: (branchId: Branch['id']) => void;
    branches: Branch[];
    onDeleteBranch: (branch: Branch) => void;
};

const BranchesPageLayout = ({
    props: {
        t,
        actionPending,
        addDialogOpen,
        branchSelectedForEdit,
        branches,
        editDialogOpen,
        eventBased,
        listLoading,
        onBranchClickHandler,
        onDeleteBranch,
        onSearchTextChange,
        onSubmitHandler,
        onToggleAddDialogHandler,
        onToggleEditDialogHandler,
        onUpdateBranch,
    },
}: {
    props: Props;
}) => (
    <div>
        {actionPending && <CustomCircularProgress />}
        <ListActionPanel
            searchPlaceholder={
                eventBased
                    ? t('main.eventBased.searchPlaceholder')
                    : t('main.default.searchPlaceholder')
            }
            title={
                eventBased
                    ? t('main.eventBased.title')
                    : t('main.default.title')
            }
            addButtonText={
                eventBased
                    ? t('main.eventBased.actionBtn')
                    : t('main.default.actionBtn')
            }
            onSearchTextChange={onSearchTextChange}
            onToggleAddDialogHandler={onToggleAddDialogHandler}
            showAddButton
        />
        {addDialogOpen && (
            <AddBranchDialog
                handleClose={onToggleAddDialogHandler}
                onSubmitHandler={onSubmitHandler}
                dialogTitle={
                    eventBased
                        ? t('dialog.eventBased.title.add')
                        : t('dialog.default.title.add')
                }
                loading={actionPending}
            />
        )}
        {editDialogOpen && (
            <EditBranchDialog
                dialogTitle={
                    eventBased
                        ? t('dialog.eventBased.title.edit')
                        : t('dialog.default.title.edit')
                }
                branch={branchSelectedForEdit}
                handleClose={onToggleEditDialogHandler}
                onUpdateBranch={onUpdateBranch}
                loading={actionPending}
            />
        )}
        <div>
            {listLoading ? (
                <CustomCircularProgress />
            ) : (
                <BranchesList
                    onBranchClick={onBranchClickHandler}
                    branches={branches}
                    onDeleteBranch={onDeleteBranch}
                />
            )}
        </div>
    </div>
);

export default BranchesPageLayout;
