import firebase from 'firebase/app';
import {task} from '../../constants/endpoints';
import {taskConverter} from '../converter/taskConverter';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

export const fetchAllTasks = callback =>
    firebase
        .firestore()
        .collection(task())
        .withConverter(taskConverter)
        .onSnapshot(callback);

export const updateTask = (id, data) =>
    firebase.firestore().collection(task()).doc(id).update(data);

export const deleteTask = taskId =>
    firebase.firestore().collection(task()).doc(taskId).delete();

export const addNewTask = (newTask, onSuccess, onError) => {
    firebase
        .firestore()
        .collection(task())
        .add(newTask)
        .then(onSuccess)
        .catch(err => {
            errorHandler(err);
            onError();
        });
};
