import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
    CustomTextField,
    Checkbox,
    CustomButton,
} from '../../../common/components/index';
import {NotificationContext} from '../../../context/notifications';
import {
    REPORTER_CAN_SEE_ALL_ISSUES,
    SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_AND_SUPERVISORS_ON_ISSUE_CREATE,
    SEND_EMAIL_WITH_NOTIFICATION_TO_USER_AFTER_ASSIGN_TO_ISSUE,
    SHOW_RECENTLY_ARCHIVED_ISSUES_ON_ISSUES_LIST,
    IS_ISSUES_CATEGORIES_FEATURE_ENABLED,
    MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE,
    SUPERVISOR_HAS_ACCESS_TO_TASKS,
    FAIR_ENVIRONMENT,
    ISSUE_EXPIRATION,
    REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION,
    MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION,
    MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE,
    REPORTER_CAN_SEE_ISSUE_SOLUTION,
    IMPORTANT_FLAG_HIDDEN_ON_ISSUE,
    MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED,
    REPORTER_GET_CHANGE_MAIL,
    SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL,
} from '../../../constants/environment';
import CustomCircularProgress from '../../../common/components/CustomCircularProgress/customCircularProgress';
import styles from './EnvironmentAdminForm.module.css';

class EnvironmentAdminForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newEnvironmentName: props.environment.name,
            oldEnvironmentName: props.environment.name,
            reporterCanSeeAllIssues: props.environment.reporterCanSeeAllIssues,
            sendEmailWithNotificationToUserAfterAssignToIssue:
                props.environment
                    .sendEmailWithNotificationToUserAfterAssignToIssue,
            showRecentlyArchivedIssuesOnIssuesList:
                props.environment.showRecentlyArchivedIssuesOnIssuesList,
            sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate:
                props.environment
                    .sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate,
            isIssuesCategoriesFeatureEnabled:
                props.environment.isIssuesCategoriesFeatureEnabled,
            maintainerHasNoPermissionToAssignUsersToIssue:
                props.environment.maintainerHasNoPermissionToAssignUsersToIssue,
            supervisorHasAccessToTasks:
                props.environment.supervisorHasAccessToTasks,
            fairEnvironment: false,
            issueExpiration: props.environment.issueExpiration,
            reporterHasNoPermissionToEditIssueDescription:
                props.environment.reporterHasNoPermissionToEditIssueDescription,
            maintainerHasNoPermissionToEditIssueDescription:
                props.environment
                    .maintainerHasNoPermissionToEditIssueDescription,
            maintainerHasNoPermissionToAddIssue:
                props.environment.maintainerHasNoPermissionToAddIssue,
            reporterCanSeeIssueSolution:
                props.environment.reporterCanSeeIssueSolution,
            maintainerGetPushOnAllIssuesCreated:
                props.environment.maintainerGetPushOnAllIssuesCreated,
            supervisorAndMaintainerGetChangeMail:
                props.environment.supervisorAndMaintainerGetChangeMail,
            reporterGetChangeMail: props.environment.reporterGetChangeMail,
        };
    }

    static contextType = NotificationContext;

    notificationSystem = this.context;

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message,
                type,
            });
        }
    };

    onSubmitEditEnvironmentHandler = () => {
        this.props.onSubmitHandler(this.state);
    };

    toggleCheckboxValue = event => {
        this.setState({[event.target.id]: !this.state[event.target.id]});
    };

    render() {
        const {loading, t} = this.props;
        const {newEnvironmentName} = this.state;
        const submitButtonDisabled = !newEnvironmentName.trim() || loading;
        this.t = this.props.t;

        return (
            <div className={styles.container}>
                {loading && <CustomCircularProgress />}
                <CustomTextField
                    label={this.t('dialog.name')}
                    fullWidth
                    value={this.state.newEnvironmentName}
                    disabled
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.reporterCanSeeAllIssues')}
                    id={REPORTER_CAN_SEE_ALL_ISSUES}
                    checked={this.state[REPORTER_CAN_SEE_ALL_ISSUES]}
                    onChange={this.toggleCheckboxValue}
                    value={this.state.reporterCanSeeAllIssues}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.sendEmailWithNotificationToUserAfterAssignToIssue',
                    )}
                    id={
                        SEND_EMAIL_WITH_NOTIFICATION_TO_USER_AFTER_ASSIGN_TO_ISSUE
                    }
                    checked={
                        this.state[
                            SEND_EMAIL_WITH_NOTIFICATION_TO_USER_AFTER_ASSIGN_TO_ISSUE
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                    value={
                        this.state
                            .sendEmailWithNotificationToUserAfterAssignToIssue
                    }
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.showRecentlyArchivedIssuesOnIssueList',
                    )}
                    id={SHOW_RECENTLY_ARCHIVED_ISSUES_ON_ISSUES_LIST}
                    checked={
                        this.state[SHOW_RECENTLY_ARCHIVED_ISSUES_ON_ISSUES_LIST]
                    }
                    onChange={this.toggleCheckboxValue}
                    value={this.state.showRecentlyArchivedIssuesOnIssuesList}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate',
                    )}
                    id={
                        SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_AND_SUPERVISORS_ON_ISSUE_CREATE
                    }
                    checked={
                        this.state[
                            SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_AND_SUPERVISORS_ON_ISSUE_CREATE
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                    value={
                        this.state
                            .sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate
                    }
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.isIssuesCategoriesFeatureEnabled',
                    )}
                    id={IS_ISSUES_CATEGORIES_FEATURE_ENABLED}
                    checked={this.state[IS_ISSUES_CATEGORIES_FEATURE_ENABLED]}
                    onChange={this.toggleCheckboxValue}
                    value={this.state.isIssuesCategoriesFeatureEnabled}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerHasNoPermissionToAssignUsersToIssue',
                    )}
                    id={MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE}
                    checked={
                        this.state[
                            MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE
                        ]
                    }
                    value={
                        this.state[
                            MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.supervisorHasAccessToTasks',
                    )}
                    id={SUPERVISOR_HAS_ACCESS_TO_TASKS}
                    checked={this.state[SUPERVISOR_HAS_ACCESS_TO_TASKS]}
                    value={this.state[SUPERVISOR_HAS_ACCESS_TO_TASKS]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.expoEnvironment')}
                    id={FAIR_ENVIRONMENT}
                    checked={this.state[FAIR_ENVIRONMENT]}
                    value={this.state[FAIR_ENVIRONMENT]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.issueExpiration')}
                    id={ISSUE_EXPIRATION}
                    checked={this.state[ISSUE_EXPIRATION]}
                    value={this.state[ISSUE_EXPIRATION]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.reporterHasNoPermissionToEditIssueDescription',
                    )}
                    id={REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION}
                    checked={
                        this.state[
                            REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    value={
                        this.state[
                            REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerHasNoPermissionToEditIssueDescription',
                    )}
                    id={MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION}
                    checked={
                        this.state[
                            MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    value={
                        this.state[
                            MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerHasNoPermissionToAddIssue',
                    )}
                    id={MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE}
                    checked={
                        this.state[MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE]
                    }
                    value={
                        this.state[MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.reporterCanSeeIssueSolution',
                    )}
                    id={REPORTER_CAN_SEE_ISSUE_SOLUTION}
                    checked={this.state[REPORTER_CAN_SEE_ISSUE_SOLUTION]}
                    value={this.state[REPORTER_CAN_SEE_ISSUE_SOLUTION]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerGetPushOnAllIssuesCreated',
                    )}
                    id={MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED}
                    checked={
                        this.state[MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED]
                    }
                    value={
                        this.state[MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.supervisorAndMaintainerGetChangeMail',
                    )}
                    id={SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL}
                    checked={
                        this.state[SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL]
                    }
                    value={
                        this.state[SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.reporterGetChangeMail')}
                    id={REPORTER_GET_CHANGE_MAIL}
                    checked={this.state[REPORTER_GET_CHANGE_MAIL]}
                    value={this.state[REPORTER_GET_CHANGE_MAIL]}
                    onChange={this.toggleCheckboxValue}
                />
                <div className={styles.submit}>
                    <CustomButton
                        text={t('dialog.title.edit')}
                        onClick={this.onSubmitEditEnvironmentHandler}
                        disabled={submitButtonDisabled}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation('environments')(EnvironmentAdminForm);
