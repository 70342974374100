import {SET_TASKS} from '../action/actionTypes';

const initialState = {
    tasks: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TASKS:
            return {
                ...state,
                tasks: action.tasks,
            };

        default:
            return state;
    }
};

export default reducer;
