import {roles as ROLES} from '../../constants/roles';

const mappings = {};

mappings[ROLES.MAINTAINER] = {};

mappings[ROLES.SUPERVISOR] = {};

mappings[ROLES.ADMIN] = {};

mappings[ROLES.SYSTEMADMIN] = {};

export {mappings};
