import React from 'react';
import styles from './mobileHeader.module.css';

const mobileHeader = props => (
    <div className={styles.cardHeader}>
        {React.Children.map(props.children, (child, index) => {
            const classForCell = props.classes[index];
            return <div className={styles[classForCell]}>{child}</div>;
        })}
    </div>
);

export default mobileHeader;
