import React from 'react';
import {useTranslation} from 'react-i18next';
import {
    AddButton,
    TimespanPicker,
    UserPicker,
    LocationsPicker,
    ElementsPicker,
    ListTitle,
    Picker,
    Checkbox,
} from '../../../common/components/index';

import styles from './ReportForm.module.css';
import {GENERATE_REPORT_WITH_ACTUAL_ISSUES} from '../../../containers/Reports/reports';
import {
    REPORT_FORMATS,
    REPORT_TYPES,
} from '../../../services/raportGenerator/reportGenerator';
import BranchPicker from '../../../common/components/branchPicker/BranchPicker';

const ReportForm = ({
    endDate,
    startDate,
    onGenerate,
    location,
    element,
    branch,
    user,
    onDatePickerChangeHandler,
    reportType,
    reportPickerTypes,
    users,
    branches,
    locations,
    elements,
    onReportTypeChange,
    onUserChange,
    onBranchChange,
    onLocationChange,
    onElementChange,
    showElementPicker,
    reportWithActualIssues,
    toggleReportWithActualIssuesCheckboxValue,
    reportFormat,
    handleFormatChange,
    reportFormatsPickerValues,
}) => {
    const {t} = useTranslation('reports');

    const disableDetailsPickers =
        (reportType.value === REPORT_TYPES.general ||
            reportType.value === REPORT_TYPES.branch_general) &&
        reportFormat.value === REPORT_FORMATS.pdf;

    const generateButtonDisabled = !(
        ((reportType.value === REPORT_TYPES.branch_general && branch) ||
            reportType.value !== REPORT_TYPES.branch_general) &&
        startDate &&
        endDate
    );

    return (
        <div className={styles.reportForm}>
            <header>
                <ListTitle title={t('title')} />
                <span className={styles.mobileStyleButton}>
                    <AddButton
                        className={styles.generateBtn}
                        text={t('submitBtn')}
                        disabled={generateButtonDisabled}
                        onClick={onGenerate}
                    />
                </span>
            </header>
            <div className={styles.whiteBackground}>
                <div className={styles.body}>
                    <Picker
                        label={t('reportType')}
                        placeholder={t('reportTypePlaceholder')}
                        handleChange={onReportTypeChange}
                        pickerItems={reportPickerTypes}
                        selectedValue={reportType}
                    />
                    <Checkbox
                        id={GENERATE_REPORT_WITH_ACTUAL_ISSUES}
                        label={t('withActualIssuesCheckbox')}
                        value={reportWithActualIssues}
                        onChange={toggleReportWithActualIssuesCheckboxValue}
                    />
                    <TimespanPicker
                        startDate={startDate}
                        endDate={endDate}
                        onDateChange={onDatePickerChangeHandler}
                    />
                    <Picker
                        label={t('reportFormatLabel')}
                        handleChange={handleFormatChange}
                        pickerItems={reportFormatsPickerValues}
                        selectedValue={reportFormat}
                        disabled={reportType.value !== REPORT_TYPES.detailed}
                    />
                    {reportType.value === REPORT_TYPES.branch_general && (
                        <BranchPicker
                            branches={branches}
                            handleChange={onBranchChange}
                        />
                    )}
                    {reportType.value === REPORT_TYPES.detailed && (
                        <UserPicker
                            label={t('employee')}
                            handleChange={onUserChange}
                            users={users}
                            clearable
                            selectedUser={user}
                            disabled={disableDetailsPickers}
                        />
                    )}
                    {reportType.value === REPORT_TYPES.detailed && (
                        <LocationsPicker
                            locations={locations}
                            handleChange={onLocationChange}
                            selectedLocation={location}
                            clearable
                            disabled={disableDetailsPickers}
                        />
                    )}
                    {reportType.value === REPORT_TYPES.detailed &&
                        showElementPicker && (
                            <ElementsPicker
                                elements={elements}
                                handleChange={onElementChange}
                                selectedElement={element}
                                clearable
                                disabled={disableDetailsPickers}
                            />
                        )}
                </div>
            </div>
        </div>
    );
};

export default ReportForm;
