import {SET_SELECTED_BRANCHES, SET_BRANCHES} from './actionTypes';
import {saveSelectedBranches} from '../../storage/localStorage';
import {Branch} from '../../models/branch';

export type SetSelectedBranchesAction = {
    type: typeof SET_SELECTED_BRANCHES;
    selectedBranches: Branch[];
};

export type SetBranchesAction = {
    type: typeof SET_BRANCHES;
    branches: Branch[];
};

const setSelectedBranches = (selectedBranches: Branch[]) => ({
    selectedBranches,
    type: SET_SELECTED_BRANCHES,
});

const setBranches = (branches: Branch[]) => ({
    branches,
    type: SET_BRANCHES,
});

export const fetchBranches =
    dispatch => (querySnapshot, setActivityIndicator) => {
        setActivityIndicator && setActivityIndicator(true);
        const branches = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
        }));

        if (setActivityIndicator) {
            setActivityIndicator(false);
        }

        dispatch(setBranches(branches));
    };

export const setUserSelectedBranches =
    (selectedBranches: Branch[]) => dispatch => {
        saveSelectedBranches(selectedBranches);
        dispatch(setSelectedBranches(selectedBranches));
    };
