import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Picker from '../picker/Picker';

const LocationsPicker = props => {
    const {t} = useTranslation('issues');

    const eventBased = useSelector(
        state => state.auth.organizationData.eventBased,
    );

    const branchNames = props.locations.reduce((set, location) => {
        set.add(location.branch.name);
        return set;
    }, new Set());

    const items =
        props.locations &&
        [...branchNames].map(name => {
            const options = props.locations
                .filter(location => location.branch.name === name)
                .map(location => ({
                    value: location.id,
                    label: location.name,
                    group: location.branch.name,
                }));
            return {label: name, options};
        });

    const selectedValue = props.selectedLocation && {
        value: props.selectedLocation.id,
        label: props.selectedLocation.name,
    };

    return (
        <Picker
            label={
                eventBased
                    ? t('details.eventBased.company')
                    : t('details.default.location')
            }
            selectedValue={selectedValue}
            pickerItems={items}
            name="Miejsca"
            first={props.first}
            placeholder={
                eventBased
                    ? t('details.eventBased.searchForCompany')
                    : t('details.default.searchForLocation')
            }
            handleChange={props.handleChange}
            className={props.className}
            disabled={props.disabled}
            clearable={props.clearable}
            menuPortalTarget={props.menuPortalTarget}
        />
    );
};

LocationsPicker.defaultProps = {
    bottomLabelActive: true,
};

export default LocationsPicker;
