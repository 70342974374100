import React from 'react';
import {roles as ROLES} from '../../constants/roles';
import {
    CustomTextField,
    LocationsPicker,
    ElementsPicker,
    ImageDisplay,
    UserPicker,
    CustomButton,
    StatusPicker,
    CustomDatePicker,
    ImageDropzone,
    RadioButton,
} from '../../common/components';
import {ISSUE_STATUS} from '../../models/issue/issue';
import CategoryPicker from '../../common/components/CategoryPicker/CategoryPicker';

const mappings = {};

mappings[ROLES.REPORTER] = {
    innerComponents: [
        props => null,
        props =>
            props.isCurrentUserIssueReporter && !props.isIssueArchived ? (
                <CustomButton {...props} />
            ) : null,
        props => (
            <LocationsPicker
                {...props}
                disabled={
                    !props.isCurrentUserIssueReporter || props.isIssueArchived
                }
            />
        ),
        props => (
            <ElementsPicker
                {...props}
                disabled={
                    !props.isCurrentUserIssueReporter || props.isIssueArchived
                }
            />
        ),
        props => <ImageDisplay {...props} />,
        props => <CustomDatePicker {...props} readOnly />,
        props => <CustomDatePicker {...props} readOnly />,
        props => <CustomTextField {...props} disabled />,
        props => (
            <CategoryPicker
                {...props}
                disabled={
                    !props.isCurrentUserIssueReporter || props.isIssueArchived
                }
            />
        ),
        props => <StatusPicker {...props} disabled />,
        props => <UserPicker {...props} disabled />,
        props => <CustomDatePicker {...props} readOnly />,
        props => <UserPicker {...props} disabled />,
        props => <RadioButton {...props} disabled />,
        props => (
            <CustomTextField
                {...props}
                disabled={
                    !props.isCurrentUserIssueReporter ||
                    props.isIssueArchived ||
                    props.reporterHasNoPermissionToEditIssueDescription
                }
            />
        ),
        props => (
            <ImageDropzone
                {...props}
                disabled={
                    !props.isCurrentUserIssueReporter || props.isIssueArchived
                }
            />
        ),
        props => props.reporterCanSeeIssueSolution && <hr {...props} />,
        props =>
            props.reporterCanSeeIssueSolution && (
                <CustomTextField {...props} disabled />
            ),
        props => null,
    ],
    roleProps: {
        statuses: [
            ISSUE_STATUS.open,
            ISSUE_STATUS.inProgress,
            ISSUE_STATUS.inReview,
        ],
    },
};

mappings[ROLES.MAINTAINER] = {
    innerComponents: [
        props => null,
        props => (!props.isIssueArchived ? <CustomButton {...props} /> : null),
        props => <LocationsPicker {...props} disabled />,
        props => (
            <ElementsPicker
                {...props}
                disabled={
                    !props.isCurrentUserIssueReporter || props.isIssueArchived
                }
            />
        ),
        props => <ImageDisplay {...props} />,
        props => <CustomDatePicker {...props} readOnly />,
        props => <CustomDatePicker {...props} readOnly />,
        props => <CustomTextField {...props} disabled />,
        props => (
            <CategoryPicker
                {...props}
                disabled={
                    !props.isCurrentUserIssueReporter || props.isIssueArchived
                }
            />
        ),
        props => <StatusPicker {...props} disabled={props.isIssueArchived} />,
        props => (
            <UserPicker
                {...props}
                disabled={
                    props.isIssueArchived ||
                    props.maintainerHasNoPermissionToAssignUsersToIssue
                }
            />
        ),
        props => <CustomDatePicker {...props} readOnly />,
        props => <UserPicker {...props} disabled />,
        props => <RadioButton {...props} disabled />,
        props => (
            <CustomTextField
                {...props}
                disabled={
                    !props.isCurrentUserIssueReporter ||
                    props.isIssueArchived ||
                    props.maintainerHasNoPermissionToEditIssueDescription
                }
            />
        ),
        props => (
            <ImageDropzone
                {...props}
                disabled={
                    !props.isCurrentUserIssueReporter || props.isIssueArchived
                }
            />
        ),
        props => <hr {...props} />,
        props => (
            <CustomTextField {...props} disabled={props.isIssueArchived} />
        ),
        props => <ImageDropzone {...props} disabled={props.isIssueArchived} />,
    ],
    roleProps: {
        statuses: [
            ISSUE_STATUS.open,
            ISSUE_STATUS.inProgress,
            ISSUE_STATUS.inReview,
        ],
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.SUPERVISOR] = {
    innerComponents: [
        props => null,
        props => (!props.isIssueArchived ? <CustomButton {...props} /> : null),
        props => (
            <LocationsPicker {...props} disabled={props.isIssueArchived} />
        ),
        props => <ElementsPicker {...props} disabled={props.isIssueArchived} />,
        props => <ImageDisplay {...props} />,
        props => <CustomDatePicker {...props} readOnly />,
        props => <CustomDatePicker {...props} readOnly />,
        props => <CustomTextField {...props} disabled />,
        props => <CategoryPicker {...props} disabled={props.isIssueArchived} />,
        props => <StatusPicker {...props} disabled={props.isIssueArchived} />,
        props => <UserPicker {...props} disabled={props.isIssueArchived} />,
        props => <CustomDatePicker {...props} readOnly />,
        props => <UserPicker {...props} disabled />,
        props => <RadioButton {...props} disabled={props.isIssueArchived} />,
        props => (
            <CustomTextField {...props} disabled={props.isIssueArchived} />
        ),
        props => <ImageDropzone {...props} disabled={props.isIssueArchived} />,
        props => <hr {...props} />,
        props => (
            <CustomTextField {...props} disabled={props.isIssueArchived} />
        ),
        props => <ImageDropzone {...props} disabled={props.isIssueArchived} />,
    ],
    roleProps: {
        statuses: [
            ISSUE_STATUS.open,
            ISSUE_STATUS.inProgress,
            ISSUE_STATUS.inReview,
            ISSUE_STATUS.done,
        ],
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.ADMIN] = {
    innerComponents: [
        props => <CustomButton {...props} />,
        props => <CustomButton {...props} />,
        props => <LocationsPicker {...props} />,
        props => <ElementsPicker {...props} />,
        props => <ImageDisplay {...props} />,
        props => <CustomDatePicker {...props} readOnly />,
        props => <CustomDatePicker {...props} />,
        props => <CustomTextField {...props} disabled />,
        props => <CategoryPicker {...props} />,
        props => <StatusPicker {...props} />,
        props => <UserPicker {...props} />,
        props => <CustomDatePicker {...props} />,
        props => <UserPicker {...props} />,
        props => <RadioButton {...props} />,
        props => <CustomTextField {...props} />,
        props => <ImageDropzone {...props} />,
        props => <hr {...props} />,
        props => <CustomTextField {...props} />,
        props => <ImageDropzone {...props} />,
    ],
    roleProps: {
        statuses: [
            ISSUE_STATUS.open,
            ISSUE_STATUS.inProgress,
            ISSUE_STATUS.inReview,
            ISSUE_STATUS.done,
        ],
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.SYSTEMADMIN] = {
    innerComponents: [
        props => <CustomButton {...props} />,
        props => <CustomButton {...props} />,
        props => <LocationsPicker {...props} />,
        props => <ElementsPicker {...props} />,
        props => <ImageDisplay {...props} />,
        props => <CustomDatePicker {...props} readOnly />,
        props => <CustomDatePicker {...props} />,
        props => <CustomTextField {...props} disabled />,
        props => <CategoryPicker {...props} />,
        props => <StatusPicker {...props} />,
        props => <UserPicker {...props} />,
        props => <CustomDatePicker {...props} />,
        props => <UserPicker {...props} />,
        props => <RadioButton {...props} />,
        props => <CustomTextField {...props} />,
        props => <ImageDropzone {...props} />,
        props => <hr {...props} />,
        props => <CustomTextField {...props} />,
        props => <ImageDropzone {...props} />,
    ],
    roleProps: {
        statuses: [
            ISSUE_STATUS.open,
            ISSUE_STATUS.inProgress,
            ISSUE_STATUS.inReview,
            ISSUE_STATUS.done,
        ],
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

export {mappings};
