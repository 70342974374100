import React from 'react';
import {CustomCircularProgress, ListActionPanel} from '../../common/components';
import EnvironmentsList from '../../components/Environment/EnvironmentList/EnvironmentList';
import AddEnvironment from '../../components/Environment/AddEnvironment/AddEnviromnent';
import EditEnvironment from '../../components/Environment/AddEnvironment/EditEnvironment';

const EnvironmentPageLayout = ({props}) => {
    const {t} = props;

    return (
        <div>
            {props?.actionPending && <CustomCircularProgress />}
            <ListActionPanel
                searchPlaceholder={t('main.searchPlaceholder')}
                title={t('main.title')}
                addButtonText={t('main.addBtn')}
                onSearchTextChange={() => {}}
                onToggleAddDialogHandler={props.toggleAddDialog}
                showAddButton
            />
            {props.addDialogOpen && (
                <AddEnvironment
                    handleClose={props.toggleAddDialog}
                    onSubmitHandler={props.onSubmitEnvironment}
                    dialogTitle={t('dialog.title.add')}
                    loading={props.importEnvironmentPending}
                />
            )}
            {props.editDialogOpen && (
                <EditEnvironment
                    handleClose={props.toggleEditDialog}
                    onSubmitHandler={props.onSubmitUpdateEnvironment}
                    environment={props.environmentSelectedForEdit}
                    dialogTitle={t('dialog.title.edit')}
                    loading={props.importEnvironmentPending}
                />
            )}
            {props.environments && (
                <EnvironmentsList
                    onEnvironmentClickHandler={props.onEnvironmentClickHandler}
                    onDeleteEnvironmentHandler={
                        props.onDeleteEnvironmentHandler
                    }
                    rows={props.environments}
                />
            )}
        </div>
    );
};

export default EnvironmentPageLayout;
