import {IssueGeneratorPayload} from './issueGeneratorOptions';
import axios from '../../../config/axios/axiosConfig';
import {ISSUES_REPORT} from '../../../constants/CloudFunctions';
import {NoReportDataError} from '../reportErrors';
import {Issue} from '../../../models/issue/issue';

export type ReportIssuesPayload = {
    archivedIssues: Record<string, Record<string, Issue[]>>;
    actualIssues: Record<string, Record<string, Issue[]>>;
    summary: {
        openIssuesAmount: number;
        inProgressIssuesAmount: number;
        inReviewIssuesAmount: number;
        doneIssuesAmount: number;
    }
};

export const fetchReportIssuesData = async ({
    endDate,
    startDate,
    withActualIssues,
    elementId,
    locationId,
    userId,
    branchId,
}: IssueGeneratorPayload) => {
    const result = await axios.get<ReportIssuesPayload>(ISSUES_REPORT, {
        params: {
            withActualIssues,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            user: userId || 'all',
            location: locationId || 'all',
            element: elementId || 'all',
            branch: branchId || 'all',
        },
    });

    const archivedIssuesMissing =
        !result.data ||
        !result.data.archivedIssues ||
        (Object.keys(result.data.archivedIssues).length === 0 &&
            result.data.archivedIssues.constructor === Object);

    const actualIssuesMissing =
        !result.data ||
        !result.data.actualIssues ||
        (Object.keys(result.data.actualIssues).length === 0 &&
            result.data.actualIssues.constructor === Object);

    if (archivedIssuesMissing && actualIssuesMissing) {
        throw new NoReportDataError();
    }

    return result.data;
};
