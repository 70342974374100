import {SET_CATEGORIES} from './actionTypes';

export const fetchCategories =
    dispatch => (querySnapshot, setActivityIndicator) => {
        setActivityIndicator && setActivityIndicator(true);
        const categories = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
        }));
        setActivityIndicator && setActivityIndicator(false);
        dispatch(setCategories(categories));
    };

export const setCategories = categories => ({
    categories,
    type: SET_CATEGORIES,
});
