import {mergeArrays} from '../array/array';

export default class MultiSnapshotHandler {
    _listenersCount = 0;

    _firedListenersCount = 0;

    _callback = null;

    _results = null;

    constructor(listenersCount, callback) {
        this._listenersCount = listenersCount;
        this._callback = callback;
        this._results = new Array(this._listenersCount);
    }

    addResult(result, index) {
        this._results[index] = result;
        this._firedListenersCount++;
        if (this._firedListenersCount >= this._listenersCount) {
            const finalResult = mergeArrays(this._results);
            this._callback(finalResult);
        }
    }
}
