import React from 'react';
import styles from './element.module.css';

const elements = ({elements}) => {
    const content = elements.slice(0, 3).map(element => (
        <div key={element.id} className={styles.elementName}>
            {element.name}
        </div>
    ));
    if (elements.length > 3) {
        content.push(
            <div key="unique key for 3 dots" className={styles.elementName}>
                ...
            </div>,
        );
    }
    return content;
};
export default elements;
