import {type ReportType} from '../../services/raportGenerator/reportGenerator';

export const reportsTypes: {
    label: string;
    value: ReportType;
}[] = [
    {label: 'reportTypes.general', value: 'general'},
    {label: 'reportTypes.detailed', value: 'detailed'},
];

export type PickerReportType = (typeof reportsTypes)[number];

export const GENERATE_REPORT_WITH_ACTUAL_ISSUES =
    'generateReportWithActualIssues';

export const reportFormats = [
    {label: 'reportFormats.pdf', value: 'pdf'},
    {label: 'reportFormats.csv', value: 'csv'},
] as const;

export type PickerReportFormat = (typeof reportFormats)[number];
