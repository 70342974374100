import {makeStyles} from '@material-ui/core';
import MuiButton, {
    ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button/Button';
import classNames from 'classnames';

type ColorSet = {
    color: string;
    backgroundColor: string;
    borderColor?: string;
};

const useStyles = (customColor?: ColorSet) =>
    makeStyles(() => ({
        base: {
            height: '40px',
            borderRadius: '4px',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '19px',
            letterSpacing: '0.02em',
            textTransform: 'none',
            ...customColor,
        },
    }));

const colorSets = {
    error: {
        outlined: {
            color: '#FF6969',
            backgroundColor: 'none',
            borderColor: '#FF6969',
        },
    },
    grey: {
        outlined: {
            color: '#3F4146',
            backgroundColor: 'none',
            borderColor: '#3F4146',
        },
    },
    primary: {
        contained: {
            color: '#FFFFFF',
            backgroundColor: '#047BEB',
        },
        text: {
            color: '#047BEB',
            backgroundColor: 'none',
        },
    },
};

const getColorSet = (
    variant: MuiButtonProps['variant'],
    customColor?: ButtonProps['customColor'],
) => {
    if (customColor && customColor in colorSets) {
        if (variant in colorSets[customColor]) {
            return colorSets[customColor][variant];
        }
    }
};

type ButtonProps = {
    marginTop?: number;
    marginBottom?: number;
    className?: string;
    customColor?: 'error' | 'grey' | 'primary';
} & Omit<MuiButtonProps, 'color'>;

export const Button = ({
    marginBottom,
    marginTop,
    className,
    customColor,
    ...props
}: ButtonProps) => {
    const classes = useStyles(getColorSet(props.variant, customColor))();

    return (
        <MuiButton
            {...props}
            style={{
                marginTop,
                marginBottom,
            }}
            className={classNames(classes.base, className)}
        />
    );
};
