import React, {Component} from 'react';
import {isEqual} from 'lodash';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {
    List,
    ListItem,
    ListColumnCreator,
    MobileListItem,
    MobileList,
    MobileDataDisplay,
} from '../../../common/components/index';

class BranchesList extends Component {
    state = {
        rows: [],
    };

    columns;

    constructor(props) {
        super(props);
        const {t} = this.props;

        this.columns = [
            new ListColumnCreator(
                'name',
                this.props.organizationData.eventBased
                    ? t('table.columns.eventBased.name')
                    : t('table.columns.default.name'),
                {},
            ),
        ];
    }

    componentDidMount() {
        this.setState({rows: this.props.branches});
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.branches, prevProps.branches)) {
            this.setState({rows: this.props.branches});
        }
    }

    render() {
        const {rows} = this.state;
        return (
            <>
                <List
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getBranchListItemTemplate}
                    name="branches"
                />
                <MobileList
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getBranchMobileListItemTemplate}
                />
            </>
        );
    }

    getBranchListItemTemplate = branch => (
        <ListItem
            key={branch.id}
            onClick={this.props.onBranchClick(branch.id)}
            deletable
            onDelete={() => this.props.onDeleteBranch(branch)}
        >
            {branch.name}
        </ListItem>
    );

    getBranchMobileListItemTemplate = branch => (
        <MobileListItem
            key={branch.id}
            onClick={this.props.onBranchClick(branch.id)}
        >
            <MobileDataDisplay title={branch.name} withoutImage />
        </MobileListItem>
    );
}

const mapStateToProps = state => ({
    organizationData: state.auth.organizationData,
});

export default connect(mapStateToProps)(
    withTranslation('branches')(BranchesList),
);
