import type {ElementId, LocationId} from '../../models';
import {
    SET_SCANNED_ELEMENT,
    CLEAR_SCANNED_ELEMENT,
} from '../action/actionTypes';

export type QrCodeState = {
    id: ElementId | null;
    locationId: LocationId | null;
};

const initialState: QrCodeState = {
    id: null,
    locationId: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SCANNED_ELEMENT:
            return action.payload;
        case CLEAR_SCANNED_ELEMENT:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
