import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import LogoutButton from '../UserStatusPanel/UserLogoutButton';
import styles from './UserName.module.css';
import userLogo from '../../static/images/icons/user.svg';

const userName = props => (
    <div
        className={styles.userName}
        onMouseEnter={props.onExpandButtonClick}
        onMouseLeave={props.onExpandButtonClick}
    >
        <div className={styles.expandClickable}>
            <img className={styles.userLogo} alt="" src={userLogo} />
            <p>{props.userName}</p>
            <ExpandMore className={styles.expandIcon} />
        </div>
        <Collapse
            in={props.menuExpanded}
            className={styles.menu}
            style={{zIndex: 1}}
        >
            <LogoutButton />
        </Collapse>
    </div>
);

export default userName;
