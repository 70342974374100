import {SET_USERS} from './actionTypes';
import {
    DELETE_USER,
    CREATE_NEW_USER,
    UPDATE_USER_PASSWORD,
} from '../../constants/CloudFunctions';
import axios from '../../config/axios/axiosConfig';
import * as Endpoints from '../../constants/endpoints';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

export const setUsers = users => ({
    type: SET_USERS,
    users,
});

export const updateUser =
    dispatch => (documentId, user, onSuccess, onError) => {
        Endpoints.users()
            .doc(documentId)
            .update(user)
            .then(onSuccess)
            .catch(error => {
                errorHandler(error);
                onError();
            });
    };

export const updateUserPassword =
    dispatch => async (userUid, newPassword, onSuccess, onError) => {
        try {
            const config = {
                data: {
                    uid: userUid,
                    newPassword,
                },
            };
            await axios.post(UPDATE_USER_PASSWORD, config);
            onSuccess();
        } catch (error) {
            const passwordUpdateResponse = error.response.data;
            onError('passwordManagerModal.notifications.passwordUpdateError');
            passwordChangeErrorHandler(passwordUpdateResponse?.code, onError);
        }
    };

const passwordChangeErrorHandler = (code, onError) => {
    switch (code) {
        case 'auth/invalid-password':
            onError('passwordManagerModal.notifications.invalidPasswordError');
            break;
        default:
            onError('passwordManagerModal.notifications.passwordUpdateError');
            break;
    }
};

export const deleteUser = dispatch => async (userUid, onSuccess, onError) => {
    try {
        const config = {
            data: {
                uid: userUid,
            },
        };
        await axios.delete(DELETE_USER, config);
        onSuccess();
    } catch (error) {
        if (error.response) {
            const deleteUserResponse = error.response.data;
            deleteUserErrorHandler(deleteUserResponse.code, onError);
        } else {
            onError('notifications.details.userDeleteError');
        }
    }
};

const deleteUserErrorHandler = (code, onError) => {
    switch (code) {
        case 'auth/user-not-found':
            onError('notifications.details.userNotFoundError');
            break;
        default:
            onError('notifications.details.userDeleteError');
            break;
    }
};

export const onUserAdded = dispatch => async (user, onError, onSuccess) => {
    try {
        await axios.post(CREATE_NEW_USER, user);
        onSuccess();
    } catch (err) {
        const createNewUserResponse = err.response.data;
        addUserErrorHandler(createNewUserResponse.code, onError);
    }
};

const addUserErrorHandler = (code, onError) => {
    switch (code) {
        case 'auth/email-already-exists':
            onError('notifications.dialog.emailTakenError');
            break;
        case 'auth/invalid-email':
            onError('notifications.dialog.emailInvalidError');
            break;
        case 'auth/invalid-password':
            onError('notifications.dialog.passwordInvalidError');
            break;
        default:
            onError('notifications.dialog.defaultError');
    }
};
