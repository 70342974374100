import {isEqual} from 'lodash';

export const chunkArray = (array, chunkSize) => {
    const results = [];

    while (array.length) {
        results.push(array.splice(0, chunkSize));
    }

    return results;
};

export const mergeArrays = arrayOfArrays => [].concat(...arrayOfArrays);

export const isEmptyArray = array => Array.isArray(array) && array.length === 0;

export const areArraysEqual = (array, items) => isEqual(array, items);
