import React from 'react';
import * as moment from 'moment';
import classnames from 'classnames';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
    List,
    ListItem,
    ListColumnCreator,
} from '../../../common/components/index';
import Priority from './IssueListItem/display/priority/priority';
import Status from './IssueListItem/display/Status/Status';
import MobileList from '../../../common/components/list/MobileList';
import ListItemMobile from '../../../common/components/list/listItem/ListItemMobile/ListItemMobile';
import MobileHeader from '../../../common/components/list/listItem/display/mobileHeader/mobileHeader';
import MobileDataDisplay from '../../../common/components/list/listItem/display/mobileDataDisplay/moblieDataDisplay';
import {
    statusComparator,
    maintainerComparator,
    issueCreateDateComparator,
    priorityComparator,
    issuePriorityComparator,
} from '../../../utils/sorting/sorting';
import {isOutdated} from '../../../utils/issue/issueUtils';
import ElementName from '../../../common/components/list/listItem/display/elementName/elementName';
import styles from './IssuesList.module.css';
import {LocationName} from './IssueListItem/display/locationName/locationName';

const IssuesList = props => {
    const {t} = useTranslation('issues');

    const eventBased = useSelector(
        state => state.auth.organizationData.eventBased,
    );
    const issueExpiration = useSelector(
        state => state.auth.organizationData.issueExpiration,
    );

    const columns = [
        new ListColumnCreator(
            'elementName',
            eventBased
                ? t('table.columns.eventBased.workStation')
                : t('table.columns.default.element'),
            {
                accessAttribute: issue => issue.element.name,
            },
        ),
        new ListColumnCreator('id', t('table.columns.default.id'), {}),
        new ListColumnCreator(
            'locationName',
            eventBased
                ? t('table.columns.eventBased.company')
                : t('table.columns.default.location'),
            {
                accessAttribute: issue => issue.element.location.name,
            },
        ),
        new ListColumnCreator(
            'branchName',
            eventBased
                ? t('table.columns.eventBased.expo')
                : t('table.columns.default.branch'),
            {
                accessAttribute: issue => issue.element.location.branch.name,
            },
        ),
        new ListColumnCreator(
            'issueDescription',
            t('table.columns.default.description'),
            {},
        ),
        new ListColumnCreator(
            'createdDate',
            t('table.columns.default.creationDate'),
            {},
        ),
        new ListColumnCreator(
            'assignedTo',
            t('table.columns.default.assignedTo'),
            {
                customComparator: maintainerComparator,
            },
        ),
        new ListColumnCreator('priority', t('table.columns.default.priority'), {
            customComparator: priorityComparator,
        }),
        new ListColumnCreator('status', t('table.columns.default.status'), {
            customComparator: statusComparator,
        }),
    ];

    const classes = ['elementName', 'image'];

    const sortIssues = issues =>
        [...issues].sort(
            (a, b) =>
                issuePriorityComparator(a, b) ||
                issueCreateDateComparator(a, b),
        );

    const columnIds = columns.map(column => column.id);
    const getIssueListItemTemplate = issue => {
        const outdated = isOutdated(
            issue.priority,
            issue.history,
            issue.status,
        );
        return (
            <ListItem
                key={issue.id}
                onClick={props.onIssueClick(issue.id)}
                className={classnames({
                    [styles.overdue]: issueExpiration && outdated,
                })}
                columns={columnIds}
            >
                <ElementName
                    elementName={issue.element.name}
                    iconUri={issue.iconUri}
                />
                {issue.id.toLowerCase().slice(0, 6)}
                <LocationName
                    locationName={issue.element.location.name}
                    hasLocationMarker={!!issue.locationMarker}
                />
                {issue.element.location.branch.name}
                {issue.issueDescription}
                {moment(issue.createdDate).format('HH:mm DD/MM/YYYY')}
                {issue.assignedTo &&
                    `${issue.assignedTo.name} ${issue.assignedTo.surname}`}
                <Priority
                    priority={issue.priority}
                    highPriority={issue.highPriority}
                    urgentPriority={issue.urgentPriority}
                />
                <Status status={issue.status} />
            </ListItem>
        );
    };

    const getMobileIssueListItemTemplate = issue => {
        const outdated = isOutdated(
            issue.highPriority,
            issue.history,
            issue.status,
        );
        return (
            <ListItemMobile
                key={issue.id}
                onClick={props.onIssueClick(issue.id)}
                className={classnames({
                    [styles.overdue]: outdated,
                })}
            >
                <MobileHeader classes={['status', 'date']}>
                    <Status status={issue.status} />
                    {moment(issue.createdDate).format('DD/MM/YYYY')}
                </MobileHeader>
                <MobileDataDisplay
                    image={issue.iconUri}
                    left={issue.element.name}
                    right={issue.element.location.name}
                    title={
                        issue.issueDescription
                            ? issue.issueDescription
                            : t('mobile.noIssueName')
                    }
                />
            </ListItemMobile>
        );
    };

    const {issues} = props;
    const sortedIssues = sortIssues(issues);

    return (
        <>
            <List
                columns={columns}
                rows={sortedIssues}
                rowTemplate={getIssueListItemTemplate}
                name="issues"
            />
            <MobileList
                columns={classes}
                rows={sortedIssues}
                rowTemplate={getMobileIssueListItemTemplate}
            />
        </>
    );
};

export default IssuesList;
