import React from 'react';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';

import './Box.css';

const Box = props => (
    <Paper
        classes={{root: classnames('box-container', props.className)}}
        elevation={0}
    >
        {props.children}
    </Paper>
);

export default Box;
