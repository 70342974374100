import React from 'react';

import styles from './ListTitle.module.css';

const ListTitle = props => (
    <div className={`${styles.listTitle} ${props.className}`}>
        {props.title}
    </div>
);

export default ListTitle;
