import React from 'react';
import {useTranslation} from 'react-i18next';
import {ListActionPanel} from '../../../common/components/index';

const UsersPageControl = ({
    onSearchTextChange,
    onToggleAddDialogHandler,
    showAddButton,
}) => {
    const {t} = useTranslation('users');

    return (
        <ListActionPanel
            searchPlaceholder={t('main.searchPlaceholder')}
            title={t('main.title')}
            addButtonText={t('main.actionBtn')}
            onSearchTextChange={onSearchTextChange}
            onToggleAddDialogHandler={onToggleAddDialogHandler}
            showAddButton={showAddButton}
        />
    );
};

export default UsersPageControl;
