import React, {Component} from 'react';
import {isEqual} from 'lodash';
import {withTranslation} from 'react-i18next';
import {
    List,
    ListItem,
    ListColumnCreator,
    MobileListItem,
    MobileList,
    MobileDataDisplay,
} from '../../../common/components/index';

class CategoriesList extends Component {
    state = {
        rows: [],
    };

    constructor(props) {
        super(props);
        const {t} = props;

        this.columns = [
            new ListColumnCreator('name', t('table.columns.name'), {}),
        ];
    }

    componentDidMount() {
        this.setState({rows: this.props.categories});
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.categories, prevProps.categories)) {
            this.setState({rows: this.props.categories});
        }
    }

    getCategoryListItemTemplate = category => (
        <ListItem
            key={category.id}
            onClick={this.props.onCategoryClick(category.id)}
            deletable
            onDelete={() => this.props.onDeleteCategory(category)}
        >
            {category.name}
        </ListItem>
    );

    getCategoryMobileListItemTemplate = category => (
        <MobileListItem
            key={category.id}
            onClick={this.props.onCategoryClick(category.id)}
        >
            <MobileDataDisplay title={category.name} withoutImage />
        </MobileListItem>
    );

    render() {
        const {rows} = this.state;
        return (
            <>
                <List
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getCategoryListItemTemplate}
                    name="categories"
                />
                <MobileList
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getCategoryMobileListItemTemplate}
                />
            </>
        );
    }
}

export default withTranslation('categories')(CategoriesList);
