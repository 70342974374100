export const saveUserData = userData => {
    localStorage.setItem('userData', JSON.stringify(userData));
};

export const saveSelectedBranches = selectedBranches => {
    localStorage.setItem('selectedBranches', JSON.stringify(selectedBranches));
};

export const issuesListFiltersStorage = {
    storageKey: 'issuesListFilters',

    saveIssueFilters: filters => {
        localStorage.setItem(
            issuesListFiltersStorage.storageKey,
            JSON.stringify(filters),
        );
    },

    getIssueFiltersFromStorage: () => {
        const filters = localStorage.getItem(
            issuesListFiltersStorage.storageKey,
        );

        if (!filters) {
            console.warn('issueFilters are empty');
        }

        return filters ? JSON.parse(filters) : null;
    },
};

export const tableColumnsSortStorage = {
    getStorageKey: tableName => `tableColumnsSort-${tableName}`,

    saveTableColumnsSort: (tableName, sort) => {
        localStorage.setItem(
            tableColumnsSortStorage.getStorageKey(tableName),
            JSON.stringify(sort),
        );
    },

    getTableColumnsSortFromStorage: tableName => {
        const sort = localStorage.getItem(
            tableColumnsSortStorage.getStorageKey(tableName),
        );

        if (!sort) {
            console.warn('tableColumnsSort are empty');
            return null;
        }

        return JSON.parse(sort);
    },
};

export const getUserDataFromStorage = () => {
    const userData = localStorage.getItem('userData');

    if (!userData) {
        console.warn('userData is empty');
    }

    return userData ? JSON.parse(userData) : null;
};

export const getSelectedBranchesFromStorage = () => {
    const branches = localStorage.getItem('selectedBranches');

    if (!branches) {
        console.warn('selectedBranches are empty');
    }

    return branches ? JSON.parse(branches) : null;
};

export const clearStorage = () => {
    localStorage.clear();
};
