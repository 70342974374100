import React from 'react';
import {
    ELEMENTS,
    LOCATIONS,
    REPORTS,
    USERS,
    ARCHIVED_ISSUES,
    TASKS,
    BRANCHES,
    CATEGORIES,
    CRUD,
    ENVIRONMENT,
} from '../../../constants/routes';
import NavigationItem from '../NavigationItem/NavigationItem';
import Icons from './DrawerIcons';
import {roles} from '../../../constants/roles';

export const drawerItemsForRole = translate => ({
    [roles.SYSTEMADMIN]: {
        innerComponents: [
            props => (
                <NavigationItem
                    path={LOCATIONS}
                    icon={<Icons.LocationIcon />}
                    title={
                        props?.eventBased
                            ? translate('tabs.eventBased.locations')
                            : translate('tabs.default.locations')
                    }
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={TASKS}
                    icon={<Icons.TasksIcon />}
                    title={translate('tabs.default.tasks')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={ELEMENTS}
                    icon={<Icons.ElementsIcon />}
                    title={
                        props?.eventBased
                            ? translate('tabs.eventBased.elements')
                            : translate('tabs.default.elements')
                    }
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={ARCHIVED_ISSUES}
                    icon={<Icons.ArchiveIcon />}
                    title={translate('tabs.default.archive')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={REPORTS}
                    icon={<Icons.ReportsIcon />}
                    title={translate('tabs.default.reports')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={USERS}
                    icon={<Icons.UsersIcon />}
                    title={translate('tabs.default.users')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={BRANCHES}
                    icon={<Icons.BranchesIcon />}
                    title={
                        props?.eventBased
                            ? translate('tabs.eventBased.branches')
                            : translate('tabs.default.branches')
                    }
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={CATEGORIES}
                    icon={<Icons.CategoriesIcon />}
                    title={translate('tabs.default.categories')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={CRUD}
                    icon={<Icons.CRUDIcon />}
                    title={translate('tabs.default.environments')}
                    {...props}
                />
            ),
        ],
    },
    [roles.ADMIN]: {
        innerComponents: [
            props => (
                <NavigationItem
                    path={LOCATIONS}
                    icon={<Icons.LocationIcon />}
                    title={
                        props?.eventBased
                            ? translate('tabs.eventBased.locations')
                            : translate('tabs.default.locations')
                    }
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={TASKS}
                    icon={<Icons.TasksIcon />}
                    title={translate('tabs.default.tasks')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={ELEMENTS}
                    icon={<Icons.ElementsIcon />}
                    title={
                        props?.eventBased
                            ? translate('tabs.eventBased.elements')
                            : translate('tabs.default.elements')
                    }
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={ARCHIVED_ISSUES}
                    icon={<Icons.ArchiveIcon />}
                    title={translate('tabs.default.archive')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={REPORTS}
                    icon={<Icons.ReportsIcon />}
                    title={translate('tabs.default.reports')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={USERS}
                    icon={<Icons.UsersIcon />}
                    title={translate('tabs.default.users')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={BRANCHES}
                    icon={<Icons.BranchesIcon />}
                    title={
                        props?.eventBased
                            ? translate('tabs.eventBased.branches')
                            : translate('tabs.default.branches')
                    }
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={CATEGORIES}
                    icon={<Icons.CategoriesIcon />}
                    title={translate('tabs.default.categories')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={ENVIRONMENT}
                    icon={<Icons.CRUDIcon />}
                    title={translate('tabs.default.environment')}
                    {...props}
                />
            ),
        ],
    },
    [roles.SUPERVISOR]: {
        innerComponents: [
            props => (
                <NavigationItem
                    path={LOCATIONS}
                    icon={<Icons.LocationIcon />}
                    title={
                        props?.eventBased
                            ? translate('tabs.eventBased.locations')
                            : translate('tabs.default.locations')
                    }
                    {...props}
                />
            ),
            props =>
                props.supervisorHasAccessToTasks && (
                    <NavigationItem
                        path={TASKS}
                        icon={<Icons.TasksIcon />}
                        title={translate('tabs.default.tasks')}
                        {...props}
                    />
                ),
            props => (
                <NavigationItem
                    path={ELEMENTS}
                    icon={<Icons.ElementsIcon />}
                    title={
                        props?.eventBased
                            ? translate('tabs.eventBased.elements')
                            : translate('tabs.default.elements')
                    }
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={ARCHIVED_ISSUES}
                    icon={<Icons.ArchiveIcon />}
                    title={translate('tabs.default.archive')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={REPORTS}
                    icon={<Icons.ReportsIcon />}
                    title={translate('tabs.default.reports')}
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={USERS}
                    icon={<Icons.UsersIcon />}
                    title={translate('tabs.default.users')}
                    {...props}
                />
            ),
            props => null,
            props => null,
            props => null,
        ],
    },
    [roles.MAINTAINER]: {
        innerComponents: [
            props => (
                <NavigationItem
                    path={LOCATIONS}
                    icon={<Icons.LocationIcon />}
                    title={
                        props?.eventBased
                            ? translate('tabs.eventBased.locations')
                            : translate('tabs.default.locations')
                    }
                    {...props}
                />
            ),
            () => null,
            props => (
                <NavigationItem
                    path={ELEMENTS}
                    icon={<Icons.ElementsIcon />}
                    title={
                        props?.eventBased
                            ? translate('tabs.eventBased.elements')
                            : translate('tabs.default.elements')
                    }
                    {...props}
                />
            ),
            props => (
                <NavigationItem
                    path={ARCHIVED_ISSUES}
                    icon={<Icons.ArchiveIcon />}
                    title={translate('tabs.default.archive')}
                    {...props}
                />
            ),
            props => null,
            props => null,
            props => null,
            props => null,
            props => null,
        ],
    },
    [roles.REPORTER]: {
        innerComponents: [
            props => null,
            props => null,
            props => null,
            props => null,
            props => null,
            props => null,
            props => null,
            props => null,
            props => null,
        ],
    },
});
