import React from 'react';
import {roles as ROLES} from '../../../constants/roles';
import {Checkbox, UserPicker} from '../../../common/components';
import CategoryPicker from '../../../common/components/CategoryPicker/CategoryPicker';

export const mappings = {};

mappings[ROLES.MAINTAINER] = {
    innerComponents: [
        props => <CategoryPicker {...props} />,
        props => <Checkbox {...props} />,
        props =>
            !props.maintainerHasNoPermissionToAssignUsersToIssue ? (
                <UserPicker {...props} />
            ) : null,
    ],
    roleProps: {
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.SUPERVISOR] = {
    innerComponents: [
        props => <CategoryPicker {...props} />,
        props => <Checkbox {...props} />,
        props => <UserPicker {...props} />,
    ],
    roleProps: {
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.ADMIN] = {
    innerComponents: [
        props => <CategoryPicker {...props} />,
        props => <Checkbox {...props} />,
        props => <UserPicker {...props} />,
    ],
    roleProps: {
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.SYSTEMADMIN] = {
    innerComponents: [
        props => <CategoryPicker {...props} />,
        props => <Checkbox {...props} />,
        props => <UserPicker {...props} />,
    ],
    roleProps: {
        rolesToFetchUsersWith: [ROLES.MAINTAINER, ROLES.SUPERVISOR],
    },
};

mappings[ROLES.REPORTER] = {
    innerComponents: [
        props => <CategoryPicker {...props} />,
        props => null,
        props => null,
    ],
    roleProps: {
        rolesToFetchUsersWith: [],
    },
};
