import styles from './LocalizationPickerButtonOverlay.module.css';
import {
    leafletMarkerIcon,
    leafletMarkerWhiteIcon,
} from '../../../../../static/images/icons';
import {useTranslation} from 'react-i18next';

export const LocalizationPickerButtonOverlay = () => {
    const {t} = useTranslation('issues');

    return (
        <div className={styles.overlayContainer}>
            <div className={styles.defaultContent}>
                <img className={styles.overlayIcon} src={leafletMarkerIcon} />
            </div>
            <div className={styles.overlayHoverContainer}>
                <img
                    src={leafletMarkerWhiteIcon}
                    className={styles.overlayIcon}
                />
                <span className={styles.previewText}>
                    {t('details.default.pickLocationPreview')}
                </span>
            </div>
        </div>
    );
};
