import React, {Component} from 'react';
import {isEqual} from 'lodash';
import {withTranslation} from 'react-i18next';
import {
    List,
    ListItem,
    ListColumnCreator,
    MobileListItem,
    MobileList,
    MobileDataDisplay,
} from '../../../common/components/index';

class EnvironmentsList extends Component {
    state = {
        rows: [],
    };

    columns;

    constructor(props) {
        super(props);
        const {t} = props;
        this.columns = [
            new ListColumnCreator('id', t('table.columns.name'), {}),
        ];
    }

    componentDidMount() {
        this.setState({rows: this.props.rows});
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.rows, prevProps.rows)) {
            this.setState({rows: this.props.rows});
        }
    }

    render() {
        const {rows} = this.state;

        return (
            <>
                <List
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getBranchListItemTemplate}
                    name="environments"
                />
                <MobileList
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getBranchMobileListItemTemplate}
                />
            </>
        );
    }

    getBranchListItemTemplate = row => (
        <ListItem
            key={row.id}
            deletable
            onClick={() => this.props.onEnvironmentClickHandler(row.name)}
            onDelete={() => this.props.onDeleteEnvironmentHandler(row.name)}
        >
            {row.name}
        </ListItem>
    );

    getBranchMobileListItemTemplate = row => (
        <MobileListItem
            onClick={() => this.props.onEnvironmentClickHandler(row.name)}
            onDelete={() => this.props.onDeleteEnvironmentHandler(row.name)}
            key={row.id}
        >
            <MobileDataDisplay title={row.id} withoutImage />
        </MobileListItem>
    );
}

export default withTranslation('environments')(EnvironmentsList);
