import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {CustomCircularProgress, ListActionPanel} from '../../common/components';

const ElementsPageLayout = ({
    innerComponents: [AddElementDialog],
    roleProps: {showAddButton},
    props,
}) => {
    const eventBased = useSelector(
        state => state.auth.organizationData.eventBased,
    );

    const {t} = useTranslation('elements');

    return (
        <div>
            {props.actionPending && <CustomCircularProgress />}
            <ListActionPanel
                searchPlaceholder={
                    eventBased
                        ? t('main.eventBased.searchPlaceholder')
                        : t('main.default.searchPlaceholder')
                }
                title={
                    eventBased
                        ? t('main.eventBased.title')
                        : t('main.default.title')
                }
                addButtonText={
                    eventBased
                        ? t('main.eventBased.actionBtn')
                        : t('main.default.actionBtn')
                }
                onSearchTextChange={props.onSearchTextChange}
                onToggleAddDialogHandler={props.onToggleAddDialogHandler}
                showAddButton={showAddButton}
            />
            {AddElementDialog({
                dialogTitle: eventBased
                    ? t('dialog.eventBased.title')
                    : t('dialog.default.title'),
                locations: props.locations,
                open: props.addDialogOpen,
                handleClose: props.onToggleAddDialogHandler,
                onElementSubmit: props.onElementSubmit,
                loading: props.actionPending,
            })}
            <div>{props.elementsList}</div>
        </div>
    );
};

export default ElementsPageLayout;
