import React from 'react';

import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import styles from './ElementDetailsBoxLayout.module.css';
import {
    Box,
    CustomCircularProgress,
    CustomTextField,
    ImageDropzone,
    FilesDropzone,
    LocationsPicker,
    CustomButton,
} from '../../common/components';
import CustomDatePicker from '../../common/components/CustomDatePicker/CustomDatePicker';

const ElementDetailsBoxLayout = ({
    innerComponents: [DeleteButton, SaveButton],
    roleProps: {inputsDisabled},
    props,
}) => {
    const {t} = useTranslation('elements');

    return (
        <div className={styles.root}>
            {props.loading && <CustomCircularProgress />}
            <Box key={props.key} className={styles.elementDetailsBox}>
                <section className={styles.buttons}>
                    {DeleteButton({
                        className: classNames(
                            styles.button,
                            styles.deleteButton,
                        ),
                        text: props.eventBased
                            ? t('details.eventBased.deleteBtn')
                            : t('details.default.deleteBtn'),
                        contained: false,
                        onClick: props.deleteElement,
                    })}
                    {SaveButton({
                        className: classNames(styles.button, styles.saveButton),
                        text: t('details.default.saveBtn'),
                        disabled: !props.isSaveButtonEnabled(),
                        onClick: props.updateElement,
                    })}
                </section>
                <div className={styles.container}>
                    <LocationsPicker
                        selectedLocation={props.location}
                        handleChange={props.onLocationChange}
                        locations={props.locations}
                        disabled={inputsDisabled}
                        controlled
                    />
                    <CustomTextField
                        className={styles.textField}
                        label={
                            props.eventBased
                                ? t('details.eventBased.name')
                                : t('details.default.name')
                        }
                        placeholder={
                            props.eventBased
                                ? t('details.eventBased.name')
                                : t('details.default.namePlaceholder')
                        }
                        defaultValue={props.name}
                        disabled={inputsDisabled}
                        onChange={props.onTextChangeHandler('name')}
                    />
                    <CustomDatePicker
                        label={t('details.default.creationDate')}
                        value={props.createdDate}
                        readOnly
                    />
                    <CustomDatePicker
                        label={t('details.default.warrantyDeadline')}
                        value={props.warrantyTo}
                        onChange={props.onElementWarrantyChange}
                        readOnly={inputsDisabled}
                    />
                    <CustomTextField
                        className={styles.textField}
                        label={
                            props.eventBased
                                ? t('details.eventBased.description')
                                : t('details.default.description')
                        }
                        placeholder={
                            props.eventBased
                                ? t('details.eventBased.descriptionPlaceholder')
                                : t('details.default.descriptionPlaceholder')
                        }
                        disabled={inputsDisabled}
                        defaultValue={props.description}
                        onChange={props.onTextChangeHandler('description')}
                    />
                    <ImageDropzone
                        label={
                            props.eventBased
                                ? t('details.eventBased.photo')
                                : t('details.default.photo')
                        }
                        imagesSrc={props.iconUri}
                        onDrop={props.onIconSelectHandler}
                        disabled={inputsDisabled}
                        onRemoveImage={props.onIconRemoveHandler}
                    />
                    <FilesDropzone
                        filesSrc={[
                            ...props.filesDropped.new.map(file => ({
                                path: URL.createObjectURL(file),
                                name: file.name,
                            })),
                            ...props.filesDropped.uploaded,
                        ]}
                        onDrop={props.onFileDrop}
                        onRemoveFile={props.onFileRemove}
                        label={t('details.default.additionalFiles')}
                    />
                    <CustomButton
                        text={t('details.default.qrBtn')}
                        onClick={props.onGenerateQRCode}
                    />
                </div>
            </Box>
        </div>
    );
};

export default ElementDetailsBoxLayout;
