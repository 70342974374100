import {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import isEqual from 'react-fast-compare';
import {withTranslation} from 'react-i18next';
import IssueDetailsLayout from './IssueDetailsLayout';
import {
    fetchElements,
    fetchLocations,
    getElementsByLocationId,
    updateIssue,
    dispatchAndDownloadPhotos,
    deleteIssue,
    setElements,
    setUsers,
} from '../../store/action';
import {mappings} from './IssueDetailsRoleMapper';
import {archivedIssue, issues, categories} from '../../constants/endpoints';
import {MAX_PHOTOS, MAX_PHOTO_SIZE} from '../../constants/files';
import {withUserRole} from '../../hoc/User/WithUserRole';
import {NotificationContext} from '../../context/notifications';
import {issueConverter} from '../../utils/converter/issueConverter';
import {ARCHIVED_ISSUES, ISSUES} from '../../constants/routes';
import {getUserRole} from '../../store/action/authHelpers';
import getUsersQuery from '../../utils/queryBuilder/UsersQueryBuilder';
import {fetchElementImages} from '../../utils/element/element';
import getLocationsQuery from '../../utils/queryBuilder/LocationQueryBuilder';
import {TYPES} from '../../constants/error';
import {roles} from '../../constants/roles';
import {fetchCategories} from '../../store/action/category';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';
import {Branch} from '../../models/branch';
import {Category} from '../../models/category';
import {Issue} from '../../models/issue/issue';
import {TFunction} from 'i18next';
import {LatLng} from 'leaflet';
import {mapBoundsStartingPosition} from '../../utils/map/type';

type State = {
    issue: Issue;
    editedIssue: Issue;
    selectedElement: Element;
    selectedLocation: Location;
    selectedCategory: Category;
    issueImages: {
        new: File[];
        uploaded: any[];
        removed: any[];
    };
    solutionImages: {
        new: File[];
        uploaded: any[];
        removed: any[];
    };
    users: unknown[];
    uploadingInProgress: boolean;
    loadingIssue: boolean;
};

class IssueDetails extends Component<any, State> {
    state = {
        issue: null,
        editedIssue: null,
        selectedElement: null,
        selectedLocation: null,
        selectedCategory: null,
        issueImages: {
            new: [],
            uploaded: [],
            removed: [],
        },
        solutionImages: {
            new: [],
            uploaded: [],
            removed: [],
        },
        users: [],
        uploadingInProgress: false,
        loadingIssue: false,
    };

    static contextType = NotificationContext;

    unsubscribeLocations;

    unsubscribeCategories;

    categoriesReference;

    unsubscribeIssue;

    issuesRef = null;

    notificationSystem = null;

    t: TFunction;

    constructor(props) {
        super(props);
        this.t = props.t;
        const collection = props.archived ? archivedIssue() : issues();
        this.issuesRef = firebase.firestore().collection(collection);
        this.categoriesReference = firebase
            .firestore()
            .collection(categories());
    }

    componentDidMount() {
        this.subscribeOnIssue(this.props.match.params.id);
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.selectedBranches !== prevProps.selectedBranches &&
            this.state.issue &&
            !this.props.selectedBranches.find(
                branch => branch.id === this.state.issue.branch.id,
            )
        ) {
            this.props.history.push(
                this.props.archived ? ARCHIVED_ISSUES : ISSUES,
            );
        }

        if (
            this.props.organizationData.isIssuesCategoriesFeatureEnabled !==
            prevProps.organizationData.isIssuesCategoriesFeatureEnabled
        ) {
            this.unsubscribeCategories && this.unsubscribeCategories();
            this.subscribeOnCategories(this.state.issue);
        }
    }

    subscribeOnCategories(issue) {
        this.unsubscribeCategories = this.categoriesReference.onSnapshot(
            querySnapshot => {
                this.props.fetchCategories(querySnapshot);

                if (issue.category) {
                    const categories = querySnapshot.docs.map(doc => ({
                        ...doc.data(),
                        id: doc.id,
                    }));

                    const issueCategory = categories.find(
                        category => category.id === issue.category.id,
                    );

                    this.fetchUsers(
                        issue.element.location.branch,
                        issueCategory,
                    );
                } else {
                    this.fetchUsers(issue.element.location.branch);
                }
            },
            errorHandler,
        );
    }

    subscribeOnIssue(issueId) {
        this.setState({loadingIssue: true});
        this.unsubscribeIssue = this.issuesRef
            .doc(issueId)
            .withConverter(issueConverter)
            .onSnapshot(doc => {
                if (!doc.data()) {
                    this.setState({loadingIssue: false});
                    return;
                }
                const issue = {key: doc.id, ...doc.data()};
                this.unsubscribeLocations && this.unsubscribeLocations();
                this.unsubscribeLocations = getLocationsQuery([
                    issue.branch.id,
                ]).onSnapshot(this.props.fetchLocations, errorHandler);

                this.unsubscribeCategories && this.unsubscribeCategories();

                if (
                    this.props.organizationData.isIssuesCategoriesFeatureEnabled
                ) {
                    this.subscribeOnCategories(issue);
                } else {
                    this.fetchUsers(issue.element.location.branch);
                }

                this.props.getElementsByLocationId(issue.element.location.id);

                this.fetchImagesAndUpdateElement(issue.element);
                this.props.downloadPhotos(
                    issue.issueImagePaths,
                    this.onPhotosDownloadedHandler('issueImages'),
                );
                this.props.downloadPhotos(
                    issue.solutionImagePaths,
                    this.onPhotosDownloadedHandler('solutionImages'),
                );
                const editedIssue = this.applyRemoteChanges(issue);

                this.setState({
                    issue,
                    editedIssue,
                    selectedLocation: issue.element.location,
                    selectedElement: issue.element,
                    selectedCategory: issue.category,
                    loadingIssue: false,
                });
            });
    }

    fetchUsers(branch: Branch, category?: Category) {
        const {rolesToFetchUsersWith} = mappings[getUserRole()].roleProps;

        if (rolesToFetchUsersWith && !category) {
            getUsersQuery()
                .withBranch(branch)
                .withRoles(rolesToFetchUsersWith)
                .get()
                .then(users => this.setState({users}))
                .catch(error => {
                    this.showNotification(
                        this.t('notifications.details.usersLoadError'),
                        TYPES.error,
                    );
                });
        } else if (rolesToFetchUsersWith && category) {
            getUsersQuery()
                .withBranch(branch)
                .withRoles(rolesToFetchUsersWith)
                .withCategory(category.id)
                .get()
                .then(users => this.setState({users}))
                .catch(error => {
                    this.showNotification(
                        this.t('notifications.details.usersLoadError'),
                        TYPES.error,
                    );
                });
        }
    }

    fetchImagesAndUpdateElement = element => {
        fetchElementImages(element)
            .then(([iconUri, patternUri]) => {
                this.setState({
                    selectedElement: {
                        ...element,
                        iconUri,
                        patternUri,
                    },
                });
            })
            .catch(error => errorHandler(error, 'Bad element image paths'));
    };

    applyRemoteChanges(newIssue) {
        if (!this.state.issue) {
            return {...newIssue};
        }
        const oldIssue = {...this.state.issue};
        const editedIssue = {...this.state.editedIssue};
        Object.keys(newIssue).forEach(key => {
            if (!isEqual(newIssue[key], oldIssue[key])) {
                oldIssue[key] = newIssue[key];
            }
        });
        return {...editedIssue, ...oldIssue};
    }

    componentWillUnmount() {
        this.unsubscribeLocations && this.unsubscribeLocations();
        this.unsubscribeIssue();
        this.unsubscribeCategories && this.unsubscribeCategories();
        this.props.clearElements();
    }

    render() {
        const {
            locations,
            elements,
            archived,
            userData,
            organizationData,
            categories,
        } = this.props;

        const {
            editedIssue,
            selectedElement,
            selectedLocation,
            selectedCategory,
            issueImages,
            solutionImages,
            uploadingInProgress,
            users,
            loadingIssue,
        } = this.state;

        const issueImagesSrc = [
            ...issueImages.new.map(image => URL.createObjectURL(image)),
            ...issueImages.uploaded.map(image => image.uri),
        ];
        const solutionImagesSrc = [
            ...solutionImages.new.map(image => URL.createObjectURL(image)),
            ...solutionImages.uploaded.map(image => image.uri),
        ];
        const isCurrentUserIssueReporter =
            editedIssue?.reporter.uid === userData.uid;
        const currentUserRole = getUserRole();

        return (
            (currentUserRole !== roles.REPORTER ||
                !archived ||
                organizationData.showRecentlyArchivedIssuesOnIssuesList) &&
            withUserRole(IssueDetailsLayout, mappings, {
                issue: editedIssue,
                isCurrentUserIssueReporter,
                userData,
                archived,
                locations,
                elements,
                categories,
                users,
                selectedElement,
                selectedLocation,
                selectedCategory,
                solutionImagesSrc,
                issueImagesSrc,
                uploadingInProgress,
                organizationData,
                loadingIssue,
                onPhoneNumberChange: this.onPhoneNumberChange,
                onSelectedCategoryChange: this.onSelectedCategoryChange,
                onPriorityChange: this.onPriorityChange,
                onElementChange: this.onElementChange,
                onLocationChange: this.onLocationChange,
                onAssignedEmployeeChange: this.onAssignedEmployeeChange,
                onAcceptedByChange: this.onAcceptedByChange,
                onStatusChange: this.onStatusChange,
                onIssueCreationDateChange: this.onIssueCreationDateChange,
                onIssueClosedDateChange: this.onIssueClosedDateChange,
                onIssueFieldChangeHandlerBuilder:
                    this.onIssueFieldChangeHandlerBuilder,
                onTextChangeHandlerBuilder: this.onTextChangeHandlerBuilder,
                onAddNewImageHandlerBuilder: this.onAddNewImageHandlerBuilder,
                onRemovePhotoHandlerBuilder: this.onRemovePhotoHandlerBuilder,
                updateIssue: this.updateIssue,
                deleteIssue: this.deleteIssue,
                onLocationMarkerDelete: this.onLocationMarkerDelete,
                onLocationMarkerSave: this.onLocationMarkerSave,
                isSubmitButtonDisabled: this.isSubmitButtonDisabled(),
                isIssuesCategoriesFeatureEnabled:
                    this.props.organizationData
                        .isIssuesCategoriesFeatureEnabled,
                maintainerHasNoPermissionToAssignUsersToIssue:
                    this.props.organizationData
                        .maintainerHasNoPermissionToAssignUsersToIssue,
                reporterHasNoPermissionToEditIssueDescription:
                    this.props.organizationData
                        .reporterHasNoPermissionToEditIssueDescription,
                maintainerHasNoPermissionToEditIssueDescription:
                    this.props.organizationData
                        .maintainerHasNoPermissionToEditIssueDescription,
                reporterCanSeeIssueSolution:
                    this.props.organizationData.reporterCanSeeIssueSolution,
                isIssueLocalizationEnabled:
                    this.props.organizationData.isIssueLocalizationEnabled,
            })
        );
    }

    isSubmitButtonDisabled() {
        const {issue} = this.state;
        const {editedIssue} = this.state;
        const {
            issueImages,
            solutionImages,
            uploadingInProgress,
            selectedElement,
        } = this.state;

        const photoChangesCount =
            issueImages.removed.length +
            issueImages.new.length +
            solutionImages.removed.length +
            solutionImages.new.length;

        return (
            uploadingInProgress ||
            !selectedElement ||
            (editedIssue?.phoneNumber?.length < 9 &&
                editedIssue?.phoneNumber?.length > 0) ||
            (editedIssue.issueDescription === issue.issueDescription &&
                editedIssue.solutionDescription === issue.solutionDescription &&
                editedIssue.createdDate === issue.createdDate &&
                editedIssue.closedDate === issue.closedDate &&
                editedIssue.element.id === issue.element.id &&
                editedIssue.phoneNumber === issue.phoneNumber &&
                isEqual(editedIssue.locationMarker, issue.locationMarker) &&
                photoChangesCount === 0 &&
                editedIssue.status === issue.status &&
                this.userDidNotChange(
                    editedIssue.assignedTo,
                    issue.assignedTo,
                ) &&
                this.userDidNotChange(
                    editedIssue.acceptedBy,
                    issue.acceptedBy,
                ) &&
                editedIssue.priority === issue.priority &&
                editedIssue.category?.id === issue.category?.id)
        );
    }

    userDidNotChange(user1, user2) {
        return user1 === user2 || (user1 && user2 && user1.uid === user2.uid);
    }

    onPhotosDownloadedHandler = photosField => photosUrls => {
        const photos = {...this.state[photosField]};
        photos.uploaded = photosUrls;

        this.setState(prevState => ({
            ...prevState,
            [photosField]: photos,
        }));
    };

    onAddNewImageHandlerBuilder = photosField => files => {
        const photos = {...this.state[photosField]};
        const numberOfunacceptedFiles = files.filter(
            file => file.size > MAX_PHOTO_SIZE,
        ).length;
        const actualImageCount = photos.new.length + photos.uploaded.length;
        const newImageCount = actualImageCount + files.length;
        if (newImageCount > MAX_PHOTOS) {
            this.showNotification(
                this.t('notifications.details.tooManyPhotosError'),
                TYPES.error,
            );
        } else if (numberOfunacceptedFiles > 0) {
            this.showNotification(
                this.t('notifications.details.tooLargePhotoError'),
                TYPES.error,
            );
        } else {
            photos.new = [...photos.new, ...files];
            this.setState(prevState => ({
                ...prevState,
                [photosField]: photos,
            }));
        }
    };

    onRemovePhotoHandlerBuilder = photosField => removedPhotoIndex => {
        const photos = this.state[photosField];
        const newPhotos = [...photos.new];
        const removedPhotos = [...photos.removed];
        const uploadedPhotos = [...photos.uploaded];
        const uploadedPhotoIndex = removedPhotoIndex - newPhotos.length;
        if (uploadedPhotoIndex >= 0) {
            removedPhotos.push(uploadedPhotos.splice(uploadedPhotoIndex, 1)[0]);
        } else {
            newPhotos.splice(uploadedPhotoIndex, 1);
        }
        const newPhotosField = {
            new: newPhotos,
            removed: removedPhotos,
            uploaded: uploadedPhotos,
        };
        this.setState(prevState => ({
            ...prevState,
            [photosField]: newPhotosField,
        }));
    };

    onLocationChange = event => {
        const location = {
            ...this.props.locations.find(
                location => event.value === location.id,
            ),
        };

        if (location !== this.state.selectedLocation) {
            this.setState({
                selectedLocation: location,
                selectedElement: null,
            });

            this.fetchUsers(location.branch);
            this.props.getElementsByLocationId(location.id);
        }
    };

    onSelectedCategoryChange = event => {
        const selectedCategory = this.props.categories.find(
            category => event.value === category.id,
        );

        if (selectedCategory !== this.state.selectedCategory) {
            const editedIssue = {...this.state.editedIssue};
            editedIssue.assignedTo = null;

            this.setState({
                selectedCategory,
                editedIssue,
            });

            this.fetchUsers(
                this.state.selectedLocation.branch,
                selectedCategory,
            );
        }

        this.onIssueFieldChangeHandlerBuilder('category')({
            id: selectedCategory.id,
            name: selectedCategory.name,
        });
    };

    onIssueCreationDateChange = date =>
        this.onIssueFieldChangeHandlerBuilder('createdDate')(date);

    onIssueClosedDateChange = date =>
        this.onIssueFieldChangeHandlerBuilder('closedDate')(date);

    onStatusChange = event => {
        this.onIssueFieldChangeHandlerBuilder('status')(event.value);
    };

    onTextChangeHandlerBuilder = field => event => {
        this.onIssueFieldChangeHandlerBuilder(field)(event.target.value);
    };

    onPriorityChange = event => {
        const editedIssue = {...this.state.editedIssue};
        editedIssue.priority = event.target.value;
        this.setState({editedIssue});
    };

    onPhoneNumberChange = event => {
        const editedIssue = {...this.state.editedIssue};
        editedIssue.phoneNumber = event.target.value;
        this.setState({editedIssue});
    };

    onIssueFieldChangeHandlerBuilder = field => change => {
        this.setState(prevState => {
            const editedIssue = {...prevState.editedIssue};
            editedIssue[field] = change;
            return {editedIssue};
        });
    };

    onAssignedEmployeeChange = event => {
        const user = this.state.users.find(user => event.value === user.uid);
        const editedIssue = {...this.state.editedIssue};
        editedIssue.assignedTo = user;
        this.setState({editedIssue});
    };

    onAcceptedByChange = event => {
        const user = this.state.users.find(user => event.value === user.key);
        const editedIssue = {...this.state.editedIssue};
        editedIssue.acceptedBy = user;
        this.setState({editedIssue});
    };

    onElementChange = event => {
        const element = {
            ...this.props.elements.find(element => event.value === element.id),
        };
        this.fetchImagesAndUpdateElement(element);
        this.setState({selectedElement: element});
        this.onIssueFieldChangeHandlerBuilder('element')(element);
    };

    onLocationMarkerSave = (latLng: LatLng) => {
        const editedIssue = {...this.state.editedIssue};

        editedIssue.locationMarker = {
            bounds: {
                start: mapBoundsStartingPosition,
                end: [
                    this.state.selectedLocation.locationMap.height,
                    this.state.selectedLocation.locationMap.width,
                ],
            },
            position: latLng,
        };
        this.setState({editedIssue});
    };

    onLocationMarkerDelete = () => {
        const editedIssue = {...this.state.editedIssue};
        editedIssue.locationMarker = null;
        this.setState({editedIssue});
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    updateIssue = () => {
        const {issue, editedIssue, issueImages, solutionImages} = this.state;
        this.setState({uploadingInProgress: true});
        this.props.updateIssue(
            editedIssue,
            issue,
            issueImages,
            solutionImages,
            () => {
                this.showNotification(
                    this.t('notifications.details.elementUpdateSuccess'),
                    'success',
                );
                this.props.history.push(
                    this.props.archived ? ARCHIVED_ISSUES : ISSUES,
                );
            },
            () => {
                this.setState({uploadingInProgress: false});
                this.showNotification(
                    this.t('notifications.details.elementEditError'),
                    TYPES.error,
                );
            },
        );
    };

    deleteIssue = () => {
        const {issue} = this.state;
        if (
            window.confirm(this.t('notifications.details.elementConfirmation'))
        ) {
            this.setState({uploadingInProgress: true});
            this.props.deleteIssue(
                issue,
                () => {
                    this.setState({uploadingInProgress: false});
                    this.showNotification(
                        this.t('notifications.details.elementDeleteSuccess'),
                        'success',
                    );
                    this.props.history.push(
                        this.props.archived ? ARCHIVED_ISSUES : ISSUES,
                    );
                },
                () => {
                    this.setState({uploadingInProgress: false});
                    this.showNotification(
                        this.t('notifications.details.elementDeleteError'),
                        TYPES.error,
                    );
                },
            );
        }
    };
}

const mapStateToProps = state => ({
    locations: state.location.locations,
    elements: state.element.elements,
    categories: state.category.categories,
    issuePhotoUris: state.issue.issuePhotoUris,
    solutionPhotoUris: state.issue.solutionPhotoUris,
    selectedBranches: state.branch.selectedBranches,
    userData: state.auth.userData,
    organizationData: state.auth.organizationData,
});

const mapDispatchToProps = dispatch => ({
    fetchLocations: querySnapshot => dispatch(fetchLocations(querySnapshot)),
    fetchElements: querySnapshot => dispatch(fetchElements(querySnapshot)),
    fetchCategories: dispatch(fetchCategories),
    updateIssue: dispatch(updateIssue),
    getElementsByLocationId: locationId =>
        dispatch(getElementsByLocationId(locationId)),
    setUsers: users => dispatch(setUsers(users)),
    deleteIssue: dispatch(deleteIssue),
    downloadPhotos: dispatchAndDownloadPhotos(dispatch),
    clearElements: () => dispatch(setElements([])),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation('issues')(IssueDetails));
