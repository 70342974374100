export const numericSegmentSeparatorRegex = /[:.,/;\\]/g;

export const numericPrefixRegex = /^[\d:.,/;\\]+/;

export const specialCharactersAtEndOfStringRegex = /[:.,/;\\]+$/;

export const splitNumericPrefixFromElement = string => {
    const prefix = string.match(numericPrefixRegex);

    if (!prefix || !prefix.length) {
        return '';
    }

    return prefix[0].replace(specialCharactersAtEndOfStringRegex, '');
};
