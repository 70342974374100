import React from 'react';
import {useTranslation} from 'react-i18next';
import {List, ListColumnCreator} from '../../../common/components/index';
import MobileList from '../../../common/components/list/MobileList';
import {
    createUserListTemplate,
    createMobileUserListItemTemplate,
} from './ListItemCreator/createListItemTemplate';
import {roleComparator} from '../../../utils/sorting/sorting';

const UsersList = ({disabled, users, onUserClick}) => {
    const {t} = useTranslation('users');

    const columns = [
        new ListColumnCreator('name', t('table.columns.name'), {
            accessAttribute: user => `${user.name} ${user.surname}`,
        }),
        new ListColumnCreator('email', t('table.columns.email'), {}),
        new ListColumnCreator('roles', t('table.columns.roles'), {
            customComparator: roleComparator,
        }),
    ];

    return (
        <>
            <List
                columns={columns}
                rows={users}
                rowTemplate={createUserListTemplate(disabled, onUserClick)}
                name="users"
            />
            <MobileList
                columns={columns}
                rows={users}
                rowTemplate={createMobileUserListItemTemplate(
                    disabled,
                    onUserClick,
                )}
            />
        </>
    );
};

export default UsersList;
