import React, {Component} from 'react';
import {connect} from 'react-redux';
import IssuesList from '../../components/Issues/IssuesList/IssuesList';
import {fetchIssues} from '../../store/action';
import PageControl from '../../components/Issues/IssuesPageControl/IssuesPageControl';
import AddIssueDialog from '../../components/Issues/AddIssueDialog/AddIssueDialog';
import {
    compareIssueWithQueryString,
    getSearchTextFromQueryParams,
} from '../../utils/filtering/filtering';
import {CustomCircularProgress} from '../../common/components/index';
import IssuesService from '../../services/IssuesService';
import {issuesListFiltersStorage} from '../../storage/localStorage';

class HomePage extends Component {
    state = {
        addDialogOpen: false,
        openFilter: false,
        indicateActivity: true,
        filter: {
            open: true,
            inProgress: true,
            inReview: true,
            done: false,
            low: true,
            medium: true,
            high: true,
        },
    };

    componentDidMount() {
        const savedFilters =
            issuesListFiltersStorage.getIssueFiltersFromStorage() || {};

        this.setState({
            filter: {
                ...this.state.filter,
                ...savedFilters,
                done: this.props.organizationData
                    .showRecentlyArchivedIssuesOnIssuesList,
            },
        });
        this.getIssues();
        if (this.props.qrCodeData.id) {
            this.setState({
                addDialogOpen: true,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.organizationData
                .showRecentlyArchivedIssuesOnIssuesList !==
            prevProps.organizationData.showRecentlyArchivedIssuesOnIssuesList
        ) {
            this.setState({
                filter: {
                    ...this.state.filter,
                    done: this.props.organizationData
                        .showRecentlyArchivedIssuesOnIssuesList,
                },
            });
        }
        if (
            this.props.selectedBranches.length !==
                prevProps.selectedBranches.length ||
            this.props.organizationData.reporterCanSeeAllIssues !==
                prevProps.organizationData.reporterCanSeeAllIssues ||
            this.props.organizationData
                .showRecentlyArchivedIssuesOnIssuesList !==
                prevProps.organizationData
                    .showRecentlyArchivedIssuesOnIssuesList
        ) {
            this.getIssues();
        }
    }

    componentWillUnmount() {
        this.unsubscribeIssues && this.unsubscribeIssues();
    }

    getIssues = async () => {
        this.unsubscribeIssues && this.unsubscribeIssues();
        this.unsubscribeIssues = await IssuesService.subscribeOnIssues(
            this.props.userData,
            this.props.organizationData,
            this.props.selectedBranches.map(branch => branch.id),
            this.setActivityIndicator,
            this.props.fetchIssues,
        );
    };

    setActivityIndicator = showIndicator => {
        this.setState({indicateActivity: showIndicator});
    };

    render() {
        const {issues, organizationData, userData} = this.props;
        const showAddIssueButton = !(
            organizationData.maintainerHasNoPermissionToAddIssue &&
            userData.roles.maintainer
        );
        const issueList = this.state.indicateActivity ? (
            <CustomCircularProgress />
        ) : (
            <IssuesList
                issues={this.filterIssues(issues)}
                onIssueClick={this.onIssueClickHandler}
            />
        );
        const searchText = getSearchTextFromQueryParams(
            this.props.location.search,
        );

        return (
            <div>
                <PageControl
                    onSearchTextChange={this.onSearchTextChange}
                    onToggleAddDialogHandler={this.onToggleAddDialogHandler}
                    onFilterIconClickHandler={this.onFilterIconClickHandler}
                    openFilter={this.state.openFilter}
                    filterState={this.state.filter}
                    onCheckboxClick={this.onCheckboxClick}
                    showAddIssueButton={showAddIssueButton}
                    searchText={searchText}
                />
                {this.state.addDialogOpen && (
                    <AddIssueDialog
                        handleClose={this.onToggleAddDialogHandler}
                        loading={this.state.indicateActivity}
                        refreshIssueList={this.getIssues}
                    />
                )}
                {issueList}
            </div>
        );
    }

    filterIssues = issues => {
        const searchString = getSearchTextFromQueryParams(
            this.props.location.search,
        );
        const filteredByStatus = issues.filter(
            issue => this.state.filter[issue.status],
        );

        const filteredByPriority = filteredByStatus.filter(
            issue => this.state.filter[issue.priority],
        );

        if (searchString) {
            return filteredByPriority.filter(issue =>
                compareIssueWithQueryString(issue, searchString),
            );
        }
        return filteredByPriority;
    };

    onSearchTextChange = event => {
        const params = new URLSearchParams({search: event.target.value});
        this.props.history.replace({
            pathname: this.props.location.pathname,
            search: params.toString(),
        });
    };

    onIssueClickHandler = issueId => () => {
        const clickedIssue = this.props.issues.find(
            issue => issue.id === issueId,
        );
        if (clickedIssue.status === 'done') {
            this.props.history.push(`/issue/archived/${issueId}`, {
                search: this.props.location.search,
            });
        } else {
            this.props.history.push(`/issue/${issueId}`, {
                search: this.props.location.search,
            });
        }
    };

    onToggleAddDialogHandler = () => {
        this.setState({addDialogOpen: !this.state.addDialogOpen});
    };

    onFilterIconClickHandler = () => {
        this.setState({openFilter: !this.state.openFilter});
    };

    onCheckboxClick = field => event => {
        const filterStateCopy = {...this.state.filter};

        filterStateCopy[field] = !filterStateCopy[field];

        issuesListFiltersStorage.saveIssueFilters(filterStateCopy);
        this.setState({filter: filterStateCopy});
    };
}

const mapStateToProps = state => ({
    issues: state.issue.issues,
    selectedBranches: state.branch.selectedBranches,
    userData: state.auth.userData,
    organizationData: state.auth.organizationData,
    qrCodeData: state.qrCode,
});

const mapDispatchToProps = dispatch => ({
    fetchIssues: (snapshot, callback) =>
        dispatch(fetchIssues(snapshot, false, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
