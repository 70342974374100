import React from 'react';
import {useTranslation} from 'react-i18next';
import CustomDatePicker from './CustomDatePicker';
import styles from './CustomDatePicker.module.css';

const TimespanPicker = ({onDateChange, startDate, endDate}) => {
    const {t} = useTranslation('common');

    return (
        <div className={styles.container}>
            <span className={styles.datePickers}>
                <CustomDatePicker
                    label={t('timespanPicker.from')}
                    value={startDate}
                    onChange={date => onDateChange('startDate', date)}
                />
            </span>
            <span className={styles.datePickers}>
                <CustomDatePicker
                    label={t('timespanPicker.to')}
                    value={endDate}
                    onChange={date => onDateChange('endDate', date)}
                />
            </span>
        </div>
    );
};

export default TimespanPicker;
