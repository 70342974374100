import firebase from 'firebase/app';
import {getUserDataFromStorage} from '../storage/localStorage';

export const issues = () => getEndpoint('issue');
export const task = () => getEndpoint('task');
export const issuesImages = () => getStorageEndpoint('issue/');
export const solutionImages = () => getEndpoint('solutionImages/');
export const archivedIssue = () => getEndpoint('archivedIssue');
export const locations = () => getEndpoint('location');
export const locationsImages = () => getStorageEndpoint('location/');
export const branches = () => getEndpoint('branch');
export const categories = () => getEndpoint('category');
export const elements = () => getEndpoint('element');
export const elementImages = () => getStorageEndpoint('element');
export const users = () => firebase.firestore().collection('user');
export const environment = () =>
    firebase.firestore().collection('organization');

export const getEndpoint = (collection: string) => {
    const userData = getUserDataFromStorage();
    const organization = userData ? userData.organization : 'null';

    return `organization/${organization}/${collection}`;
};

export const getStorageEndpoint = (collection: string) => {
    const userData = getUserDataFromStorage();
    const organization = userData ? userData.organization : 'null';

    return `${organization}/${collection}`;
};
