import {
    Marker as LeafletMarker,
    MarkerProps as LeafletMarkerProps,
} from 'react-leaflet';
import {icon} from 'leaflet';
import {leafletMarkerIcon} from '../../../../static/images/icons';

type Props = LeafletMarkerProps;

export const Marker = (props: Props) => (
    <LeafletMarker
        icon={icon({iconUrl: leafletMarkerIcon, iconSize: [28, 40]})}
        {...props}
    />
);
