import React from 'react';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import './App.css';
import firebase from 'firebase/app';
import {shallowEqual, useSelector} from 'react-redux';
import {
    HOME,
    ELEMENTS,
    ISSUES,
    LOGIN,
    ARCHIVED_ISSUES,
    REPORTS,
    LOCATIONS,
    USERS,
    USER,
    TASKS,
    TASK,
    BRANCHES,
    CRUD,
    CATEGORIES,
    ENVIRONMENT,
} from './constants/routes';
import ArchivedIssuePage from './containers/ArchivedIssuePage/ArchivedIssuePage';
import ElementsPage from './containers/ElementsPage/ElementsPage';
import HomePage from './containers/HomePage/HomePage';
import IssueDetails from './containers/IssueDetails/IssueDetails';
import ElementDetails from './containers/ElementDetails/ElementDetails';
import LoginPage from './containers/LoginPage/LoginPage';
import withAuthentication from './hoc/Authentication/WithAuthentication';
import withLayout from './hoc/Layout/WithLayout';
import {allRoles, roles} from './constants/roles';
import ReportsPage from './containers/Reports/ReportsPage';
import LocationsPage from './containers/LocationsPage/LocationsPage';
import TasksPage from './containers/TasksPage/TasksPage';
import UsersPage from './containers/Users/UsersPage';
import UserDetails from './containers/UserDetails/UserDetails';
import {NotificationProvider} from './context/notifications';
import TaskDetails from './containers/TaskDetails/TaskDetails';
import BranchesPage from './containers/BranchesPage/BranchesPage';
import EnvironmentPage from './containers/Environment/EnvironmentPage';
import AdminEnvironmentPage from './containers/AdminEnvironment/AdminEnvironmentPage';
import packageJson from '../package.json';
import CategoriesPage from './containers/CategoriesPage/CategoriesPage';

const {version} = packageJson;

console.info(`Version: ${version}`);
firebase.auth().useDeviceLanguage();

const App = () => {
    const {supervisorHasAccessToTasks} = useSelector(
        state => state.auth.organizationData,
        shallowEqual,
    );

    return (
        <BrowserRouter>
            <NotificationProvider>
                <Switch>
                    <Redirect exact from={HOME} to={ISSUES} />
                    <Route
                        path={ISSUES}
                        exact
                        component={withLayout(
                            withAuthentication(HomePage, true, allRoles),
                            true,
                        )}
                    />
                </Switch>
                <Route path={LOGIN} exact component={LoginPage} />
                <Route
                    path="/issue/:id"
                    exact
                    component={withLayout(
                        withAuthentication(IssueDetails, true, allRoles, {
                            archived: false,
                        }),
                    )}
                />
                <Route
                    path="/issue/archived/:id"
                    exact
                    component={withLayout(
                        withAuthentication(
                            IssueDetails,
                            true,
                            [
                                roles.REPORTER,
                                roles.MAINTAINER,
                                roles.SUPERVISOR,
                                roles.ADMIN,
                                roles.SYSTEMADMIN,
                            ],
                            {
                                archived: true,
                            },
                        ),
                    )}
                />
                <Route
                    path="/element/:id"
                    component={withLayout(
                        withAuthentication(ElementDetails, true, [
                            roles.MAINTAINER,
                            roles.SUPERVISOR,
                            roles.ADMIN,
                            roles.SYSTEMADMIN,
                        ]),
                    )}
                />
                <Route
                    path={ARCHIVED_ISSUES}
                    exact
                    component={withLayout(
                        withAuthentication(ArchivedIssuePage, true, [
                            roles.MAINTAINER,
                            roles.SUPERVISOR,
                            roles.ADMIN,
                            roles.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={ELEMENTS}
                    exact
                    component={withLayout(
                        withAuthentication(ElementsPage, true, [
                            roles.MAINTAINER,
                            roles.SUPERVISOR,
                            roles.ADMIN,
                            roles.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={REPORTS}
                    exact
                    component={withLayout(
                        withAuthentication(ReportsPage, true, [
                            roles.ADMIN,
                            roles.SUPERVISOR,
                            roles.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={LOCATIONS}
                    exact
                    component={withLayout(
                        withAuthentication(LocationsPage, true, [
                            roles.MAINTAINER,
                            roles.SUPERVISOR,
                            roles.ADMIN,
                            roles.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={USERS}
                    exact
                    component={withLayout(
                        withAuthentication(UsersPage, true, [
                            roles.SUPERVISOR,
                            roles.ADMIN,
                            roles.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={USER(':id')}
                    exact
                    component={withLayout(
                        withAuthentication(UserDetails, true, [
                            roles.ADMIN,
                            roles.SYSTEMADMIN,
                        ]),
                    )}
                />
                <Route
                    path={TASKS}
                    exact
                    component={withLayout(
                        withAuthentication(TasksPage, true, [
                            roles.ADMIN,
                            roles.SYSTEMADMIN,
                            supervisorHasAccessToTasks
                                ? roles.SUPERVISOR
                                : null,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={TASK(':id')}
                    exact
                    component={withLayout(
                        withAuthentication(TaskDetails, true, [
                            roles.ADMIN,
                            roles.SYSTEMADMIN,
                            supervisorHasAccessToTasks
                                ? roles.SUPERVISOR
                                : null,
                        ]),
                    )}
                />
                <Route
                    path={BRANCHES}
                    exact
                    component={withLayout(
                        withAuthentication(BranchesPage, true, [
                            roles.ADMIN,
                            roles.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={CATEGORIES}
                    exact
                    component={withLayout(
                        withAuthentication(CategoriesPage, true, [
                            roles.ADMIN,
                            roles.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={CRUD}
                    exact
                    component={withLayout(
                        withAuthentication(EnvironmentPage, true, [
                            roles.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={ENVIRONMENT}
                    exact
                    component={withLayout(
                        withAuthentication(AdminEnvironmentPage, true, [
                            roles.ADMIN,
                        ]),
                        true,
                    )}
                />
            </NotificationProvider>
        </BrowserRouter>
    );
};

export default App;
