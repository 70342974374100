import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import React from 'react';
import './NavigationItem.css';
import {NavLink} from 'react-router-dom';

const navigationItem = props => (
    <div className="navigationItem">
        <MenuItem className="button">
            <NavLink
                to={props.path}
                exact
                className="navLink"
                activeClassName="active"
            >
                {props.icon}
                {props.title}
            </NavLink>
        </MenuItem>
    </div>
);

export default navigationItem;
