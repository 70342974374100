import {type ReportFormat, type ReportType} from '../reportGenerator';
import {fetchReportIssuesData} from './fetchIssuesData';
import {type IssueGeneratorPayload} from './issueGeneratorOptions';
import {generateIssuesReport} from './pdf/PDFGenerator';
import {generateIssueCsvReport} from './csv/generateIssueCsvReport';

export type IssueGeneratorOptions = {
    type: ReportType;
    format: ReportFormat;
    payload: IssueGeneratorPayload;
    hasCategoriesFeature: boolean;
};

export const generateIssueRaport = async (options: IssueGeneratorOptions) => {
    const {
        payload: {
            endDate,
            startDate,
            withActualIssues,
            elementId,
            locationId,
            userId,
            branch,
        },
        type,
        hasCategoriesFeature,
    } = options;
    const issues = await fetchReportIssuesData({
        endDate,
        startDate,
        withActualIssues,
        elementId,
        locationId,
        userId,
        branchId: branch?.id,
    });

    switch (options.format) {
        case 'pdf':
            return generateIssuesReport(
                startDate,
                endDate,
                type,
                issues,
                hasCategoriesFeature,
                branch,
            );
        case 'csv':
            return generateIssueCsvReport({
                ...options,
                data: issues,
                hasCategoriesFeature,
            });
    }
};
