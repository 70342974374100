import {ImageOverlay, useMapEvents} from 'react-leaflet';
import {BoundsLiteral, LeafletMouseEvent, LatLngExpression} from 'leaflet';
import {Marker} from './Marker';

type Props = {
    onClick: (event: LeafletMouseEvent) => void;
    mapBunds: BoundsLiteral;
    markerPosition: LatLngExpression | null;
    mapImageUrl: string;
};

export const LocalizationMap = ({
    onClick,
    mapBunds,
    markerPosition,
    mapImageUrl,
}: Props) => {
    useMapEvents({
        click: onClick,
    });

    return (
        <>
            <ImageOverlay bounds={mapBunds} url={mapImageUrl} />
            {markerPosition && <Marker position={markerPosition} />}
        </>
    );
};
