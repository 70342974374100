export const allUsersOption = {
    uid: 'all',
    name: 'multiUserPicker.allUsersName',
    surname: 'multiUserPicker.allUsersSurname',
};

export const translateAllUsersOption = translate => {
    allUsersOption.name = translate(allUsersOption.name);
    allUsersOption.surname = translate(allUsersOption.surname);
};

export const mapUserToPicker = user => ({
    value: user.uid,
    label: `${user.name} ${user.surname}`,
});

export const generateOptions = users => {
    if (!users.length) {
        return [];
    }

    const mappedOptions = [allUsersOption, ...users].map(mapUserToPicker);

    return mappedOptions;
};

export const isAllUsersOptionPresent = users =>
    users.some(user => user.value === allUsersOption.uid);

export const areAllUsersSelected = (users, selectedUsers) =>
    users.length === selectedUsers.length;

export const findSelectedUserInUsers = (users, selectedUser) =>
    users.find(user => user.uid === selectedUser.value);
