import React from 'react';
import styles from './NotificationIndicator.module.css';
import {TYPES} from '../../../../constants/error';
import remove from '../../../../static/images/icons/remove.svg';
import checkCircle from '../../../../static/images/icons/check-circle.svg';

const notificationIndicator = ({type}) =>
    type === TYPES.error ? (
        <img className={styles.indicator} alt="" src={remove} />
    ) : (
        <img className={styles.indicator} alt="" src={checkCircle} />
    );

export default notificationIndicator;
