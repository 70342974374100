import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './TaskDetails.module.css';
import {
    BackNavigation,
    Box,
    CustomButton,
    CustomCircularProgress,
} from '../../common/components';
import {TASKS} from '../../constants/routes';
import TaskForm from '../forms/task/TaskForm';
import sharedStyles from '../shared/mobileStyle.module.css';

const TaskDetailsLayout = ({props}) => {
    const {t} = useTranslation('tasks');

    return (
        <div className={sharedStyles.mobileBackground}>
            {props.loading && <CustomCircularProgress />}
            <BackNavigation
                text={t('details.backNav')}
                header={props.header}
                backUrl={TASKS}
            />
            <div className={styles.root}>
                <Box className={styles.taskDetailsBox}>
                    <section className={styles.buttons}>
                        <CustomButton
                            className={`${styles.btn} ${styles.deleteBtn}`}
                            text={t('details.deleteBtn')}
                            contained={false}
                            onClick={props.onTaskDeleted}
                            disabled={props.loading}
                        />
                        <CustomButton
                            className={`${styles.btn} ${styles.saveBtn}`}
                            text={t('details.saveBtn')}
                            disabled={props.loading || props.saveButtonDisabled}
                            onClick={props.onTaskUpdated}
                        />
                    </section>
                    <div className={styles.taskDetails}>
                        <TaskForm
                            originalTask={props.originalTask}
                            elements={props.elements}
                            categories={props.categories}
                            isIssuesCategoriesFeatureEnabled={
                                props.isIssuesCategoriesFeatureEnabled
                            }
                            locations={props.locations}
                            users={props.users}
                            setSubmitButtonDisabled={
                                props.setSaveButtonDisabled
                            }
                            setFormState={props.setFormState}
                        />
                    </div>
                </Box>
            </div>
        </div>
    );
};
export default TaskDetailsLayout;
