import {default as auth} from './auth.json';
import {default as archive} from './archive.json';
import {default as issues} from './issues.json';
import {default as locations} from './locations.json';
import {default as tasks} from './tasks.json';
import {default as common} from './common.json';
import {default as sidebar} from './sidebar.json';
import {default as branches} from './branches.json';
import {default as users} from './users.json';
import {default as environments} from './environments.json';
import {default as elements} from './elements.json';
import {default as reports} from './reports.json';
import {default as categories} from './categories.json';
import {default as pdfGenerator} from './pdf-generator.json';

export const plResources = {
    common,
    auth,
    archive,
    issues,
    locations,
    tasks,
    sidebar,
    branches,
    users,
    environments,
    elements,
    reports,
    categories,
    pdfGenerator,
};
