export const elementConverter = {
    toFirestore: issue => issue,
    fromFirestore: (snapshot, options) => {
        const element = snapshot.data(options);
        convertElement(element);
        return element;
    },
};

export const convertElement = element => {
    element.warrantyTo = element.warrantyTo && element.warrantyTo.toDate();
    element.createdDate = element.createdDate?.toDate();
};
