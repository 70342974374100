import React from 'react';
import {roles as ROLES} from '../../constants/roles';
import AddElementDialog from '../../components/Element/AddElementDialog/AddElementDialog';

const mappings = {};

mappings[ROLES.MAINTAINER] = {
    innerComponents: [props => null],
    roleProps: {showAddButton: false},
};

mappings[ROLES.SUPERVISOR] = {
    innerComponents: [props => <AddElementDialog {...props} />],
    roleProps: {showAddButton: true},
};

mappings[ROLES.ADMIN] = {
    innerComponents: [props => <AddElementDialog {...props} />],
    roleProps: {showAddButton: true},
};

mappings[ROLES.SYSTEMADMIN] = {
    innerComponents: [props => <AddElementDialog {...props} />],
    roleProps: {showAddButton: true},
};

export {mappings};
