import firebase from 'firebase/app';
import {issues} from '../../constants/endpoints';
import {issueConverter} from '../converter/issueConverter';
import QueryBuilder from './QueryBuilder';

class IssueQueryBuilder {
    constructor(branchIds, organizationData, user) {
        const createQuery = () =>
            user.roles.reporter &&
            organizationData.reporterCanSeeAllIssues === false
                ? firebase
                      .firestore()
                      .collection(issues())
                      .where('reporter.uid', '==', user.uid)
                : firebase.firestore().collection(issues());
        this._queryBuilder = new QueryBuilder(
            createQuery,
            branchIds,
            {},
            issueConverter,
        );
    }

    static query(branchIds, organizationData, user) {
        return new IssueQueryBuilder(branchIds, organizationData, user);
    }

    withCategory(category) {
        return this._queryBuilder.withCategory(category);
    }

    withUserId(userId) {
        return this._queryBuilder.withUserId(userId);
    }

    onSnapshot(callback, onError) {
        return this._queryBuilder.onSnapshot(callback, onError);
    }

    get() {
        return this._queryBuilder.get();
    }
}

const getIssuesQuery = IssueQueryBuilder.query;

export default getIssuesQuery;
