import {MuiThemeProvider} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {MainTheme} from '../../utils/Themes/Themes';
import {CustomTextField, AddButton} from '../../common/components/index';
import styles from './LoginForm.module.css';
import logoSmall from '../../static/images/logo/logo_small2x.png';

const LoginForm = props => {
    const {t} = useTranslation('auth');

    return (
        <form className={styles.container}>
            <div className={styles.logoContainer}>
                <img className={styles.logo} src={logoSmall} alt="" />
            </div>
            <div className={styles.title}>{t('login.title')}</div>
            <MuiThemeProvider theme={MainTheme}>
                <CustomTextField
                    type="email"
                    placeholder={t('login.emailPlaceholder')}
                    fullWidth
                    onChange={props.onLoginTextChange}
                    label={t('login.email')}
                    autoFocus
                />
                <div className={styles.textInput}>
                    <div className={styles.passwordLabel}>
                        {t('login.password')}
                        <div
                            onClick={props.onChangePasswordClick}
                            className={styles.resetPasswordButton}
                        >
                            {t('login.forgotPassword')}
                        </div>
                    </div>
                    <TextField
                        type="password"
                        placeholder={t('login.passwordPlaceholder')}
                        fullWidth
                        className={styles.passwordTextField}
                        onChange={props.onPasswordTextChange}
                        InputProps={{disableUnderline: true}}
                    />
                </div>
            </MuiThemeProvider>
            <MuiThemeProvider theme={MainTheme}>
                <div className={styles.marginForButton}>
                    {props.alert}
                    <AddButton
                        className={styles.loginButton}
                        disabled={props.disabled}
                        onClick={event => {
                            event.preventDefault();
                            props.onLoginButtonClicked();
                        }}
                        type="submit"
                        text={t('login.actionBtn')}
                    />
                </div>
            </MuiThemeProvider>
        </form>
    );
};

export default LoginForm;
