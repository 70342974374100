import {ISSUE_STATUS, type IssueStatus} from './issue';

export type IssueHistoryEntry = {
    changes: {
        status?: IssueStatus;
    };
    date: Date;
};

export const getIssueOpeningDate = (
    issueHistory: IssueHistoryEntry[],
): Date | undefined =>
    issueHistory.find(history => history.changes?.status === ISSUE_STATUS.open)
        ?.date;

export const getIssueFirstInReviewDate = (
    issueHistory: IssueHistoryEntry[],
): Date | undefined =>
    issueHistory.find(
        history => history.changes?.status === ISSUE_STATUS.inReview,
    )?.date;
