import {SET_LOCATIONS, SET_SELECTED_LOCATION} from '../action/actionTypes';

const initialState = {
    locations: [],
    selectedLocation: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOCATIONS:
            return {
                ...state,
                locations: action.locations,
            };
        case SET_SELECTED_LOCATION:
            return {
                ...state,
                selectedLocation: action.selectedLocation,
            };
        default:
            return state;
    }
};

export default reducer;
