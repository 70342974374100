import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {CustomTextField, RolePicker} from '../../../common/components';
import {NotificationContext} from '../../../context/notifications';
import Dialog from '../../../common/components/Dialog/Dialog';
import BranchPicker from '../../../common/components/branchPicker/BranchPicker';
import {roles} from '../../../constants/roles';
import {TYPES} from '../../../constants/error';

class AddUserDialog extends Component {
    state = {
        name: '',
        surname: '',
        email: '',
        password: '',
        checkPassword: '',
        role: '',
        busy: false,
        branches: [],
        isPasswordTouched: false,
        isPasswordConfirmTouched: false,
        isEmailTouched: false,
        dialogRef: null,
    };

    static contextType = NotificationContext;

    constructor(props) {
        super(props);
        if (props.branches.length === 1) {
            this.state.branches = this.props.branches;
        }
    }

    componentDidMount() {
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.branches.length && this.props.branches.length === 1) {
            this.setState({branches: this.props.branches});
        }
    }

    render() {
        const {busy, role} = this.state;
        this.t = this.props.t;

        return (
            <Dialog
                ref={dialogRef =>
                    !this.state.dialogRef && this.setState({dialogRef})
                }
                loading={busy}
                handleClose={this.props.handleClose}
                dialogTitle={this.t('dialog.title')}
                submitButtonDisabled={this.isSubmitButtonDisabled()}
                onSubmitHandler={this.onSubmitNewUserHandler}
                submitButtonText={this.t('dialog.submitBtn')}
            >
                <CustomTextField
                    label={this.t('dialog.name')}
                    onChange={this.onTextChangeHandler('name')}
                    fullWidth
                    first
                />
                <CustomTextField
                    label={this.t('dialog.surname')}
                    onChange={this.onTextChangeHandler('surname')}
                    fullWidth
                />
                <CustomTextField
                    label={this.t('dialog.email')}
                    onChange={this.onTextChangeHandler('email')}
                    onBlur={this.onEmailBlurHandler}
                    fullWidth
                    type="email"
                    helperText={
                        this.isEmailIncorrect() &&
                        this.t('notifications.dialog.emailIncorrectWarning')
                    }
                    error
                />
                <RolePicker handleChange={this.onRoleChangeHandler} />
                <BranchPicker
                    handleChange={this.onBranchChange}
                    branches={this.props.branches}
                    selectedBranches={this.state.branches}
                    disabled={role.toUpperCase() === roles.ADMIN}
                    multi
                    menuPortalTarget={this.state.dialogRef}
                />
                <CustomTextField
                    label={this.t('dialog.password')}
                    onChange={this.onTextChangeHandler('password')}
                    onBlur={this.onPasswordBlurHandler}
                    fullWidth
                    type="password"
                    helperText={
                        this.isPasswordShort() &&
                        this.t('notifications.dialog.tooShortPasswordWarning')
                    }
                    error
                />
                <CustomTextField
                    label={this.t('dialog.confirmPassword')}
                    onChange={this.onTextChangeHandler('checkPassword')}
                    onBlur={this.onPasswordConfirmBlurHandler}
                    fullWidth
                    type="password"
                    helperText={
                        this.arePasswordsDifferent() &&
                        this.t('notifications.dialog.passwordsDifferentWarning')
                    }
                    error
                />
            </Dialog>
        );
    }

    isSubmitButtonDisabled = () => {
        const {email, password, checkPassword, name, surname, role, branches} =
            this.state;
        return (
            !email ||
            !password ||
            checkPassword !== password ||
            !name ||
            !surname ||
            !role ||
            !branches.length ||
            password.length < 6
        );
    };

    isPasswordShort = () =>
        this.state.isPasswordTouched && this.state.password.length < 6;

    arePasswordsDifferent = () =>
        this.state.isPasswordConfirmTouched &&
        this.state.checkPassword !== this.state.password;

    isEmailIncorrect = () =>
        this.state.isEmailTouched &&
        !this.state.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

    onEmailBlurHandler = () => {
        this.setState({isEmailTouched: true});
    };

    onPasswordBlurHandler = () => {
        this.setState({isPasswordTouched: true});
    };

    onPasswordConfirmBlurHandler = () => {
        this.setState({isPasswordConfirmTouched: true});
    };

    onRoleChangeHandler = change => {
        const role = change.value;
        const branches =
            role === roles.ADMIN ? this.props.branches : this.state.branches;
        this.setState({role: role.toLowerCase(), branches});
    };

    onTextChangeHandler = field => event => {
        this.setState({[field]: event.target.value});
    };

    onBranchChange = selectedBranches => {
        selectedBranches = selectedBranches || [];
        const branches = selectedBranches.map(branch => ({
            id: branch.value,
            name: branch.label,
        }));
        this.setState({branches});
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    onSubmitNewUserHandler = () => {
        const {name, surname, password, role, email, branches} = this.state;
        this.setState({busy: true});
        const userToSet = {
            name,
            surname,
            email,
            role,
            password,
            branches,
        };
        this.props.onUserAdded(
            userToSet,
            error => {
                this.setState({busy: false});
                this.showNotification(this.t(error), TYPES.error);
            },
            () => {
                this.showNotification(
                    this.t('notifications.dialog.userAddSuccess'),
                    TYPES.success,
                );
                this.props.fetchUsers();
                this.props.handleClose();
            },
        );
    };
}

const mapStateToProps = store => ({
    branches: store.branch.branches,
});

export default connect(mapStateToProps)(
    withTranslation('users')(AddUserDialog),
);
