import React from 'react';
import {Link} from 'react-router-dom';
import {LOGIN} from '../../constants/routes';
import withUser from '../../hoc/User/WithUser';

const userStatusPanel = () => (
    <div>
        <Link to={LOGIN} />
    </div>
);

export default withUser(userStatusPanel);
