import firebase from 'firebase/app';
import {AUTH_SET_USER_DATA, AUTH_SET_ORGANIZATION_DATA} from './actionTypes';
import {clearStorage, saveUserData} from '../../storage/localStorage';
import {setUserSelectedBranches} from './branch';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

export const tryAuth =
    (authData, onLoginFailed, onLoginSuccess) => dispatch => {
        firebase
            .auth()
            .signInWithEmailAndPassword(authData.login, authData.password)
            .then(result => {
                fetchUserData(result.user.uid)
                    .then(doc => {
                        const userData = doc.data();
                        saveUserData(userData);
                        authSetUserData(userData || {});
                        dispatch(setUserSelectedBranches(userData.branches));
                        onLoginSuccess();
                    })
                    .catch(error => {
                        errorHandler(error);
                    });
            })
            .catch(error => {
                onLoginFailedHandler(error.code, onLoginFailed);
            });
    };

const onLoginFailedHandler = (code, onError) => {
    switch (code) {
        case 'auth/invalid-email':
        case 'auth/wrong-password':
            onError('login.notifications.wrongCredentialsError');
            break;
        case 'auth/user-disabled':
            onError('login.notifications.userDisabledError');
            break;
        case 'auth/user-not-found':
            onError('login.notifications.userNotFoundError');
            break;
        default:
            onError('login.notifications.defaultError');
    }
};

const onResetFailedHandler = (code, onError) => {
    switch (code) {
        case 'auth/invalid-email':
        case 'auth/wrong-password':
            onError('resetPassword.notifications.wrongCredentialsError');
            break;
        case 'auth/user-disabled':
            onError('resetPassword.notifications.userDisabledError');
            break;
        case 'auth/user-not-found':
            onError('resetPassword.notifications.userNotFoundError');
            break;
        default:
            onError('resetPassword.notifications.defaultError');
    }
};

export const fetchUserData = userId => {
    const userRef = firebase.firestore().collection('user').doc(userId);
    return userRef.get();
};

export const fetchOrganizationData = organization => {
    const organizationRef = firebase
        .firestore()
        .collection('organization')
        .doc(organization);
    return organizationRef.get();
};

export const sendResetPasswordEmail = (email, onError) => dispatch => {
    firebase
        .auth()
        .sendPasswordResetEmail(email)
        .catch(error => onResetFailedHandler(error.code, onError));
};

export const logOut = () => dispatch => {
    firebase
        .auth()
        .signOut()
        .then(result => {
            dispatch(authSetUserData(null));
            clearStorage();
        })
        .catch(error => {
            errorHandler(error);
        });
};

export const authSetUserData = userData => ({
    type: AUTH_SET_USER_DATA,
    userData,
});

export const authSetOrganizationData = organizationData => ({
    type: AUTH_SET_ORGANIZATION_DATA,
    organizationData,
});
