export const roles = {
    ADMIN: 'ADMIN',
    SUPERVISOR: 'SUPERVISOR',
    MAINTAINER: 'MAINTAINER',
    REPORTER: 'REPORTER',
    SYSTEMADMIN: 'SYSTEMADMIN',
};

export const allRoles = [
    roles.ADMIN,
    roles.MAINTAINER,
    roles.SUPERVISOR,
    roles.REPORTER,
    roles.SYSTEMADMIN,
];

export const rolesPriority = {
    [roles.SYSTEMADMIN]: 5,
    [roles.ADMIN]: 4,
    [roles.SUPERVISOR]: 3,
    [roles.MAINTAINER]: 2,
    [roles.REPORTER]: 1,
};

export const labels = {
    ADMIN: 'Admin',
    SUPERVISOR: 'Supervisor',
    MAINTAINER: 'Maintainer',
    REPORTER: 'Reporter',
};
