import {json2csv} from 'json-2-csv';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

export const objectToCsvBlob = data =>
    json2csv(data)
        .then(csv => {
            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            return blob;
        })
        .catch(errorHandler);
