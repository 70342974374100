import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {addNewTask} from '../../../utils/task/task';
import CreateNewTaskLayout from './CreateNewTaskLayout';
import {NotificationContext} from '../../../context/notifications';
import getLocationsQuery from '../../../utils/queryBuilder/LocationQueryBuilder';
import getElementsQuery from '../../../utils/queryBuilder/ElementsQueryBuilder';
import {TYPES} from '../../../constants/error';
import {
    compareStringWithNumericPrefixes,
    getComparator,
} from '../../../utils/sorting/sorting';
import errorHandler from '../../../common/components/ExceptionReporting/ErrorReporting';

class CreateNewTask extends Component {
    state = {
        elements: [],
        locations: [],
        branch: null,
        addButtonDisabled: true,
        loading: false,
    };

    task = null;

    static contextType = NotificationContext;

    notificationSystem = this.context;

    componentDidMount() {
        const {selectedBranches} = this.props;
        const branchIds = selectedBranches.map(branch => branch.id);
        this.subscribeOnLocations(branchIds);
        this.subscribeOnElements(branchIds);
    }

    subscribeOnLocations(branchIds) {
        this.unsubscribeLocations = getLocationsQuery(branchIds).onSnapshot(
            locations => this.setState({locations}),
            errorHandler,
        );
    }

    subscribeOnElements(branchIds) {
        this.unsubscribeElements = getElementsQuery(branchIds).onSnapshot(
            elements => {
                const comparator = getComparator(
                    compareStringWithNumericPrefixes,
                    'DESC',
                );
                const newElements = elements.sort((a, b) =>
                    comparator(a.name, b.name),
                );

                this.setState({elements: newElements});
            },
            errorHandler,
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
            prevProps.selectedBranches.length
        ) {
            const branchIds = this.props.selectedBranches.map(
                branch => branch.id,
            );
            this.unsubscribeLocations && this.unsubscribeLocations();
            this.subscribeOnLocations(branchIds);
            this.subscribeOnElements(branchIds);
        }
    }

    componentWillUnmount() {
        this.unsubscribeLocations();
        this.unsubscribeElements();
    }

    render() {
        const {elements, locations, loading} = this.state;
        const {onClose} = this.props;
        this.t = this.props.t;

        return (
            <CreateNewTaskLayout
                elements={elements}
                locations={locations}
                categories={this.props.categories}
                isIssuesCategoriesFeatureEnabled={
                    this.props.organizationData.isIssuesCategoriesFeatureEnabled
                }
                onClose={onClose}
                loading={loading}
                submitButtonDisabled={this.state.addButtonDisabled}
                onSubmitNewTaskHandler={this.onSubmitNewTaskHandler}
                setAddButtonDisabled={this.setAddButtonDisabled}
                setFormState={this.setFormState}
            />
        );
    }

    setAddButtonDisabled = value => {
        this.setState({addButtonDisabled: value});
    };

    isSubmitButtonDisabled = () => {
        const {assignedTo, name, selectedElements, startDate} = this.state;
        return !assignedTo || !selectedElements.length || !startDate || !name;
    };

    setFormState = task => {
        this.task = task;
    };

    onSubmitNewTaskHandler = () => {
        this.setState({loading: true});
        const {userData} = this.props;
        const {
            active,
            branch,
            category,
            assignedTo,
            repeat,
            selectedElements,
            startDate,
            name,
            notifications,
            priority,
            addToIssueList,
            addToIssueListTimePeriod,
            finishRepeatDate,
        } = this.task;
        const newTask = {
            active,
            repeat: +repeat,
            branch,
            assignedTo: assignedTo && {
                name: assignedTo.name,
                surname: assignedTo.surname,
                uid: assignedTo.uid,
            },
            reporter: {
                name: userData.name,
                surname: userData.surname,
                uid: userData.uid,
            },
            category: category,
            elements: selectedElements.filter(Boolean),
            startDate,
            name,
            nextOccurrenceDate: startDate,
            notifications,
            priority,
            addToIssueList,
            addToIssueListTimePeriod,
            finishRepeatDate,
        };
        addNewTask(
            newTask,
            () => {
                this.showNotification(
                    this.t('notifications.elementAddSuccess'),
                    'success',
                );
                this.props.onClose();
            },
            () => {
                this.setState({loading: false});
                this.showNotification(
                    this.t('notifications.elementAddError'),
                    TYPES.error,
                );
            },
        );
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message,
                type,
            });
        }
    };
}

const mapStateToProps = state => ({
    organizationData: state.auth.organizationData,
    categories: state.category.categories,
    userData: state.auth.userData,
    selectedBranches: state.branch.selectedBranches,
});

export default withRouter(
    connect(mapStateToProps)(withTranslation('tasks')(CreateNewTask)),
);
