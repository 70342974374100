import React from 'react';
import {useTranslation} from 'react-i18next';
import {ListActionPanel} from '../../../common/components/index';

const IssuesPageControl = props => {
    const {t} = useTranslation('issues');
    const {
        onSearchTextChange,
        onToggleAddDialogHandler,
        onFilterIconClickHandler,
        openFilter,
        filterState,
        onCheckboxClick,
        showAddIssueButton,
        searchText,
    } = props;

    return (
        <ListActionPanel
            searchPlaceholder={t('search.placeholder')}
            title={t('title')}
            addButtonText={t('actionBtn')}
            onSearchTextChange={onSearchTextChange}
            searchText={searchText}
            onToggleAddDialogHandler={onToggleAddDialogHandler}
            onFilterIconClickHandler={onFilterIconClickHandler}
            openFilter={openFilter}
            filterState={filterState}
            onCheckboxClick={onCheckboxClick}
            showAddButton={showAddIssueButton}
            withFilter
        />
    );
};

export default IssuesPageControl;
