import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import TaskList from '../../components/Tasks/TasksList/TaskList';
import {fetchTasks} from '../../store/action';
import PageControl from '../../components/Tasks/TasksPageControl/TasksPageControl';
import {compareTaskWithQueryString} from '../../utils/filtering/filtering';
import {fetchAllTasks, updateTask} from '../../utils/task/task';
import {CustomCircularProgress} from '../../common/components/index';
import {TASK} from '../../constants/routes';
import CreateNewTask from '../../components/Tasks/CreateNewTask/CreateNewTask';
import {byTruthiness, byDate} from '../../utils/sorting/sorting';
import {fetchCategories} from '../../store/action/category';
import {categories} from '../../constants/endpoints';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

class TaskPage extends Component {
    state = {
        searchString: '',
        indicateActivity: true,
        newTaskDialogOpen: false,
    };

    componentDidMount() {
        this.unsubscribeTasks = fetchAllTasks(
            snapshot =>
                this.props.fetchTasks(snapshot, this.setActivityIndicator),
            errorHandler,
        );
        this.subscribeOnCategories();
    }

    componentWillUnmount() {
        this.unsubscribeTasks();
        this.unsubscribeCategories && this.unsubscribeCategories();
    }

    subscribeOnCategories() {
        this.unsubscribeCategories = firebase
            .firestore()
            .collection(categories())
            .onSnapshot(this.props.fetchCategories, errorHandler);
    }

    render() {
        const taskList = this.state.indicateActivity ? (
            <CustomCircularProgress />
        ) : (
            <TaskList
                tasks={this.filterTasks(
                    this.props.tasks
                        .sort(this.tasksByDate)
                        .sort(this.tasksByActive),
                )}
                onTaskClick={this.onTaskClickHandler}
                onActiveStateChange={this.onActiveStateChange}
            />
        );
        return (
            <>
                <PageControl
                    onSearchTextChange={this.onSearchTextChange}
                    onToggleAddDialogHandler={this.onToggleAddDialogHandler}
                />
                {this.state.newTaskDialogOpen && (
                    <CreateNewTask
                        onClose={this.onToggleAddDialogHandler}
                        loading={this.state.indicateActivity}
                    />
                )}
                <div>{taskList}</div>
            </>
        );
    }

    tasksByActive = (a, b) => byTruthiness(a.active, b.active);

    tasksByDate = (a, b) =>
        a.active
            ? byDate(a.nextOccurrenceDate, b.nextOccurrenceDate)
            : byDate(b.nextOccurrenceDate, a.nextOccurrenceDate);

    setActivityIndicator = showIndicator => {
        this.setState({indicateActivity: showIndicator});
    };

    onTaskClickHandler = taskId => () => {
        this.props.history.push(TASK(taskId));
    };

    onToggleAddDialogHandler = () => {
        this.setState({newTaskDialogOpen: !this.state.newTaskDialogOpen});
    };

    onActiveStateChange = event => {
        event.stopPropagation();

        const taskId = event.target.name;
        const active = event.target.checked;
        const task = {
            ...this.props.tasks.find(task => task.id === taskId),
        };
        task.active = active;
        updateTask(taskId, task);
    };

    filterTasks = tasks => {
        const {searchString} = this.state;
        const {selectedBranches} = this.props;
        const taskList = tasks.filter(task =>
            selectedBranches.some(branch => branch.id === task.branch.id),
        );
        if (searchString) {
            return taskList.filter(task =>
                compareTaskWithQueryString(task, searchString),
            );
        }
        return taskList;
    };

    onSearchTextChange = event => {
        this.setState({searchString: event.target.value});
    };
}

const mapStateToProps = state => ({
    tasks: state.task.tasks,
    selectedBranches: state.branch.selectedBranches,
});

const mapDispatchToProps = dispatch => ({
    fetchTasks: fetchTasks(dispatch),
    fetchCategories: dispatch(fetchCategories),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskPage);
