import React, {Component} from 'react';
import * as moment from 'moment';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {
    List,
    ListItem,
    ListColumnCreator,
} from '../../../common/components/index';
import styles from './ArchivedIssueList.module.css';
import ElementName from '../../../common/components/list/listItem/display/elementName/elementName';
import ListItemMobile from '../../../common/components/list/listItem/ListItemMobile/ListItemMobile';
import MobileHeader from '../../../common/components/list/listItem/display/mobileHeader/mobileHeader';
import MobileDataDisplay from '../../../common/components/list/listItem/display/mobileDataDisplay/moblieDataDisplay';
import MobileList from '../../../common/components/list/MobileList';
import Status from '../IssuesList/IssueListItem/display/Status/Status';

class ArchivedIssuesList extends Component {
    columns;

    columnIds;

    constructor(props) {
        super(props);
        this.createColumns(props.t);
        this.columnIds = this.columns.map(column => column.id);
    }

    createColumns = t => {
        this.columns = [
            new ListColumnCreator(
                'elementName',
                this.props.organizationData.eventBased
                    ? t('table.columns.eventBased.workStation')
                    : t('table.columns.default.element'),
                {
                    accessAttribute: issue => issue.element.name,
                },
            ),
            new ListColumnCreator(
                'locationName',
                this.props.organizationData.eventBased
                    ? t('table.columns.eventBased.company')
                    : t('table.columns.default.location'),
                {
                    accessAttribute: issue => issue.element.location.name,
                },
            ),
            new ListColumnCreator(
                'createdDate',
                t('table.columns.default.creationDate'),
                {},
            ),
            new ListColumnCreator(
                'assignedEmployee',
                t('table.columns.default.assignedTo'),
                {
                    accessAttribute: issue =>
                        issue.assignedTo &&
                        `${issue.assignedTo.name} ${issue.assignedTo.surname}`,
                },
            ),
            new ListColumnCreator(
                'closedDate',
                t('table.columns.default.closedDate'),
                {},
            ),
            new ListColumnCreator(
                'closedBy',
                t('table.columns.default.closedBy'),
                {
                    accessAttribute: issue =>
                        `${issue.acceptedBy.name} ${issue.acceptedBy.surname}`,
                },
            ),
        ];
    };

    render() {
        const {issues} = this.props;

        return (
            <>
                <List
                    className={styles.listA}
                    columns={this.columns}
                    rows={issues}
                    rowTemplate={this.getIssueListItemTemplate}
                    name="archivedIssues"
                />
                <MobileList
                    columns={['elementName', 'image']}
                    rows={issues}
                    rowTemplate={this.getMobileIssueListItemTemplate}
                />
            </>
        );
    }

    getIssueListItemTemplate = issue => (
        <ListItem
            key={issue.id}
            columns={this.columnIds}
            onClick={this.props.onIssueClick(issue.id)}
        >
            <ElementName
                elementName={issue.element.name}
                iconUri={issue.iconUri}
            />
            {issue.element.location.name}
            {moment(issue.createdDate).format('DD/MM/YYYY')}
            {issue.assignedTo &&
                `${issue.assignedTo.name} ${issue.assignedTo.surname}`}
            {moment(issue.closedDate).format('DD/MM/YYYY')}
            {`${issue.acceptedBy.name} ${issue.acceptedBy.surname}`}
        </ListItem>
    );

    getMobileIssueListItemTemplate = issue => (
        <ListItemMobile
            key={issue.id}
            onClick={this.props.onIssueClick(issue.id)}
        >
            <MobileHeader classes={['status', 'date']}>
                <Status status={issue.status} />
                {moment(issue.createdDate).format('DD/MM/YYYY')}
            </MobileHeader>
            <MobileDataDisplay
                image={issue.iconUri}
                left={issue.element.name}
                right={issue.element.location.name}
                title={issue.issueDescription}
            />
        </ListItemMobile>
    );
}

const mapStateToProps = state => ({
    organizationData: state.auth.organizationData,
});

export default connect(mapStateToProps)(
    withTranslation('archive')(ArchivedIssuesList),
);
