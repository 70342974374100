import React, {Component} from 'react';
import {isAxiosError} from 'axios';
import {withTranslation} from 'react-i18next';
import EnvironmentPageLayout from './EnvironmentPageLayout';
import * as endpoints from '../../constants/endpoints';
import {
    sendDataToImport,
    sendDataToUpdate,
    deleteEnvironment,
} from '../../store/action/environment';
import {NotificationContext} from '../../context/notifications';
import {TYPES} from '../../constants/error';
import {objectToCsvBlob} from '../../utils/converter/objectToCsvBlob';
import {instantDownload} from '../../utils/download/instantDownload';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

class EnvironmentPage extends Component {
    state = {
        environmentSelectedForEdit: null,
        environments: null,
        addDialogOpen: false,
        editDialogOpen: false,
        importEnvironmentPending: false,
    };

    static contextType = NotificationContext;

    notificationSystem = this.context;

    componentDidMount() {
        this.getEnvironments();
    }

    getEnvironments = () =>
        endpoints
            .environment()
            .get()
            .then(data =>
                this.setState({
                    environments: data.docs.map(doc => ({
                        id: doc.id,
                        name: doc.id,
                        reporterCanSeeAllIssues:
                            doc.data().reporterCanSeeAllIssues,
                        sendEmailWithNotificationToUserAfterAssignToIssue:
                            doc.data()
                                .sendEmailWithNotificationToUserAfterAssignToIssue,
                        showRecentlyArchivedIssuesOnIssuesList:
                            doc.data().showRecentlyArchivedIssuesOnIssuesList,
                        sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate:
                            doc.data()
                                .sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate,
                        isIssuesCategoriesFeatureEnabled:
                            doc.data().isIssuesCategoriesFeatureEnabled,
                        maintainerHasNoPermissionToAssignUsersToIssue:
                            doc.data()
                                .maintainerHasNoPermissionToAssignUsersToIssue,
                        supervisorHasAccessToTasks:
                            doc.data().supervisorHasAccessToTasks,
                        eventBased: doc.data().eventBased,
                        issueExpiration: doc.data().issueExpiration,
                        reporterHasNoPermissionToEditIssueDescription:
                            doc.data()
                                .reporterHasNoPermissionToEditIssueDescription,
                        maintainerHasNoPermissionToEditIssueDescription:
                            doc.data()
                                .maintainerHasNoPermissionToEditIssueDescription,
                        maintainerHasNoPermissionToAddIssue:
                            doc.data().maintainerHasNoPermissionToAddIssue,
                        reporterCanSeeIssueSolution:
                            doc.data().reporterCanSeeIssueSolution,
                        maintainerGetPushOnAllIssuesCreated:
                            doc.data().maintainerGetPushOnAllIssuesCreated,
                        supervisorAndMaintainerGetChangeMail:
                            doc.data().supervisorAndMaintainerGetChangeMail,
                        reporterGetChangeMail: doc.data().reporterGetChangeMail,
                    })),
                }),
            )
            .catch(errorHandler);

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message,
                type,
            });
        }
    };

    deleteEnvironment = environmentId => {
        if (window.confirm(this.t('notifications.elementDeleteConfirm'))) {
            deleteEnvironment(environmentId)
                .then(() => this.getEnvironments())
                .then(() =>
                    this.showNotification(
                        this.t('notifications.elementDeleteSuccess'),
                        TYPES.success,
                    ),
                )
                .catch(error => {
                    this.showNotification(
                        this.t('notifications.elementDeleteError'),
                        TYPES.error,
                    );
                });
        }
    };

    onEnvironmentClickHandler = environmentId => {
        const environment = this.state.environments.find(
            env => env.id === environmentId,
        );
        this.setState({
            environmentSelectedForEdit: environment,
            editDialogOpen: true,
        });
    };

    makeDataDownloadableAsCsv = (data, filename) => {
        objectToCsvBlob(data).then(blob => {
            instantDownload(blob, filename);
        });
    };

    toggleAddDialog = () =>
        this.setState({addDialogOpen: !this.state.addDialogOpen});

    toggleEditDialog = () =>
        this.setState({editDialogOpen: !this.state.editDialogOpen});

    isFileValidationError = error =>
        isAxiosError(error) &&
        error?.response?.status === 400 &&
        error?.response?.data?.errors;

    onSubmitEnvironment = formData => {
        this.setState({importEnvironmentPending: true});
        sendDataToImport(formData)
            .then(({data}) => {
                this.getEnvironments();
                this.showNotification(
                    this.t('notifications.elementAddSuccess'),
                    TYPES.success,
                );
                this.makeDataDownloadableAsCsv(
                    data,
                    `${formData.environmentName}_users.csv`,
                );
            })
            .catch(error => {
                if (this.isFileValidationError(error)) {
                    this.makeDataDownloadableAsCsv(
                        error.response.data.errors.map(errorMessage => ({
                            error: errorMessage,
                        })),
                        `${formData.environmentName}-import_file_errors.csv`,
                    );
                }
                this.showNotification(
                    this.t('notifications.elementAddError'),
                    TYPES.error,
                );
            })
            .finally(() => {
                this.setState({importEnvironmentPending: false});
                this.toggleAddDialog();
            });
    };

    onSubmitUpdateEnvironment = formData => {
        this.setState({importEnvironmentPending: true});
        sendDataToUpdate(formData)
            .then(({data}) => {
                this.getEnvironments();
                this.showNotification(
                    this.t('notifications.elementEditSuccess'),
                    TYPES.success,
                );
                if (data) {
                    this.makeDataDownloadableAsCsv(
                        data,
                        formData.newEnvironmentName,
                    );
                }
            })
            .catch(error => {
                if (this.isFileValidationError(error)) {
                    this.makeDataDownloadableAsCsv(
                        error.response.data.errors.map(errorMessage => ({
                            error: errorMessage,
                        })),
                        `${formData.environmentName}-update_file_errors.csv`,
                    );
                }
                this.showNotification(
                    this.t('notifications.elementEditError'),
                    TYPES.error,
                );
            })
            .finally(() => {
                this.setState({importEnvironmentPending: false});
                this.toggleEditDialog();
            });
    };

    render() {
        this.t = this.props.t;

        return EnvironmentPageLayout({
            props: {
                t: this.props.t,
                importEnvironmentPending: this.state.importEnvironmentPending,
                environmentSelectedForEdit:
                    this.state.environmentSelectedForEdit,
                addDialogOpen: this.state.addDialogOpen,
                editDialogOpen: this.state.editDialogOpen,
                onEnvironmentClickHandler: this.onEnvironmentClickHandler,
                environments: this.state.environments,
                toggleAddDialog: this.toggleAddDialog,
                toggleEditDialog: this.toggleEditDialog,
                onSubmitEnvironment: this.onSubmitEnvironment,
                onSubmitUpdateEnvironment: this.onSubmitUpdateEnvironment,
                onDeleteEnvironmentHandler: this.deleteEnvironment,
            },
        });
    }
}

export default withTranslation('environments')(EnvironmentPage);
