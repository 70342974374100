import React from 'react';
import {useTranslation} from 'react-i18next';
import {
    CustomDatePicker,
    CustomTextField,
    ElementsPicker,
    IOSSwitchBigger,
    LocationsPicker,
    SwitchField,
    UserPicker,
    AddElement,
    Checkbox,
} from '../../../common/components';
import CategoryPicker from '../../../common/components/CategoryPicker/CategoryPicker';
import RadioButtons from '../../../common/components/radio/RadioButton';
import MultiUserPicker from '../../../components/Tasks/multiUserPicker/MultiUserPicker';

const taskPriorityRadioGroupOptions = translatedPriorityLabels =>
    Object.entries(translatedPriorityLabels).map(([key, value]) => ({
        name: value,
        value: key,
    }));

const TaskFormLayout = props => {
    const {t: tIssues} = useTranslation('issues');
    const {t: tTasks} = useTranslation('tasks');

    const translatedPriorityLabels = {
        low: tIssues('priorityLabels.low'),
        medium: tIssues('priorityLabels.medium'),
        high: tIssues('priorityLabels.high'),
    };

    return (
        <>
            <CustomTextField
                onChange={props.onTaskNameChange}
                label={tTasks('dialog.name')}
                value={props.name}
                first={props.noTopMarginOnFirstInput}
            />
            {props.selectedLocations.map((location, index) => (
                <div key={index}>
                    <LocationsPicker
                        handleChange={location =>
                            props.onLocationChange(location, index)
                        }
                        locations={props.locations}
                        selectedLocation={location}
                    />
                    <ElementsPicker
                        handleChange={element =>
                            props.onElementChange(element, index)
                        }
                        selectedElement={props.selectedElements[index]}
                        elements={props.elementsForLocation[index]}
                    />
                </div>
            ))}
            <AddElement
                text={tTasks('dialog.addElement')}
                onClick={props.onElementAdded}
            />
            <CustomDatePicker
                label={tTasks('dialog.deadline')}
                onChange={props.onStartDateChange}
                value={props.startDate}
            />
            <Checkbox
                onChange={props.checkboxToggle}
                label={tTasks('dialog.sendEmailNotificationCheckbox')}
                name="notificationCheckbox"
                checked={props.notificationCheckbox}
            />
            {props.notificationCheckbox && (
                <>
                    <MultiUserPicker
                        users={props.users}
                        selectedUsers={props.selectedUsers}
                        setSelectedUsers={props.setSelectedUsers}
                    />
                    <CustomTextField
                        label={`${tTasks(
                            'dialog.sendEmailNotificationTemplatePrefix',
                        )} ${props.notificationTime || 0} ${tTasks(
                            'dialog.sendEmailNotificationTemplateSuffix',
                        )}.`}
                        value={props.notificationTime}
                        onChange={props.onNotificationTimeChange}
                        name="notificationTime"
                        inputProps={{maxLength: 3}}
                    />
                </>
            )}
            {props.isIssuesCategoriesFeatureEnabled && (
                <CategoryPicker
                    label={tTasks('dialog.category')}
                    categories={props.categories}
                    selectedCategory={props.category}
                    handleChange={props.onSelectedCategoryChange}
                />
            )}
            <UserPicker
                users={props.users}
                label={tTasks('dialog.assignedTo')}
                handleChange={props.onAssignedToChange}
                selectedUser={props.assignedTo}
            />

            <Checkbox
                onChange={props.checkboxToggle}
                label={tTasks('dialog.addTaskToList')}
                name="addToIssueList"
                checked={props.addToIssueList}
            />
            {props.addToIssueList && (
                <>
                    {RadioButtons({
                        items: taskPriorityRadioGroupOptions(
                            translatedPriorityLabels,
                        ),
                        label: tTasks('dialog.priority'),
                        onChange: props.onPriorityChange,
                        defaultValue: props.defaultPriority,
                    })}
                    <CustomTextField
                        label={`${tTasks(
                            'dialog.addTasksToListTemplatePrefix',
                        )} ${props.addToIssueListTimePeriod} ${tTasks(
                            'dialog.addTasksToListTemplateSuffix',
                        )}.`}
                        value={props.addToIssueListTimePeriod}
                        onChange={props.onAddToIssueListTimePeriodChange}
                        name="addToIssueListTime"
                        inputProps={{maxLength: 3}}
                    />
                </>
            )}

            <CustomTextField
                type="text"
                label={`${tTasks(
                    'dialog.repeatTaskCyclicallyTemplatePrefix',
                )} ${props.repeatDays} ${tTasks(
                    'dialog.repeatTaskCyclicallyTemplateSuffix',
                )}.`}
                onChange={props.onDaysNumberChange}
                inputProps={{maxLength: 4}}
                value={props.repeatDays}
            />
            {!!props.repeatDays && (
                <CustomDatePicker
                    label={tTasks('dialog.repeatTaskCyclicallyUntil')}
                    onChange={props.onFinishRepeatDateChange}
                    value={props.finishRepeatDate}
                />
            )}
            <SwitchField
                label={tTasks('dialog.active')}
                switchComponent={
                    <IOSSwitchBigger
                        onChange={props.onActiveStateChange}
                        checked={props.active}
                    />
                }
            />
        </>
    );
};

export default TaskFormLayout;
