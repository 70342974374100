const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
const MILLISECONDS_PER_MIN = 1000 * 60;
export const MINS_PER_THREE_DAYS = 3 * 1440;
export const MINS_PER_DAY = 1440;
export const MINS_PER_FOUR_HOURS = 240;

export const dateDiffInDays = (date1, date2) =>
    Math.floor((date1 - date2) / MILLISECONDS_PER_DAY);

export const diffTimeInMin = (date1, date2) =>
    Math.floor((date1 - date2) / MILLISECONDS_PER_MIN);

export const isSameDay = (date1, date2) =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
