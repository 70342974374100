import React from 'react';
import {useTranslation} from 'react-i18next';
import {ListActionPanel, CustomCircularProgress} from '../../common/components';
import CategoriesList from '../../components/Category/CategoriesList/CategoriesList';
import AddCategoryDialog from '../../components/Category/AddCategoryDialog/AddCategoryDialog';
import EditCategoryDialog from '../../components/Category/EditCategoryDialog/EditCategoryDialog';

const CategoriesPageLayout = ({props}) => {
    const {t} = props;

    return (
        <div>
            {props.actionPending && <CustomCircularProgress />}
            <ListActionPanel
                searchPlaceholder={t('main.searchPlaceholder')}
                title={t('main.title')}
                addButtonText={t('main.actionBtn')}
                onSearchTextChange={props.onSearchTextChange}
                onToggleAddDialogHandler={props.onToggleAddDialogHandler}
                showAddButton
            />
            {props.addDialogOpen && (
                <AddCategoryDialog
                    handleClose={props.onToggleAddDialogHandler}
                    onSubmitHandler={props.onSubmitHandler}
                    dialogTitle={t('dialog.title.add')}
                    loading={props.actionPending}
                    users={props.users}
                    selectedUsers={props.selectedUsers}
                    setSelectedUsers={props.setSelectedUsers}
                />
            )}
            {props.editDialogOpen && (
                <EditCategoryDialog
                    dialogTitle={t('dialog.title.edit')}
                    onCategoryNameChange={props.onCategoryNameChange}
                    category={props.categorySelectedForEdit}
                    handleClose={props.onToggleEditDialogHandler}
                    onUpdateCategory={props.onUpdateCategory}
                    loading={props.actionPending}
                    users={props.users}
                    selectedUsers={props.selectedUsers}
                    setSelectedUsers={props.setSelectedUsers}
                />
            )}
            <div>
                {props.listLoading ? (
                    <CustomCircularProgress />
                ) : (
                    <CategoriesList
                        onCategoryClick={props.onCategoryClickHandler}
                        categories={props.categories}
                        onDeleteCategory={props.onDeleteCategory}
                    />
                )}
            </div>
        </div>
    );
};
export default CategoriesPageLayout;
