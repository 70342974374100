import {leafletMarkerIcon} from '../../../../../../static/images/icons';
import styles from './locationName.module.css';

type Props = {
    locationName: string;
    hasLocationMarker: boolean;
};

export const LocationName = ({locationName, hasLocationMarker}: Props) => (
    <div className={styles.locationContainer}>
        {locationName}
        {hasLocationMarker && (
            <img
                src={leafletMarkerIcon}
                alt="marker icon"
                className={styles.locationMarker}
            />
        )}
    </div>
);
