type Parameters = {
    imageDimensions: {
        width: number;
        height: number;
    };
    containerDimensions: {
        width: number;
        height: number;
    };
};

/**
 *
 * @link https://leafletjs.com/examples/zoom-levels/
 * @returns
 */
export const getMapMinZoom = ({
    imageDimensions: {width: imageWidth, height: imageHeight},
    containerDimensions: {width: containerWidth, height: containerHeight},
}: Parameters) => {
    const widthRatio = imageWidth / containerWidth;
    const heightRatio = imageHeight / containerHeight;

    if (widthRatio < heightRatio) {
        if (widthRatio < 1) {
            return Math.floor(Math.sqrt(1 / widthRatio));
        }

        return -Math.floor(Math.sqrt(widthRatio));
    }

    if (heightRatio < 1) {
        return Math.floor(Math.sqrt(1 / heightRatio));
    }

    return -Math.floor(Math.sqrt(heightRatio));
};
