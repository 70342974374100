import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {fetchElementByDocumentId} from '../../utils/firestoreDocumentUtils/firestoreDocumentUtils';
import {
    BackNavigation,
    CustomCircularProgress,
} from '../../common/components/index';
import ElementDetailsBox from '../../components/ElementDetails/ElementDetailsBox';
import styles from '../shared/mobileStyle.module.css';
import {ELEMENTS} from '../../constants/routes';

class ElementDetails extends Component {
    state = {
        buttonDisabled: true,
        element: null,
    };

    setElement = element => {
        this.setState({element});
    };

    componentDidMount() {
        fetchElementByDocumentId(this.props.match.params.id, this.setElement);
    }

    setButtonState = state => {
        this.setState({buttonDisabled: state});
    };

    render() {
        const {element} = this.state;
        const {t} = this.props;

        return element ? (
            <div className={styles.mobileBackground}>
                <div className={styles.row}>
                    <BackNavigation
                        text={
                            this.props.organizationData.eventBased
                                ? t('details.eventBased.backNav')
                                : t('details.default.backNav')
                        }
                        header={element.name}
                        backUrl={ELEMENTS}
                    />
                </div>
                <ElementDetailsBox
                    key={element.key}
                    element={element}
                    setButtonState={this.setButtonState}
                    fetchUpdatedElement={this.props.fetchElementByDocumentId}
                />
            </div>
        ) : (
            <CustomCircularProgress />
        );
    }
}

const mapStateToProps = state => ({
    element: state.element.selectedElement,
    organizationData: state.auth.organizationData,
});

export default connect(
    mapStateToProps,
    null,
)(withTranslation('elements')(ElementDetails));
