import React, {useMemo} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import styles from './Status.module.css';

const Status = props => {
    const {t} = useTranslation('issues');

    const STATUS_LABELS = useMemo(
        () => ({
            open: t('statusLabels.open'),
            inProgress: t('statusLabels.inProgress'),
            inReview: t('statusLabels.inReview'),
            done: t('statusLabels.done'),
        }),
        [t],
    );

    return (
        <>
            <span className={classNames(styles.icon, styles[props.status])} />
            <span
                className={classNames(styles.mobileIcon, styles[props.status])}
            >
                {STATUS_LABELS[props.status]}
            </span>
        </>
    );
};

export default Status;
