import firebase from 'firebase/app';
import {SET_ELEMENTS} from './actionTypes';
import {elements, elementImages} from '../../constants/endpoints';
import {
    uploadFile,
    deleteFiles,
    deleteFile,
} from '../../utils/storageUtils/storageUtils';
import {hashFilename} from './utilHelper';
import {elementConverter} from '../../utils/converter/elementConverter';
import {downloadImagesAndSetElements} from './photos';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

const firestore = firebase.firestore();

export const setElements = elements => ({
    elements,
    type: SET_ELEMENTS,
});

export const fetchElements = (elements, setActivityIndicator) => dispatch => {
    setActivityIndicator && setActivityIndicator(true);
    elements = elements.sort((e1, e2) => e1.name > e2.name);
    dispatch(
        downloadImagesAndSetElements(
            elements,
            setElements,
            () => setActivityIndicator && setActivityIndicator(false),
        ),
    );
};

export const putNewElement =
    (element, newIconFile, newFiles) => async dispatch => {
        const newElementRef = firestore.collection(elements()).doc();
        const elementIconData = newIconFile
            ? await uploadElementIcon(newIconFile, newElementRef.id)
            : {patternImagePath: null, iconImagePath: null};
        const elementFiles = newFiles
            ? await uploadFiles(newFiles, newElementRef.id)
            : {files: null};

        const elementData = {...element, ...elementIconData, ...elementFiles};
        return newElementRef.set(elementData);
    };

export const deleteElement = element => dispatch => {
    removeElementIcon(element);
    removeElementFiles(element);

    return firestore.collection(elements()).doc(element.key).delete();
};

const removeElementFiles = element => {
    element.files && element.files.forEach(file => deleteFile(file.path));
};

export const updateElement =
    (element, newIconFile, removeOldIcon, newFiles) => async dispatch => {
        if (removeOldIcon) {
            removeElementIcon(element);
        }

        newFiles.removed.forEach(file => deleteFile(file.path));

        const elementFiles =
            newFiles.new && (await uploadFiles(newFiles.new, element.key));

        newFiles.uploaded.forEach(file => elementFiles.files.push(file));

        const elementIconData =
            newIconFile && (await uploadElementIcon(newIconFile, element.key));

        const elementToSet = {
            ...element,
            ...elementIconData,
            ...elementFiles,
        };
        delete elementToSet.key;

        return firestore
            .collection(elements())
            .doc(element.key)
            .update(elementToSet);
    };

const uploadElementIcon = (iconFile, elementId) => {
    const path = `${elementImages()}/${elementId}/${hashFilename(iconFile)}`;

    return uploadFile(path, iconFile).then(() => ({
        patternImagePath: path,
        iconImagePath: path,
    }));
};

const uploadFiles = (files, elementId) => {
    const filesTable = files.map(file => {
        const path = `${elementImages()}/${elementId}/${hashFilename(file)}`;
        uploadFile(path, file);
        return {name: file.name, path};
    });
    return {files: filesTable};
};

const removeElementIcon = element => {
    const {patternImagePath, iconImagePath} = element;

    element.patternImagePath = null;
    element.iconImagePath = null;
    deleteFiles([patternImagePath, iconImagePath]);
};

export const getElementsByLocationId = locationId => dispatch => {
    firebase
        .firestore()
        .collection(elements())
        .withConverter(elementConverter)
        .where('location.id', '==', locationId)
        .get()
        .then(collection => {
            if (!collection.empty) {
                const elements = collection.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                dispatch(
                    downloadImagesAndSetElements(
                        elements,
                        setElements,
                        () => {},
                    ),
                );
            } else {
                dispatch(setElements([]));
            }
        })
        .catch(error => {
            dispatch(setElements([]));
            errorHandler(error);
        });
};
