import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Picker from '../picker/Picker';

const ElementPicker = props => {
    const {t} = useTranslation('issues');

    const eventBased = useSelector(
        state => state.auth.organizationData.eventBased,
    );

    const items = props.elements?.map(element => ({
        value: element.id,
        label: element.name,
    }));
    const selectedValue = props.selectedElement && {
        value: props.selectedElement.id,
        label: props.selectedElement.name,
    };
    return (
        <Picker
            label={
                eventBased
                    ? t('details.eventBased.workStation')
                    : t('details.default.element')
            }
            selectedValue={selectedValue}
            pickerItems={items}
            name="Elementy"
            handleChange={props.handleChange}
            placeholder={
                eventBased
                    ? t('details.eventBased.searchForWorkStation')
                    : t('details.default.searchForElement')
            }
            className={props.className}
            disabled={props.disabled}
            clearable={props.clearable}
            menuPortalTarget={props.menuPortalTarget}
        />
    );
};

ElementPicker.defaultProps = {
    bottomLabelActive: true,
};

export default ElementPicker;
