import React from 'react';
import styles from './ListActionPanel.module.css';
import {AddButton, SearchField, ListTitle} from '../index';

const ListActionPanel = props => (
    <div className={styles.actionListPanel}>
        <div className={styles.topBar}>
            <ListTitle title={props.title} />
            {props.showAddButton && (
                <span className={styles.addIssueButton}>
                    <AddButton
                        text={props.addButtonText}
                        onClick={props.onToggleAddDialogHandler}
                    />
                </span>
            )}
        </div>
        <SearchField
            placeholder={props.searchPlaceholder}
            onSearchTextChange={props.onSearchTextChange}
            withFilter={props.withFilter}
            openFilter={props.openFilter}
            filterState={props.filterState}
            onCheckboxClick={props.onCheckboxClick}
            onFilterIconClickHandler={props.onFilterIconClickHandler}
            searchText={props.searchText}
        />
    </div>
);

export default ListActionPanel;
