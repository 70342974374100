import React, {useState} from 'react';
import classnames from 'classnames';
import Dialog from '@material-ui/core/Dialog/Dialog';
import styles from './ImageDisplay.module.css';

type Props = {
    title?: string;
    src?: string;
    fullSizeSrc?: string;
    onDelete?: () => void;
    alt?: string;
    onImageClick?: () => void;
    imgClassName?: string;
    deletable?: boolean;
    templateSrc?: string;
    overlayElement?: React.ReactNode;
};

const ImageDisplay = ({
    title,
    src,
    fullSizeSrc,
    onDelete,
    alt,
    onImageClick,
    imgClassName,
    deletable,
    templateSrc,
    overlayElement,
}: Props) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    fullSizeSrc = fullSizeSrc || src;

    return (
        <div className={styles.row}>
            {title && <div className={styles.title}>{title}</div>}
            <div className={styles.wrapper}>
                <img
                    className={classnames(
                        styles.imagePreview,
                        {
                            [styles.iconTemplate]: !src,
                        },
                        imgClassName,
                    )}
                    src={src || templateSrc}
                    alt={alt}
                />
                <div
                    onClick={
                        onImageClick || (() => setDialogOpen(Boolean(src)))
                    }
                    className={styles.overlay}
                >
                    {overlayElement}
                </div>
                {deletable && (
                    <div className={styles.closeButton} onClick={onDelete} />
                )}
                {src && !onImageClick && (
                    <Dialog
                        maxWidth={false}
                        open={dialogOpen}
                        onClick={() => setDialogOpen(false)}
                    >
                        <img src={fullSizeSrc} alt={alt} />
                    </Dialog>
                )}
            </div>
        </div>
    );
};

export default ImageDisplay;
