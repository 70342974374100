import React, {useState} from 'react';
import Dropzone from 'react-dropzone';
import styles from './fileDropzone.module.css';
import {ImageDisplay, PhotoGallery} from '..';
import dropzoneIconSrc from '../../../static/images/icons/Group.svg';

type Props = {
    label: string;
    onDrop: (files: any) => void;
    imagesSrc: string[];
    onRemoveImage: (index: number) => void;
    disabled?: boolean;
    multiple?: boolean;
};

const ImageDropzone = ({
    label,
    onDrop,
    imagesSrc,
    onRemoveImage,
    disabled,
    multiple = true,
}: Props) => {
    const [galleryObj, setGalleryObj] = useState({open: false, index: 0});
    const onDropHandler = (accepted, rejected) => {
        if (!rejected.length) {
            onDrop(accepted);
        }
    };
    const images = imagesSrc.map((imageSrc, index) => (
        <ImageDisplay
            key={imageSrc}
            src={imageSrc}
            onDelete={() => onRemoveImage(index)}
            onImageClick={() => setGalleryObj({open: true, index: index})}
            deletable={!disabled}
        />
    ));
    return (
        <div className={styles.fileDropzone}>
            <header>{label}</header>
            {!disabled && (
                <Dropzone
                    multiple={multiple}
                    onDrop={onDropHandler}
                    accept="image/jpeg, image/png"
                >
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className={styles.content}>
                            <input {...getInputProps()} />
                            <img alt="" src={dropzoneIconSrc} />
                        </div>
                    )}
                </Dropzone>
            )}
            <div className={styles.images}>{images}</div>
            {galleryObj.open && (
                <PhotoGallery
                    onDialogClick={() =>
                        setGalleryObj({
                            open: false,
                            index: galleryObj.index,
                        })
                    }
                    photos={imagesSrc}
                    index={galleryObj.index}
                />
            )}
        </div>
    );
};
export default ImageDropzone;
