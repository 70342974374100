import React from 'react';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import styles from './priority.module.css';

const Priority = props => {
    const {t} = useTranslation('issues');

    const PRIORITY_LABEL = {
        low: t('priorityLabels.low'),
        medium: t('priorityLabels.medium'),
        high: t('priorityLabels.high'),
    };

    return (
        <div
            className={classnames(styles.highPriority, {
                [styles.priorityDisplay]: !props.active,
            })}
        >
            <span className={styles.mobileDisplay}>
                {props.priority ? PRIORITY_LABEL[props.priority] : '-'}
            </span>
        </div>
    );
};

export default Priority;
