export const REPORTER_CAN_SEE_ALL_ISSUES = 'reporterCanSeeAllIssues';
export const SEND_EMAIL_WITH_NOTIFICATION_TO_USER_AFTER_ASSIGN_TO_ISSUE =
    'sendEmailWithNotificationToUserAfterAssignToIssue';
export const SHOW_RECENTLY_ARCHIVED_ISSUES_ON_ISSUES_LIST =
    'showRecentlyArchivedIssuesOnIssuesList';
export const SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_AND_SUPERVISORS_ON_ISSUE_CREATE =
    'sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate';
export const IS_ISSUES_CATEGORIES_FEATURE_ENABLED =
    'isIssuesCategoriesFeatureEnabled';
export const MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE =
    'maintainerHasNoPermissionToAssignUsersToIssue';
export const SUPERVISOR_HAS_ACCESS_TO_TASKS = 'supervisorHasAccessToTasks';

/**
 * Changes displayed UI elements to match "expo" environment, mostly translations.
 */
export const FAIR_ENVIRONMENT = 'eventBased';
export const ISSUE_EXPIRATION = 'issueExpiration';
export const REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION =
    'reporterHasNoPermissionToEditIssueDescription';
export const MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION =
    'maintainerHasNoPermissionToEditIssueDescription';
export const MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE =
    'maintainerHasNoPermissionToAddIssue';
export const REPORTER_CAN_SEE_ISSUE_SOLUTION = 'reporterCanSeeIssueSolution';
export const MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED =
    'maintainerGetPushOnAllIssuesCreated';
export const SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL =
    'supervisorAndMaintainerGetChangeMail';
export const REPORTER_GET_CHANGE_MAIL = 'reporterGetChangeMail';
export const OVERWRITE_ENVIRONMENT = 'overwriteEnvironment';
