import React from 'react';
import {roles as ROLES} from '../../constants/roles';
import AddUserDialog from '../../components/Users/AddUserDialog/AddUserDialog';

const mappings = {};

mappings[ROLES.MAINTAINER] = {
    innerComponents: [props => null],
    roleProps: {showAddButton: false, linkToDetails: false},
};

mappings[ROLES.SUPERVISOR] = {
    innerComponents: [props => <AddUserDialog {...props} />],
    roleProps: {showAddButton: false, linkToDetails: false},
};

mappings[ROLES.ADMIN] = {
    innerComponents: [props => <AddUserDialog {...props} />],
    roleProps: {showAddButton: true, linkToDetails: true},
};

mappings[ROLES.SYSTEMADMIN] = {
    innerComponents: [props => <AddUserDialog {...props} />],
    roleProps: {showAddButton: true, linkToDetails: true},
};

export {mappings};
