import {BoundsLiteral} from 'leaflet';

/**
 * Map bounds type. Differs from leaflet's BoundsLiteral because of limits of firebase related to nested arrays.
 * @link https://leafletjs.com/reference.html#latlngbounds
 * @example {start: [0, 0], end: [3500, 7000]}
 */
export type MapBounds = {
    start: [number, number];
    end: [number, number];
};

export const mapBoundsStartingPosition: [number, number] = [0, 0];
