import {convertElement} from './elementConverter';

export const issueConverter = {
    toFirestore: issue => issue,
    fromFirestore: (snapshot, options) => {
        const issue = {...snapshot.data(options), id: snapshot.id};
        convertIssue(issue);
        return issue;
    },
};

export const convertIssue = issue => {
    convertElement(issue.element);
    issue.createdDate = issue.createdDate.toDate();
    issue.lastModified = issue.lastModified.toDate();
    issue.closedDate = issue.closedDate?.toDate();
    issue.history.forEach(change => {
        change.date = change.date.toDate();
    });
    return issue;
};
