import {useContext, useEffect, useMemo, useState} from 'react';
import {MapContainer} from 'react-leaflet';
import Leaflet, {LatLng, BoundsLiteral, LeafletMouseEvent} from 'leaflet';
import {LocalizationPickerButton} from './components/LocalizationPickerButton/LocalizationPickerButton';
import Dialog from '../../../common/components/Dialog/Dialog';
import {useTranslation} from 'react-i18next';
import styles from './LocalizationMapPicker.module.css';
import {getMapMinZoom} from '../../../utils/map/getMapMinZoom';
import {LocalizationMap} from './components/LocalizationMap';
import {Location} from '../../../models/location';
import {downloadPhotos} from '../../../store/action';
import {TYPES} from '../../../constants/error';
import {NotificationContext} from '../../../context/notifications';
import {rescaleMarkerPosition} from '../../../utils/map/rescaleMarkerPosition';
import {IssueLocationMarker} from '../../../models/issue/issue';
import {MAX_MAP_CONTAINER_HEIGHT, MAX_MAP_CONTAINER_WIDTH} from './constant';
import {Button} from '../../../common/components';
import {pencilIcon, trashRedIcon} from '../../../static/images/icons';

type Props = {
    onSave: (position: LatLng) => void;
    onDelete: () => void;
    selectedLocation: Location;
    initialMarker?: IssueLocationMarker;
    initInEditMode?: boolean;
};

export const LocalizationMapPicker = ({
    onSave,
    onDelete,
    selectedLocation,
    initialMarker,
    initInEditMode,
}: Props) => {
    const {width, height} = selectedLocation?.locationMap || {};
    const [open, setOpen] = useState(false);
    const {t} = useTranslation('issues');
    const [savedMarkerPosition, setSavedMarkerPosition] =
        useState<LatLng | null>(null);
    const [temporaryMarkerPosition, setTemporaryMarkerPosition] =
        useState<LatLng | null>(null);
    const [locationMapImageUrl, setLocationMapImageUrl] = useState<string>('');
    const notificationContext = useContext(NotificationContext);
    const [isInEditMode, setIsInEditMode] = useState<boolean>(!!initInEditMode);
    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
        useState<boolean>(false);

    const {zoom, mapBounds, initialMarkerPosition} = useMemo(() => {
        const mapBounds = [
            [0, 0],
            [height, width],
        ] as BoundsLiteral;
        const minimalZoom = getMapMinZoom({
            imageDimensions: {width, height},
            containerDimensions: {
                width: MAX_MAP_CONTAINER_WIDTH,
                height: MAX_MAP_CONTAINER_HEIGHT,
            },
        });

        const initialMarkerPosition = initialMarker
            ? new LatLng(...rescaleMarkerPosition(initialMarker, mapBounds))
            : null;

        return {zoom: minimalZoom, mapBounds, initialMarkerPosition};
    }, [selectedLocation, initialMarker]);

    const handleMapClick = (event: LeafletMouseEvent) => {
        if (isInEditMode) {
            setTemporaryMarkerPosition(event.latlng);
        }
    };

    const downloadLocationMap = async () => {
        try {
            const paths = await downloadPhotos([
                selectedLocation.locationMap.image,
            ]);

            setLocationMapImageUrl(paths[0].uri);
        } catch (error) {
            console.error(error);
            // @ts-ignore TODO remove after adding types to notificationContext
            notificationContext.addNotification({
                type: TYPES.error,
                message: t('mapDialog.imageLoadError'),
            });
        }
    };

    useEffect(() => {
        if (selectedLocation) {
            downloadLocationMap();
        }
    }, [selectedLocation]);

    const onCancelInEditMode = () => {
        setOpen(false);

        setTimeout(() => {
            setTemporaryMarkerPosition(savedMarkerPosition);
            if (!initialMarker && !savedMarkerPosition) {
                setIsInEditMode(true);
            } else {
                setIsInEditMode(false);
            }
        }, 200);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const onSaveInEditMode = () => {
        setOpen(false);
        onSave(temporaryMarkerPosition);
        temporaryMarkerPosition &&
            setSavedMarkerPosition(temporaryMarkerPosition);

        setTimeout(() => {
            setIsInEditMode(false);
        }, 200);
    };

    const onDeleteMarker = () => {
        onDelete();
        setTemporaryMarkerPosition(null);
        setSavedMarkerPosition(null);
        setOpen(false);
        setIsDeleteConfirmationDialogOpen(false);

        setTimeout(() => {
            setIsInEditMode(true);
        }, 200);
    };

    return (
        <>
            <LocalizationPickerButton
                marginTop={10}
                onClick={() => setOpen(true)}
                hasMarker={!!savedMarkerPosition || !!initialMarkerPosition}
                mapUrl={locationMapImageUrl}
            />
            <Dialog
                dialogTitle={t('mapDialog.title')}
                open={open}
                handleClose={
                    isInEditMode
                        ? () => onCancelInEditMode()
                        : () => onCloseModal()
                }
                submitButtonText={t('mapDialog.actionButton')}
                maxWidth="md"
                fullWidth
                onSubmitHandler={() => onSaveInEditMode()}
                dialogTitleRightElement={
                    !isInEditMode ? (
                        <div className={styles.buttonsRow}>
                            <Button
                                startIcon={<img src={pencilIcon} />}
                                variant="outlined"
                                customColor="grey"
                                onClick={() => {
                                    setIsInEditMode(true);
                                }}
                            >
                                {t('mapDialog.editButton')}
                            </Button>
                            <Button
                                startIcon={<img src={trashRedIcon} />}
                                variant="outlined"
                                customColor="error"
                                onClick={() => {
                                    setIsDeleteConfirmationDialogOpen(true);
                                }}
                            >
                                {t('mapDialog.deleteButton')}
                            </Button>
                        </div>
                    ) : null
                }
                cancelButtonVisible={isInEditMode}
                confirmButtonVisible={isInEditMode}
                submitButtonDisabled={
                    !temporaryMarkerPosition ||
                    temporaryMarkerPosition === savedMarkerPosition
                }
            >
                {locationMapImageUrl && (
                    <MapContainer
                        center={[height / 2, width / 2]}
                        scrollWheelZoom={false}
                        className={styles.mapContainer}
                        maxBoundsViscosity={1}
                        zoom={zoom}
                        minZoom={zoom}
                        crs={Leaflet.CRS.Simple}
                        bounds={mapBounds}
                        maxBounds={mapBounds}
                    >
                        <LocalizationMap
                            mapBunds={mapBounds}
                            mapImageUrl={locationMapImageUrl}
                            markerPosition={
                                temporaryMarkerPosition ||
                                savedMarkerPosition ||
                                initialMarkerPosition
                            }
                            onClick={handleMapClick}
                        />
                    </MapContainer>
                )}
                {isDeleteConfirmationDialogOpen && (
                    <Dialog
                        dialogTitle={t('mapDialog.confirmDeleteDialog.title')}
                        open={isDeleteConfirmationDialogOpen}
                        submitButtonText={t(
                            'mapDialog.confirmDeleteDialog.actionButton',
                        )}
                        handleClose={() =>
                            setIsDeleteConfirmationDialogOpen(false)
                        }
                        onSubmitHandler={onDeleteMarker}
                    >
                        <p>{t('mapDialog.confirmDeleteDialog.description')}</p>
                    </Dialog>
                )}
            </Dialog>
        </>
    );
};
