import {BoundsLiteral} from 'leaflet';
import {IssueLocationMarker} from '../../models/issue/issue';

export const rescaleMarkerPosition = (
    markerData: IssueLocationMarker,
    mapBounds: BoundsLiteral,
) => {
    const {bounds, position} = markerData;
    const boundsWidthRatio = mapBounds[1][1] / bounds.end[1];
    const boundsHeightRatio = mapBounds[1][0] / bounds.end[0];

    const finalPosition = [
        parseFloat((position.lat * boundsHeightRatio).toFixed(2)),
        parseFloat((position.lng * boundsWidthRatio).toFixed(2)),
    ] as const;

    return finalPosition;
};
