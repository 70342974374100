import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import styles from './AddElement.module.css';

const AddElement = ({text, onClick, absolute}) => {
    const style = absolute ? styles.absolute : styles.normal;

    return (
        <span
            className={classNames(styles.addElement, style)}
            onClick={onClick}
        >
            <AddIcon className={styles.plusIcon} />
            {text}
        </span>
    );
};

export default AddElement;
