import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import ElementsList from '../../components/Element/ElementsList/ElementsList';
import {mappings} from './ElementsPageRoleMapper';
import {fetchElements, fetchLocations, putNewElement} from '../../store/action';
import {CustomCircularProgress} from '../../common/components/index';
import {
    normalizedStringEquals,
    normalizeStr,
} from '../../utils/StringUtils/StringUtils';
import {withUserRole} from '../../hoc/User/WithUserRole';
import ElementsPageLayout from './ElementsPageLayout';
import {NotificationContext} from '../../context/notifications';
import {
    compareStringWithNumericPrefixes,
    getComparator,
} from '../../utils/sorting/sorting';
import {ELEMENT} from '../../constants/routes';
import getElementsQuery from '../../utils/queryBuilder/ElementsQueryBuilder';
import getLocationsQuery from '../../utils/queryBuilder/LocationQueryBuilder';
import {TYPES} from '../../constants/error';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

class ElementsPage extends Component {
    state = {
        addDialogOpen: false,
        newElementDesciption: '',
        newElementLocation: '',
        newElementName: '',
        searchString: '',
        selectedElement: null,
        selectedLocation: '',
        indicateActivity: true,
        actionPending: false,
    };

    static contextType = NotificationContext;

    unsubscribeElements;

    unsubscribeLocations;

    notificationSystem;

    componentDidMount() {
        this.subscribeOnCollections();
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
            prevProps.selectedBranches.length
        ) {
            this.unsubscribeElements && this.unsubscribeElements();
            this.unsubscribeLocations && this.unsubscribeLocations();
            this.subscribeOnCollections();
        }
    }

    subscribeOnCollections = () => {
        const branchIds = this.props.selectedBranches.map(branch => branch.id);
        this.unsubscribeElements = getElementsQuery(branchIds).onSnapshot(
            elements =>
                this.props.fetchElements(elements, this.setActivityIndicator),
            errorHandler,
        );
        this.unsubscribeLocations = getLocationsQuery(branchIds).onSnapshot(
            this.props.fetchLocations,
            errorHandler,
        );
    };

    componentWillUnmount() {
        this.unsubscribeElements();
        this.unsubscribeLocations();
    }

    render() {
        const elementsList = this.getElementsList();
        this.t = this.props.t;

        return withUserRole(ElementsPageLayout, mappings, {
            onSearchTextChange: this.onSearchTextChange,
            onToggleAddDialogHandler: this.onToggleAddDialogHandler,
            locations: this.props.locations,
            addDialogOpen: this.state.addDialogOpen,
            onElementSubmit: this.onElementSubmit,
            elementsList: elementsList,
            actionPending: this.state.actionPending,
        });
    }

    onSearchTextChange = event => {
        this.setState({searchString: event.target.value});
    };

    filterElements = elements => {
        if (this.state.searchString) {
            elements = elements.filter(element =>
                normalizeStr(element.name).includes(
                    normalizeStr(this.state.searchString),
                ),
            );
        }
        if (this.state.selectedLocation) {
            elements = elements.filter(
                element =>
                    normalizeStr(element.location.name) ===
                    normalizeStr(this.state.selectedLocation),
            );
        }
        return elements;
    };

    sortElements = elements => {
        const comparator = getComparator(
            compareStringWithNumericPrefixes,
            'DESC',
        );

        return [...elements].sort((a, b) => comparator(a.name, b.name));
    };

    onElementClickHandler = elementId => () => {
        this.props.history.push(ELEMENT(elementId));
    };

    onToggleAddDialogHandler = () => {
        this.setState({addDialogOpen: !this.state.addDialogOpen});
    };

    onElementSubmit = (elementToSet, iconFile, filesDropped) => {
        if (this.elementExists(elementToSet)) {
            this.showNotification(
                this.t('notifications.elementExistsError'),
                TYPES.error,
            );
        } else {
            this.setState({actionPending: true});

            this.props
                .putNewElement(elementToSet, iconFile, filesDropped)
                .then(() => {
                    this.setState({actionPending: false});
                    this.showNotification(
                        this.t('notifications.elementAddSuccess'),
                        'success',
                    );
                    this.onToggleAddDialogHandler();
                })
                .catch(error => {
                    errorHandler(error);
                    this.setState({actionPending: false});
                    this.showNotification(
                        this.t('notifications.elementAddError'),
                        TYPES.error,
                    );
                });
        }
    };

    elementExists = element =>
        this.props.elements
            .filter(el => normalizedStringEquals(el.name, element.name))
            .some(el => el.location.id === element.location.id);

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    setActivityIndicator = showIndicator => {
        this.setState({indicateActivity: showIndicator});
    };

    getElementsList = () => {
        const elements = this.filterElements(this.props.elements);
        const sortedElements = this.sortElements(elements);

        return this.state.indicateActivity ? (
            <CustomCircularProgress />
        ) : (
            <ElementsList
                elements={sortedElements}
                onElementClick={this.onElementClickHandler}
            />
        );
    };
}

const mapStateToProps = state => ({
    elements: state.element.elements,
    locations: state.location.locations,
    selectedBranches: state.branch.selectedBranches,
});

const mapDispatchToProps = {
    fetchElements,
    fetchLocations,
    putNewElement,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation('elements')(ElementsPage));
