import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './LogoutButton.module.css';
import logout from '../../static/images/icons/logout-1.svg';

const LogoutButton = props => {
    const {t} = useTranslation('auth');

    return (
        <div
            className={styles.logoutButton}
            style={{color: 'var(--dark-grey)', textTransform: 'none'}}
            onClick={props.logOutHandler}
            color="inherit"
        >
            <img className={styles.draverIcon} alt="" src={logout} />
            {t('logout.name')}
        </div>
    );
};

export default LogoutButton;
