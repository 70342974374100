import {getI18n} from 'react-i18next';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';
import {NoReportDataError} from '../../services/raportGenerator/reportErrors';
import {
    type ReportType,
    generateRaport,
    type ReportFormat,
} from '../../services/raportGenerator/reportGenerator';
import {type UserId} from '../../models/user/user';
import {BranchId, type LocationId} from '../../models/location';
import {type ElementId} from '../../models/element';

export const generateIssueReport = async (
    reportWithActualIssues: boolean,
    startDate: Date,
    endDate: Date,
    showErrorMessage: (message: string) => void,
    userId: UserId,
    branch: {id: string; name: string},
    locationId: LocationId,
    reportType: ReportType,
    elementId: ElementId,
    onFinish: () => void,
    format: ReportFormat,
    hasCategoriesFeature: boolean
) => {
    const {t} = getI18n();

    try {
        await generateRaport({
            subject: 'issue',
            type: reportType,
            format,
            payload: {
                startDate,
                endDate,
                withActualIssues: reportWithActualIssues,
                elementId,
                locationId,
                userId,
                branch,
            },
            hasCategoriesFeature
        });
        onFinish();
    } catch (error) {
        switch (error.constructor) {
            case NoReportDataError:
                showErrorMessage(
                    t('notifications.noResultsForTimeRangeError', {
                        ns: 'reports',
                    }),
                );
                break;
            default:
                showErrorMessage(
                    t('notifications.defaultError', {
                        ns: 'reports',
                    }),
                );
                break;
        }

        errorHandler(error);
        onFinish();
    }
};
