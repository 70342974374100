import getArchivedIssuesQuery from '../utils/queryBuilder/ArchivedIssueQueryBuilder';
import errorHandler from '../common/components/ExceptionReporting/ErrorReporting';
import {nullCategory} from './IssuesService';

class ArchivedIssuesService {
    archivedIssues = [];

    archivedIssuesFromCategories = [];

    subscribeOnArchivedIssuesFromCategory = (
        category,
        index,
        branchIds,
        fetchArchivedIssues,
        setActivityIndicator,
    ) =>
        getArchivedIssuesQuery(branchIds)
            .withCategory(category)
            .onSnapshot(archivedIssues => {
                this.archivedIssuesFromCategories[index] = archivedIssues;
                fetchArchivedIssues(
                    this.archivedIssuesFromCategories.flat(),
                    setActivityIndicator,
                );
            }, errorHandler);

    subscribeOnArchivedIssues = async (
        userData,
        organizationData,
        branchIds,
        setActivityIndicator,
        fetchArchivedIssues,
    ) => {
        if (
            organizationData.isIssuesCategoriesFeatureEnabled &&
            !userData.roles.admin &&
            !userData.roles.reporter
        ) {
            if (userData.categories?.length) {
                this.unsubscribeCategoriesArchivedIssues = [];
                [...userData.categories, nullCategory].map(
                    (category, index) => {
                        this.unsubscribeCategoriesArchivedIssues[index] =
                            this.subscribeOnArchivedIssuesFromCategory(
                                category,
                                index,
                                branchIds,
                                fetchArchivedIssues,
                                setActivityIndicator,
                            );
                    },
                );
            } else {
                setActivityIndicator(false);
            }
        } else {
            this.unsubscribeArchivedIssues = getArchivedIssuesQuery(
                branchIds,
            ).onSnapshot(
                archivedIssues =>
                    fetchArchivedIssues(archivedIssues, setActivityIndicator),
                errorHandler,
            );
        }

        return () => {
            this.unsubscribeArchivedIssues && this.unsubscribeArchivedIssues();
            this.unsubscribeCategoriesArchivedIssues &&
                this.unsubscribeCategoriesArchivedIssues.map(
                    unsubscribeCategoryArchivedIssues =>
                        unsubscribeCategoryArchivedIssues(),
                );
        };
    };
}

export default new ArchivedIssuesService();
