import {ReportIssuesPayload} from '../fetchIssuesData';
import {Issue, ISSUE_TYPE} from '../../../../models/issue/issue';
import moment from 'moment';
import {getIssueFirstInReviewDate} from '../../../../models/issue/issueHistory';
import {getI18n} from 'react-i18next';
import {
    calcDurationsActualIssueHistory,
    calcDurationsArchivedIssueHistory,
} from '../pdf/PDFGenerator';

const getArchivedIssueRows = (
    issue: Issue,
    hasCategoryFeature: boolean,
    issuesType,
): string[] => {
    const {t} = getI18n();
    const {
        branch,
        element,
        issueDescription,
        createdDate,
        solutionDescription,
        history,
        closedDate,
    } = issue;
    const issueFirstInReviewDate = getIssueFirstInReviewDate(history);

    const user = issue.assignedTo
        ? `${issue.assignedTo.name} ${issue.assignedTo.surname}`
        : t('table.cells.nullShort', {ns: 'pdfGenerator'});

    const reactionDuration =
        issuesType === ISSUE_TYPE.archived
            ? calcDurationsArchivedIssueHistory(t, issue.history)
            : calcDurationsActualIssueHistory(t, issue.history);

    const status = t(`statusLabels.${issue.status}`, {
        ns: 'issues',
    });

    if (hasCategoryFeature) {
        return [
            branch?.name,
            element.location.name,
            element.name,
            issueDescription,
            moment(createdDate).format('DD/MM/YYYY HH:mm'),
            issueFirstInReviewDate
                ? moment(issueFirstInReviewDate).format('DD/MM/YYYY HH:mm')
                : '-',
            closedDate ? moment(closedDate).format('DD/MM/YYYY HH:mm') : '-',
            status,
            issue.category?.name === 'null' || !issue.category?.name
                ? '-'
                : issue.category?.name,
            user,
            solutionDescription,
            reactionDuration.reaction,
            reactionDuration.execution,
        ];
    }
    return [
        branch?.name,
        element.location.name,
        element.name,
        issueDescription,
        moment(createdDate).format('DD/MM/YYYY HH:mm'),
        issueFirstInReviewDate
            ? moment(issueFirstInReviewDate).format('DD/MM/YYYY HH:mm')
            : '-',
        closedDate ? moment(closedDate).format('DD/MM/YYYY HH:mm') : '-',
        status,
        user,
        solutionDescription,
        reactionDuration.reaction,
        reactionDuration.execution,
    ];
};

const flattenIssues = (issues: ReportIssuesPayload['archivedIssues']) => {
    const result: Issue[] = [];

    for (const branch in issues) {
        for (const location in issues[branch]) {
            for (const issue of issues[branch][location]) {
                result.push(issue);
            }
        }
    }

    return result;
};

const sanitizeData = (csvDataRow: string[]) => {
    return csvDataRow.map(dataString => {
        return dataString
            .trim()
            .replace(';', ' ')
            .replace(/(\r\n|\n|\r)/gm, ' ');
    });
};

export const getTableDataRows = (
    issues: ReportIssuesPayload,
    hasCategoriesFeature: boolean,
) => {
    const archivedIssues = flattenIssues(issues.archivedIssues);
    const actualIssues = flattenIssues(issues.actualIssues);
    const archivedIssuesRows = archivedIssues.map(issue =>
        getArchivedIssueRows(issue, hasCategoriesFeature, ISSUE_TYPE.archived),
    );
    const actualIssuesRows = actualIssues.map(issue =>
        getArchivedIssueRows(issue, hasCategoriesFeature, ISSUE_TYPE.actual),
    );

    return [...archivedIssuesRows, ...actualIssuesRows].map(sanitizeData);
};
