import React from 'react';
import {CustomCircularProgress} from '../../common/components/index';
import PageControl from '../../components/Users/UsersPageControl/UsersPageControl';
import UsersList from '../../components/Users/UsersList/UsersList';

const UsersPageLayout = ({
    innerComponents: [AddUserDialog],
    roleProps: {showAddButton, linkToDetails},
    props,
}) => (
    <div>
        <PageControl
            onSearchTextChange={props.onSearchTextChange}
            onToggleAddDialogHandler={props.onToggleAddDialogHandler}
            showAddButton={showAddButton}
        />
        {props.addDialogOpen &&
            AddUserDialog({
                handleClose: props.onToggleAddDialogHandler,
                onUserAdded: props.onUserAdded,
                fetchUsers: props.fetchUsers,
            })}
        {props.indicateActivity ? (
            <CustomCircularProgress />
        ) : (
            <UsersList
                disabled={!linkToDetails}
                users={props.users}
                onUserClick={key => props.history.push(`user/${key}`)}
            />
        )}
    </div>
);

export default UsersPageLayout;
