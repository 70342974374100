import React, {ReactNode} from 'react';
import {TextField, TextFieldProps} from '@material-ui/core';
import classNames from 'classnames';
import styles from './CustomTextField.module.css';

type Props = {
    className?: string;
    label: string;
    icon?: ReactNode;
    disabled?: boolean;
    first?: boolean;
    type?: string;
    maxLength?: number;
} & TextFieldProps;

const customTextField = ({
    className,
    label,
    icon,
    disabled,
    first,
    type,
    maxLength,
    ...props
}: Props) => (
    <div
        className={classNames(styles.textFieldContainer, {
            [styles.noTopMargin]: first,
        })}
    >
        {label}
        <div className={styles.textField}>
            {icon}
            <TextField
                margin="normal"
                disabled={disabled}
                className={classNames(styles.textInput, className)}
                InputProps={{
                    disableUnderline: true,
                    rows: 4,
                    // @ts-expect-error Dunno if this works but surly typescript does not like it
                    color: 'var(--dark-grey)',
                }}
                type={type}
                maxLength={maxLength}
                {...props}
            />
        </div>
    </div>
);

export default customTextField;
