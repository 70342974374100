import React from 'react';
import DrawerItems from '../DrawerItems/DrawerItems';
import {withUserRole} from '../../../hoc/User/WithUserRole';
import {drawerItemsForRole} from '../DrawerItems/drawerItemsForRole';
import './NavBar.css';
import {useTranslation} from 'react-i18next';

const NavBar = () => {
    const {t} = useTranslation('sidebar');

    return (
        <div className="navBar">
            {withUserRole(DrawerItems, drawerItemsForRole(t))}
        </div>
    );
};

export default NavBar;
