import {useTranslation} from 'react-i18next';
import {Button, ImageDisplay} from '../../../../../common/components';
import styles from './LocalizationPickerButton.module.css';
import {mapMarkerIcon} from '../../../../../static/images/icons';
import {LocalizationPickerButtonOverlay} from './LocalizationPickerButtonOverlay';

type Props = {
    onClick: () => void;
    marginBottom?: number;
    marginTop?: number;
    hasMarker?: boolean;
    mapUrl?: string;
};

export const LocalizationPickerButton = ({
    onClick,
    marginBottom,
    marginTop,
    hasMarker,
    mapUrl,
}: Props) => {
    const {t} = useTranslation('issues');

    return hasMarker && mapUrl ? (
        <ImageDisplay
            key={mapUrl}
            src={mapUrl}
            onImageClick={onClick}
            deletable={false}
            imgClassName={styles.localizationMapImageDisplay}
            overlayElement={<LocalizationPickerButtonOverlay />}
        />
    ) : (
        <Button
            onClick={onClick}
            customColor="primary"
            startIcon={<img src={mapMarkerIcon} />}
            className={styles.localizationButton}
            marginBottom={marginBottom}
            marginTop={marginTop}
            variant="text"
        >
            {t('details.default.pickLocationButton')}
        </Button>
    );
};
