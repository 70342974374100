import {Location} from './location';
import {File} from './file';

export type ElementId = string;

export type Element = {
    id: ElementId;
    name: string;
    description: string;
    qrCode: string;
    patternImagePath: string | null;
    iconImagePath: string;
    producer: string;
    warrantyTo: string | null;
    createdDate: string;
    location: Location;
    files: File[];
};

export const findElementFromQRCode = (
    qrDataElementId: ElementId,
    elements: Element[],
) => elements.find(element => element.id === qrDataElementId);
