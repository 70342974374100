import axios from '../../config/axios/axiosConfig';
import {
    IMPORT_ENVIRONMENT,
    DELETE_ENVIRONMENT,
    UPDATE_ENVIRONMENT,
} from '../../constants/CloudFunctions';

const IMPORT_TIMEOUT = 300000;

export const sendDataToImport = ({
    environmentName,
    environmentFile,
    reporterCanSeeAllIssues,
    sendEmailWithNotificationToUserAfterAssignToIssue,
    showRecentlyArchivedIssuesOnIssuesList,
    sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate,
    isIssuesCategoriesFeatureEnabled,
    maintainerHasNoPermissionToAssignUsersToIssue,
    supervisorHasAccessToTasks,
    eventBased,
    issueExpiration,
    reporterHasNoPermissionToEditIssueDescription,
    maintainerHasNoPermissionToEditIssueDescription,
    maintainerHasNoPermissionToAddIssue,
    reporterCanSeeIssueSolution,
    maintainerGetPushOnAllIssuesCreated,
    supervisorAndMaintainerGetChangeMail,
    reporterGetChangeMail,
}) => {
    const formData = new FormData();

    formData.append('file', environmentFile);
    formData.append('environmentName', environmentName);
    formData.append('reporterCanSeeAllIssues', +reporterCanSeeAllIssues);
    formData.append(
        'sendEmailWithNotificationToUserAfterAssignToIssue',
        +sendEmailWithNotificationToUserAfterAssignToIssue,
    );
    formData.append(
        'showRecentlyArchivedIssuesOnIssuesList',
        +showRecentlyArchivedIssuesOnIssuesList,
    );
    formData.append(
        'sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate',
        +sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate,
    );
    formData.append(
        'isIssuesCategoriesFeatureEnabled',
        +isIssuesCategoriesFeatureEnabled,
    );
    formData.append(
        'maintainerHasNoPermissionToAssignUsersToIssue',
        +maintainerHasNoPermissionToAssignUsersToIssue,
    );
    formData.append('supervisorHasAccessToTasks', +supervisorHasAccessToTasks);
    formData.append('eventBased', +eventBased);
    formData.append('issueExpiration', +issueExpiration);
    formData.append(
        'reporterHasNoPermissionToEditIssueDescription',
        +reporterHasNoPermissionToEditIssueDescription,
    );
    formData.append(
        'maintainerHasNoPermissionToEditIssueDescription',
        +maintainerHasNoPermissionToEditIssueDescription,
    );
    formData.append(
        'maintainerHasNoPermissionToAddIssue',
        +maintainerHasNoPermissionToAddIssue,
    );
    formData.append(
        'reporterCanSeeIssueSolution',
        +reporterCanSeeIssueSolution,
    );
    formData.append(
        'maintainerGetPushOnAllIssuesCreated',
        +maintainerGetPushOnAllIssuesCreated,
    );

    formData.append(
        'supervisorAndMaintainerGetChangeMail',
        +supervisorAndMaintainerGetChangeMail,
    );

    formData.append('reporterGetChangeMail', +reporterGetChangeMail);

    return axios.post(IMPORT_ENVIRONMENT, formData, {timeout: IMPORT_TIMEOUT});
};

export const sendDataToUpdate = ({
    oldEnvironmentName,
    newEnvironmentName,
    environmentFile,
    reporterCanSeeAllIssues,
    sendEmailWithNotificationToUserAfterAssignToIssue,
    showRecentlyArchivedIssuesOnIssuesList,
    sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate,
    isIssuesCategoriesFeatureEnabled,
    maintainerHasNoPermissionToAssignUsersToIssue,
    supervisorHasAccessToTasks,
    eventBased,
    issueExpiration,
    reporterHasNoPermissionToEditIssueDescription,
    maintainerHasNoPermissionToEditIssueDescription,
    maintainerHasNoPermissionToAddIssue,
    reporterCanSeeIssueSolution,
    maintainerGetPushOnAllIssuesCreated,
    supervisorAndMaintainerGetChangeMail,
    reporterGetChangeMail,
    overwriteEnvironment,
}) => {
    const formData = new FormData();

    environmentFile && formData.append('file', environmentFile);
    formData.append('oldEnvironmentName', oldEnvironmentName);
    formData.append('newEnvironmentName', newEnvironmentName);
    formData.append('reporterCanSeeAllIssues', +reporterCanSeeAllIssues);
    formData.append(
        'sendEmailWithNotificationToUserAfterAssignToIssue',
        +sendEmailWithNotificationToUserAfterAssignToIssue,
    );
    formData.append(
        'showRecentlyArchivedIssuesOnIssuesList',
        +showRecentlyArchivedIssuesOnIssuesList,
    );
    formData.append(
        'sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate',
        +sendEmailWithNotificationToAdminsAndSupervisorsOnIssueCreate,
    );
    formData.append(
        'isIssuesCategoriesFeatureEnabled',
        +isIssuesCategoriesFeatureEnabled,
    );
    formData.append(
        'maintainerHasNoPermissionToAssignUsersToIssue',
        +maintainerHasNoPermissionToAssignUsersToIssue,
    );
    formData.append('supervisorHasAccessToTasks', +supervisorHasAccessToTasks);
    formData.append('eventBased', +eventBased);
    formData.append('issueExpiration', +issueExpiration);
    formData.append(
        'reporterHasNoPermissionToEditIssueDescription',
        +reporterHasNoPermissionToEditIssueDescription,
    );
    formData.append(
        'maintainerHasNoPermissionToEditIssueDescription',
        +maintainerHasNoPermissionToEditIssueDescription,
    );
    formData.append(
        'maintainerHasNoPermissionToAddIssue',
        +maintainerHasNoPermissionToAddIssue,
    );
    formData.append(
        'reporterCanSeeIssueSolution',
        +reporterCanSeeIssueSolution,
    );
    formData.append(
        'maintainerGetPushOnAllIssuesCreated',
        +maintainerGetPushOnAllIssuesCreated,
    );

    formData.append(
        'supervisorAndMaintainerGetChangeMail',
        +supervisorAndMaintainerGetChangeMail,
    );

    formData.append('reporterGetChangeMail', +reporterGetChangeMail);

    formData.append('overwriteEnvironment', +overwriteEnvironment);

    return axios.post(UPDATE_ENVIRONMENT, formData, {timeout: IMPORT_TIMEOUT});
};

export const deleteEnvironment = environment =>
    axios.delete(DELETE_ENVIRONMENT, {params: {environment}});
