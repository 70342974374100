import React, {Component} from 'react';
import styles from './List.module.css';

class mobileList extends Component {
    state = {
        openedFilterNumber: -1,
    };

    customComparator;

    render() {
        const {columns, rows, rowTemplate} = this.props;
        return (
            <div
                className={`${styles.list} ${this.props.className} ${styles.displayMobile}`}
            >
                {columns.length ? (
                    <div>{rows.map(row => rowTemplate(row))}</div>
                ) : (
                    'Loading...'
                )}
            </div>
        );
    }

    onFilterIconClick = index => () => {
        this.setState(prevState => ({
            openedFilterNumber:
                prevState.openedFilterNumber === index ? -1 : index,
        }));
    };

    descSortByDate = cards => {
        cards.sort((row, nextRow) => nextRow.createdDate - row.createdDate);
    };
}

export default mobileList;
