import {MapBounds} from '../../utils/map/type';
import {Branch} from '../branch';
import {Category} from '../category';
import {Element} from '../element';
import {UserRole} from '../user/roles';
import {UserId} from '../user/user';
import {IssueHistoryEntry} from './issueHistory';

export const ISSUE_PRIORITY = {
    high: 'high',
    medium: 'medium',
    low: 'low',
} as const;

export type IssuePriority =
    (typeof ISSUE_PRIORITY)[keyof typeof ISSUE_PRIORITY];

export const ISSUE_STATUS = {
    open: 'open',
    inProgress: 'inProgress',
    inReview: 'inReview',
    done: 'done',
} as const;

export type IssueStatus = (typeof ISSUE_STATUS)[keyof typeof ISSUE_STATUS];

export const ISSUE_TYPE = {
    actual: 'actual_issue',
    archived: 'archived_issue',
} as const;

export type IssueUser = {
    name: string;
    surname: string;
    uid: UserId;
    roles: Record<UserRole, boolean>;
};

type IssueId = string;

export type IssueLocationMarker = {
    bounds: MapBounds;
    position: {lat: number; lng: number};
};

export type Issue = {
    id: IssueId;
    key: string;
    issueDescription: string;
    solutionDescription: string;
    solutionImagePaths: string[];
    issueImagePaths: string[];
    status: IssueStatus;
    priority: IssuePriority;
    branch: Branch;
    element: Element;
    category: Category;
    reporter: IssueUser;
    assignedTo: IssueUser | null;
    createdDate: Date;
    lastModified: Date;
    statusOnLastNotification: IssueStatus;
    history: IssueHistoryEntry[];
    outDated: boolean;
    closedDate: Date | null;
    locationMarker?: IssueLocationMarker | null;
    phoneNumber: string | null;
};
