import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import Dialog from '../../../common/components/Dialog/Dialog';
import {CustomTextField, ImageDropzone} from '../../../common/components/index';
import BranchPicker from '../../../common/components/branchPicker/BranchPicker';
import {TYPES} from '../../../constants/error';
import {MAX_LOCATION_MAP_SIZE} from '../../../models/location';
import {getBrowserImageDimensions} from '../../../utils/getBrowserImageSize';

class AddLocationDialog extends Component {
    state = {
        newLocationName: '',
        branch: null,
        dialogRef: null,
        imagePickerMapSource: null,
        locationMap: null,
    };

    constructor(props) {
        super(props);
        if (props.branches.length === 1) {
            this.state.branch = this.props.branches[0];
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.branches.length && this.props.branches.length === 1) {
            this.setState({branch: this.props.branches[0]});
        }
    }

    getLocationMapObject = async () => {
        const {locationMap} = this.state;

        if (!locationMap) {
            return null;
        }

        const imageSize = await getBrowserImageDimensions(locationMap);

        return {
            image: '',
            width: imageSize.width,
            height: imageSize.height,
            size: locationMap.size,
            name: locationMap.name,
        };
    };

    onSubmitNewLocationHandler = async () => {
        const {
            branch,
            newLocationName,
            locationMap: locationMapFile,
        } = this.state;
        const locationMap = await this.getLocationMapObject();
        const locationToSet = {
            name: newLocationName.trim(),
            branch,
            locationMap,
        };
        this.props.onAddLocation(locationToSet, locationMapFile);
        this.props.handleClose();
    };

    onRemoveLocationMapImage = () => {
        this.setState({locationMap: null, imagePickerMapSource: null});
    };

    onLocationMapImageDrop = files => {
        const {t} = this.props;
        const singleImage = files[0];
        const isFileTooLarge = singleImage.size > MAX_LOCATION_MAP_SIZE;

        if (isFileTooLarge) {
            this.props.showNotification(
                t('notifications.dialog.tooLargePhotoError'),
                TYPES.error,
            );
        }

        this.setState({
            locationMap: singleImage,
            imagePickerMapSource: URL.createObjectURL(singleImage),
        });
    };

    getTextField(topLabel, onChange) {
        return (
            <CustomTextField label={topLabel} onChange={onChange} fullWidth />
        );
    }

    onLocationNameChange = event => {
        this.setState({newLocationName: event.target.value});
    };

    onBranchChange = ({value, label}) => {
        this.setState({branch: {id: value, name: label}});
    };

    render() {
        const {dialogTitle, handleClose} = this.props;
        const {newLocationName, branch, imagePickerMapSource} = this.state;
        const submitButtonDisabled = !newLocationName || !branch;
        const {t} = this.props;

        return (
            <Dialog
                ref={dialogRef =>
                    !this.state.dialogRef && this.setState({dialogRef})
                }
                loading={false}
                handleClose={handleClose}
                dialogTitle={dialogTitle}
                submitButtonDisabled={submitButtonDisabled}
                onSubmitHandler={this.onSubmitNewLocationHandler}
                submitButtonText={dialogTitle}
            >
                <BranchPicker
                    handleChange={this.onBranchChange}
                    selectedBranch={branch}
                    branches={this.props.branches}
                    menuPortalTarget={this.state.dialogRef}
                />
                {this.getTextField(
                    this.props.organizationData.eventBased
                        ? t('dialog.eventBased.name')
                        : t('dialog.default.name'),
                    this.onLocationNameChange,
                )}
                {this.props.organizationData.isIssueLocalizationEnabled && (
                    <ImageDropzone
                        label={t('details.locationMap')}
                        multiple={false}
                        onDrop={this.onLocationMapImageDrop}
                        imagesSrc={[imagePickerMapSource].filter(Boolean)}
                        onRemoveImage={this.onRemoveLocationMapImage}
                    />
                )}
            </Dialog>
        );
    }
}

const mapStateToProps = store => ({
    branches: store.branch.branches,
    organizationData: store.auth.organizationData,
});

export default connect(mapStateToProps)(
    withTranslation('locations')(AddLocationDialog),
);
