import React from 'react';
import {components} from 'react-select';
import {useTranslation} from 'react-i18next';
import styles from './componentsForPicker.module.css';

export const Option = props => (
    <div>
        <components.Option {...props}>
            <input
                className={styles.checkbox}
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />
            <label className={styles.label}>{props.label}</label>
        </components.Option>
    </div>
);

export const ValueContainer = ({children, ...props}) => {
    const currentValues = props.getValue();
    let toBeRendered = children;
    if (currentValues.length > 1) {
        toBeRendered = [[children[0][0]], children[1]];
    }
    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
        </components.ValueContainer>
    );
};

export const MultiValue = props => {
    let labelToBeDisplayed = props.data.label;
    const {t} = useTranslation('common');

    if (props.options.length === props.selectProps.value.length) {
        labelToBeDisplayed = t('multiValue.all');
    } else if (props.selectProps.value.length > 1) {
        labelToBeDisplayed = t('multiValue.many');
    }
    return (
        <components.MultiValue {...props}>
            <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
    );
};
