import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {CustomTextField} from '../../../common/components';
import Dialog from '../../../common/components/Dialog/Dialog';

class EditBranchDialog extends Component {
    state = {
        name: '',
    };

    constructor(props) {
        super(props);
        this.state.name = props.branch.name;
    }

    render() {
        const {t, dialogTitle, handleClose, branch, loading} = this.props;
        const {name} = this.state;
        const submitButtonDisabled =
            this.state.name.trim() === branch.name ||
            !name.trim().length ||
            loading;

        return (
            <Dialog
                loading={loading}
                handleClose={handleClose}
                dialogTitle={dialogTitle}
                submitButtonDisabled={submitButtonDisabled}
                onSubmitHandler={this.onUpdateBranchHandler}
            >
                <CustomTextField
                    label={
                        this.props.organizationData.eventBased
                            ? t('dialog.eventBased.name')
                            : t('dialog.default.name')
                    }
                    placeholder={
                        this.props.organizationData.eventBased
                            ? t('dialog.eventBased.namePlaceholder')
                            : t('dialog.default.namePlaceholder')
                    }
                    onChange={this.onBranchNameChangeHandler}
                    value={name}
                    fullWidth
                />
            </Dialog>
        );
    }

    onBranchNameChangeHandler = event => {
        this.setState({name: event.target.value});
    };

    onUpdateBranchHandler = () => {
        const branchToSet = {
            name: this.state.name.trim(),
        };
        this.props.onUpdateBranch(branchToSet);
    };
}

const mapStateToProps = state => ({
    organizationData: state.auth.organizationData,
});

export default connect(mapStateToProps)(
    withTranslation('branches')(EditBranchDialog),
);
