import React from 'react';
import ReactDOM from 'react-dom';

import html2canvas from 'html2canvas';
import QrCodeWithText from '../../components/QrCodeWithText/QrCodeWithText';

export const generateAndDownloadQR = async ({
    content,
    fileName,
    locationName,
    elementName,
}) => {
    try {
        const qrCodeContainer = document.createElement('div');
        qrCodeContainer.style.position = 'absolute';
        document.body.appendChild(qrCodeContainer);
        ReactDOM.render(
            <QrCodeWithText
                content={content}
                locationName={locationName}
                elementName={elementName}
            />,
            qrCodeContainer,
        );

        html2canvas(qrCodeContainer, {useCORS: true}).then(canvas => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = `${fileName}.png`;
            link.click();
            document.body.removeChild(qrCodeContainer);
        });
    } catch (err) {
        throw err;
    }
};
