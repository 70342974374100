import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import firebase from 'firebase/app';
import {LOGIN} from '../../constants/routes';
import {
    authSetUserData,
    fetchUserData,
    setUserSelectedBranches,
    fetchOrganizationData,
    authSetOrganizationData,
    fetchBranches,
} from '../../store/action';
import {getUserRole} from '../../store/action/authHelpers';
import {
    saveUserData,
    getSelectedBranchesFromStorage,
} from '../../storage/localStorage';
import {setScannedElement} from '../../store/action/qrCode';
import * as endpoints from '../../constants/endpoints';

const withAuthentication = (
    Component,
    redirect,
    allowedRoles,
    props = null,
) => {
    class WithAuthentication extends React.Component {
        componentDidMount() {
            this.saveQrCodeIfExists();
            firebase.auth().onAuthStateChanged(async user => {
                if (user) {
                    const userDoc = await fetchUserData(user.uid);
                    const userData = userDoc.data();

                    saveUserData(userData);

                    const selectedBranches = getSelectedBranchesFromStorage();

                    if (selectedBranches) {
                        this.props.setUserSelectedBranches(selectedBranches);
                    } else if (!this.props.selectedBranches.length) {
                        this.props.setUserSelectedBranches(userData.branches);
                    }

                    const organizationDoc = await fetchOrganizationData(
                        userData.organization,
                    );
                    const organizationData = organizationDoc.data();

                    const branchesDoc = await this.fetchBranches().catch(
                        () => {},
                    );
                    if (branchesDoc) {
                        this.props.fetchBranches(branchesDoc);
                    }

                    this.props.authSetOrganizationData(organizationData);
                    this.props.setUserData(userData || {});
                }

                if (!user && redirect) {
                    this.saveQrCodeIfExists();
                    this.props.history.push(LOGIN);
                }
            });
        }

        fetchBranches = async () =>
            firebase.firestore().collection(endpoints.branches()).get();

        shouldDisplayComponent = () => {
            const {userData} = this.props;
            return userData && allowedRoles.includes(getUserRole(userData));
        };

        saveQrCodeIfExists() {
            const params = new URLSearchParams(this.props.location.search);
            const searchParams = Object.fromEntries(params.entries());
            const {locationId, elementId} = searchParams;
            if (locationId && elementId) {
                this.props.setScannedElement({
                    locationId,
                    id: elementId,
                });
            }
        }

        render() {
            return this.shouldDisplayComponent() ? (
                <Component {...this.props} {...props} />
            ) : (
                <div />
            );
        }
    }

    const mapStateToProps = state => ({
        userData: state.auth.userData,
        selectedBranches: state.branch.selectedBranches,
        branches: state.branch.branches,
    });

    const mapDispatchToProps = dispatch => ({
        setUserData: userData => dispatch(authSetUserData(userData)),
        setUserSelectedBranches: userBranches =>
            dispatch(setUserSelectedBranches(userBranches)),
        fetchBranches: dispatch(fetchBranches),
        authSetOrganizationData: organizationData =>
            dispatch(authSetOrganizationData(organizationData)),
        setScannedElement: element => dispatch(setScannedElement(element)),
    });

    return withRouter(
        connect(mapStateToProps, mapDispatchToProps)(WithAuthentication),
    );
};

export default withAuthentication;
