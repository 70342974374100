import {Organization} from '../../models/organization';
import {UserDetails} from '../../models/user/user';
import {
    AUTH_SET_USER_DATA,
    AUTH_SET_ORGANIZATION_DATA,
} from '../action/actionTypes';

export type AuthState = {
    userData: null | UserDetails;
    organizationData: Organization;
};

const initialState: AuthState = {
    userData: null,
    organizationData: {
        reporterCanSeeAllIssues: false,
        showRecentlyArchivedIssuesOnIssuesList: false,
        isIssuesCategoriesFeatureEnabled: true,
        maintainerHasNoPermissionToAssignUsersToIssue: false,
        supervisorHasAccessToTasks: false,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SET_USER_DATA:
            return {
                ...state,
                userData: action.userData,
            };
        case AUTH_SET_ORGANIZATION_DATA:
            return {
                ...state,
                organizationData: action.organizationData,
            };
        default:
            return state;
    }
};

export default reducer;
