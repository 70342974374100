import React from 'react';
import {useTranslation} from 'react-i18next';
import Picker from '../picker/Picker';
import {allRoles, labels} from '../../../constants/roles';

const RolePicker = props => {
    const items = allRoles.map(role => ({value: role, label: labels[role]}));

    const selectedValue = props.selectedRole && {
        value: props.selectedRole,
        label: labels[props.selectedRole],
    };

    const {t} = useTranslation('common');

    return (
        <Picker
            label={t('rolePicker.label')}
            selectedValue={selectedValue}
            pickerItems={items}
            name="Rola"
            first={props.first}
            placeholder={t('rolePicker.placeholder')}
            handleChange={props.handleChange}
            searchable={false}
            className={props.className}
            disabled={props.disabled}
            menuPlacement={props.menuPlacement}
            menuPortalTarget={props.menuPortalTarget}
        />
    );
};

RolePicker.defaultProps = {
    bottomLabelActive: true,
};

export default RolePicker;
