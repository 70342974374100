import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {CustomTextField} from '../../../common/components';
import Dialog from '../../../common/components/Dialog/Dialog';
import MultiUserPicker from '../../Tasks/multiUserPicker/MultiUserPicker';

class EditCategoryDialog extends Component {
    state = {
        name: '',
        usersAlreadyInCategory: [],
    };

    constructor(props) {
        super(props);
        this.state.name = props.category.name;
        this.state.usersAlreadyInCategory = props.users.filter(user =>
            props.category.uids.includes(user.uid),
        );
    }

    componentDidMount() {
        this.props.setSelectedUsers(this.state.usersAlreadyInCategory);
    }

    onCategoryNameChangeHandler = event => {
        this.setState({name: event.target.value});
    };

    onUpdateCategoryHandler = () => {
        const categoryToSet = {
            name: this.state.name.trim(),
            uids: this.props.selectedUsers.map(user => user.uid),
        };
        this.props.onUpdateCategory(
            this.props.category,
            categoryToSet,
            this.state.usersAlreadyInCategory,
            this.props.selectedUsers,
        );
    };

    isCategoryNameNotChanged = () =>
        this.state.name.trim() === this.props.category.name;

    areCategoryUsersNotChanged = () =>
        this.props.selectedUsers.length ===
            this.state.usersAlreadyInCategory.length &&
        this.props.selectedUsers.every(selectedUser =>
            this.state.usersAlreadyInCategory.includes(selectedUser),
        );

    render() {
        const {t, dialogTitle, handleClose, loading} = this.props;
        const {name} = this.state;
        const submitButtonDisabled =
            (this.isCategoryNameNotChanged() &&
                this.areCategoryUsersNotChanged()) ||
            !name.trim().length ||
            loading;

        return (
            <Dialog
                loading={loading}
                handleClose={handleClose}
                dialogTitle={dialogTitle}
                submitButtonDisabled={submitButtonDisabled}
                onSubmitHandler={this.onUpdateCategoryHandler}
            >
                <CustomTextField
                    label={t('dialog.name')}
                    placeholder={t('dialog.namePlaceholder')}
                    onChange={this.onCategoryNameChangeHandler}
                    value={name}
                    fullWidth
                />
                <div style={{height: '500px'}}>
                    <MultiUserPicker
                        users={this.props.users}
                        selectedUsers={this.props.selectedUsers}
                        setSelectedUsers={this.props.setSelectedUsers}
                        menuPlacement="bottom"
                        searchable
                        label={t('dialog.users')}
                        maxMenuHeight="400px"
                    />
                </div>
            </Dialog>
        );
    }
}

export default withTranslation('categories')(EditCategoryDialog);
