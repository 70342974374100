import React from 'react';
import AppBar from './AppBar/AppBar';

const layout = props => (
    <div>
        <AppBar withBurger={props.withBurger}>{props.children}</AppBar>
    </div>
);

export default layout;
