import React from 'react';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../common/components/Dialog/Dialog';
import TaskForm from '../../../containers/forms/task/TaskForm';

const CreateNewTaskLayout = props => {
    const {t} = useTranslation('tasks');

    return (
        <Dialog
            loading={props.loading}
            handleClose={props.onClose}
            dialogTitle={t('dialog.title')}
            submitButtonDisabled={props.loading || props.submitButtonDisabled}
            onSubmitHandler={props.onSubmitNewTaskHandler}
            submitButtonText={t('dialog.submitBtn')}
        >
            <TaskForm
                elements={props.elements}
                locations={props.locations}
                categories={props.categories}
                isIssuesCategoriesFeatureEnabled={
                    props.isIssuesCategoriesFeatureEnabled
                }
                users={props.users}
                setSubmitButtonDisabled={props.setAddButtonDisabled}
                setFormState={props.setFormState}
                noTopMarginOnFirstInput
            />
        </Dialog>
    );
};

export default CreateNewTaskLayout;
