import firebase from 'firebase/app';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import CategoriesPageLayout from './CategoriesPageLayout';
import * as endpoints from '../../constants/endpoints';
import {TYPES} from '../../constants/error';
import {
    addCategory,
    addCategoryToUsers,
    deleteCategory,
    deleteCategoryFromUsers,
    updateCategory,
    updateCategoryNameInIssues,
    updateCategoryNameInUsers,
} from '../../utils/category/category';
import {NotificationContext} from '../../context/notifications';
import {fetchCategories} from '../../store/action/category';
import getUsersQuery from '../../utils/queryBuilder/UsersQueryBuilder';
import {setUsers} from '../../store/action';
import {compareCategoryWithQueryString} from '../../utils/filtering/filtering';
import {roles as ROLES} from '../../constants/roles';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

class CategoriesPage extends Component {
    state = {
        searchString: '',
        addDialogOpen: false,
        editDialogOpen: false,
        listLoading: true,
        actionPending: false,
        categorySelectedForEdit: null,
        selectedUsers: [],
    };

    static contextType = NotificationContext;

    unsubscribeCategories;

    categoriesReference;

    notificationSystem = null;

    constructor(props) {
        super(props);
        this.categoriesReference = firebase
            .firestore()
            .collection(endpoints.categories());
    }

    componentDidMount() {
        this.unsubscribeCategories = this.categoriesReference
            .orderBy('name')
            .onSnapshot(
                snapshot =>
                    this.props.fetchCategories(
                        snapshot,
                        this.setActivityIndicator,
                    ),
                error =>
                    errorHandler(error, 'Error during fetching categories'),
            );
        this.fetchUsers();
        this.notificationSystem = this.context;
    }

    componentWillUnmount() {
        this.unsubscribeCategories && this.unsubscribeCategories();
    }

    onSearchTextChange = event => {
        this.setState({searchString: event.target.value});
    };

    onToggleAddDialogHandler = () => {
        this.setState({addDialogOpen: !this.state.addDialogOpen});
    };

    onToggleEditDialogHandler = () => {
        this.setState({editDialogOpen: !this.state.editDialogOpen});
    };

    setActivityIndicator = loading => {
        this.setState({listLoading: loading});
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    branchExists = branch =>
        this.props.branches.some(
            br => br.name.toUpperCase() === branch.name.toUpperCase(),
        );

    categoryExists = _category =>
        this.props.categories.some(
            category =>
                category.name.toUpperCase() === _category.name.toUpperCase(),
        );

    setSelectedUsers = selectedUsers => {
        this.setState({selectedUsers});
    };

    onSubmitHandler = category => {
        if (this.categoryExists(category)) {
            this.showNotification(
                this.t('notifications.add.elementExistsError'),
                TYPES.error,
            );
        } else {
            this.setState({actionPending: true});
            addCategory(category)
                .then(async doc => {
                    if (category.uids.length) {
                        await addCategoryToUsers(
                            {id: doc.id, name: category.name},
                            category.uids,
                        );
                    }
                })
                .then(() => {
                    this.setState({actionPending: false});
                    this.onToggleAddDialogHandler();
                    this.showNotification(
                        this.t('notifications.add.elementAddSuccess'),
                        'success',
                    );
                })
                .catch(error => {
                    errorHandler(error, 'Error during adding category');
                    this.showNotification(
                        this.t('notifications.add.elementAddError'),
                        TYPES.error,
                    );
                });
        }
    };

    fetchUsers() {
        this.setActivityIndicator(true);
        const rolesToFetchUsersWith = [ROLES.MAINTAINER, ROLES.SUPERVISOR];
        getUsersQuery()
            .withRoles(rolesToFetchUsersWith)
            .get()
            .then(users => {
                this.props.setUsers(users);
                this.setActivityIndicator(false);
            })
            .catch(error => {
                this.showNotification(
                    this.t('notifications.usersLoadError'),
                    TYPES.error,
                );
            });
    }

    filterCategories = categories => {
        if (this.state.searchString) {
            return categories.filter(category =>
                compareCategoryWithQueryString(
                    category,
                    this.state.searchString,
                ),
            );
        }
        return categories;
    };

    onCategoryClickHandler = elementId => () => {
        const category = this.props.categories.find(
            category => category.id === elementId,
        );
        this.setState({
            categorySelectedForEdit: category,
            editDialogOpen: true,
        });
    };

    onUpdateCategory = (
        oldCategory,
        categoryToSet,
        usersAlreadyInCategory,
        selectedUsers,
    ) => {
        if (
            oldCategory.name !== categoryToSet.name &&
            this.categoryExists(categoryToSet)
        ) {
            this.showNotification(
                this.t('notifications.edit.elementExistsError'),
                TYPES.error,
            );
        } else {
            this.setState({actionPending: true});
            const usersToDeleteFromCategory = usersAlreadyInCategory.filter(
                userAlreadyInCategory =>
                    !selectedUsers.includes(userAlreadyInCategory),
            );

            const usersToAddToCategory = selectedUsers.filter(
                selectedUser => !usersAlreadyInCategory.includes(selectedUser),
            );
            updateCategory(oldCategory.id, categoryToSet)
                .then(() =>
                    addCategoryToUsers(
                        {id: oldCategory.id, name: categoryToSet.name},
                        usersToAddToCategory.map(
                            userToAddToCategory => userToAddToCategory.uid,
                        ),
                    ),
                )
                .then(() =>
                    deleteCategoryFromUsers(
                        {id: oldCategory.id, name: categoryToSet.name},
                        usersToDeleteFromCategory.map(
                            userToDeleteFromCategory =>
                                userToDeleteFromCategory.uid,
                        ),
                    ),
                )
                .then(() => {
                    if (oldCategory.name !== categoryToSet.name) {
                        return updateCategoryNameInUsers(
                            {id: oldCategory.id, name: oldCategory.name},
                            {id: oldCategory.id, name: categoryToSet.name},
                            selectedUsers
                                .filter(selectedUser =>
                                    usersAlreadyInCategory.includes(
                                        selectedUser,
                                    ),
                                )
                                .map(user => user.uid),
                        );
                    }
                })
                .then(() => {
                    if (oldCategory.name !== categoryToSet.name) {
                        return updateCategoryNameInIssues(
                            oldCategory,
                            categoryToSet,
                        );
                    }
                })
                .then(() => {
                    this.setState({actionPending: false});
                    this.onToggleEditDialogHandler();
                    this.showNotification(
                        this.t('notifications.edit.elementUpdateSuccess'),
                        'success',
                    );
                })
                .catch(error => {
                    errorHandler(error, 'Error during updating category');
                    this.showNotification(
                        this.t('notifications.edit.elementUpdateError'),
                        TYPES.error,
                    );
                });
        }
    };

    onDeleteCategory = async category => {
        this.setState({actionPending: true});

        deleteCategory(category)
            .then(() =>
                deleteCategoryFromUsers(
                    {id: category.id, name: category.name},
                    category.uids,
                ),
            )
            .then(() => {
                this.showNotification(
                    this.t('notifications.delete.elementDeleteSuccess'),
                    TYPES.success,
                );
            })
            .catch(error => {
                errorHandler(error);
                this.showNotification(
                    this.t('notifications.delete.elementDeleteError'),
                    TYPES.error,
                );
            })
            .then(() => {
                this.setState({actionPending: false});
            });
    };

    render() {
        this.t = this.props.t;

        return this.props.organizationData.isIssuesCategoriesFeatureEnabled ? (
            CategoriesPageLayout({
                props: {
                    t: this.props.t,
                    addDialogOpen: this.state.addDialogOpen,
                    editDialogOpen: this.state.editDialogOpen,
                    categorySelectedForEdit: this.state.categorySelectedForEdit,
                    onSearchTextChange: this.onSearchTextChange,
                    categories: this.filterCategories(this.props.categories),
                    onToggleAddDialogHandler: this.onToggleAddDialogHandler,
                    onToggleEditDialogHandler: this.onToggleEditDialogHandler,
                    listLoading: this.state.listLoading,
                    actionPending: this.state.actionPending,
                    onSubmitHandler: this.onSubmitHandler,
                    onCategoryClickHandler: this.onCategoryClickHandler,
                    onUpdateCategory: this.onUpdateCategory,
                    onDeleteCategory: this.onDeleteCategory,
                    users: this.props.users,
                    selectedUsers: this.state.selectedUsers,
                    setSelectedUsers: this.setSelectedUsers,
                },
            })
        ) : (
            <div />
        );
    }
}

const mapStateToProps = state => ({
    organizationData: state.auth.organizationData,
    categories: state.category.categories,
    users: state.user.users,
});

const mapDispatchToProps = dispatch => ({
    fetchCategories: dispatch(fetchCategories),
    setUsers: users => dispatch(setUsers(users)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation('categories')(CategoriesPage));
