import {getUserDataFromStorage} from '../../storage/localStorage';
import {roles as userRoles} from '../../constants/roles';

export const getUserRole = userData => {
    const data = userData || getUserDataFromStorage();
    const roles = data ? data.roles : {};
    let role = userRoles.REPORTER;
    const keys = Object.keys(roles);
    keys.forEach(key => {
        if (roles[key]) {
            role = userRoles[key.toUpperCase()];
        }
    });
    return role;
};
