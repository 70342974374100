import firebase from 'firebase/app';
import {elements} from '../../constants/endpoints';
import {getDownloadUrl} from '../storageUtils/storageUtils';
import {elementConverter} from '../converter/elementConverter';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

export const defaultConverter = {
    toFirestore: item => item,
    fromFirestore: (snapshot, options) => snapshot.data(options),
};

const doElementAction = (callback, documentId) => element => {
    if (element.patternImagePath || element.files) {
        const promises = [];
        element.iconImagePath &&
            promises.push(getDownloadUrl(element.iconImagePath));
        element.patternImagePath &&
            promises.push(getDownloadUrl(element.patternImagePath));
        element.files &&
            element.files.forEach(file =>
                promises.push(getDownloadUrl(file.path)),
            );
        Promise.all(promises)
            .then(([...uris]) => {
                const elementToSet = {...element, id: documentId};
                if (element.iconImagePath) {
                    let filesUris = [];
                    [
                        elementToSet.iconUri,
                        elementToSet.photoUri,
                        ...filesUris
                    ] = uris;
                    filesUris.forEach(
                        (file, index) => (elementToSet.files[index].uri = file),
                    );
                } else if (!element.iconImagePath) {
                    const filesUris = [...uris];
                    filesUris.forEach(
                        (file, index) => (elementToSet.files[index].uri = file),
                    );
                }
                callback({
                    ...elementToSet,
                });
            })
            .catch(err => {
                callback({
                    ...element,
                    id: documentId,
                });
                errorHandler(err);
            });
    } else {
        callback({
            ...element,
            key: documentId,
            id: documentId,
        });
    }
};

export function fetchDocumentById(
    collection,
    documentId,
    converter = defaultConverter,
) {
    return firebase
        .firestore()
        .collection(collection)
        .doc(documentId)
        .withConverter(converter);
}

export function getItemFromDocumentAndPassToCallback(document, callback) {
    document
        .get()
        .then(doc => {
            if (doc.exists) {
                const item = {...doc.data(), key: doc.id};
                if (item) {
                    callback(item);
                }
            }
        })
        .catch(error => {
            errorHandler(error);
        });
}

export const fetchElementByDocumentId = (documentId, callback) => {
    const elementDocument = fetchDocumentById(
        elements(),
        documentId,
        elementConverter,
    );
    getItemFromDocumentAndPassToCallback(
        elementDocument,
        doElementAction(callback, documentId),
    );
};

export const checkIfElementsExists = documentId =>
    firebase
        .firestore()
        .collection(elements())
        .get()
        .then(snapshot =>
            snapshot.docs.some(doc => doc.data().location?.id === documentId),
        )
        .catch(errorHandler);
