import React from 'react';
import * as moment from 'moment';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
    List,
    ListItem,
    ListColumnCreator,
    IOSSwitch,
    MobileList,
    ListItemMobile,
    MobileHeader,
    MobileDataDisplay,
} from '../../../common/components/index';
import CyclicIcon from './display/cyclicIcon/cyclicIcon';
import {maintainerComparator} from '../../../utils/sorting/sorting';
import Elements from './display/element/elements';

const TasksList = props => {
    const {t} = useTranslation('tasks');

    const eventBased = useSelector(
        state => state.auth.organizationData.eventBased,
    );

    const columns = [
        new ListColumnCreator('name', t('table.columns.default.name'), {}),
        new ListColumnCreator(
            'startDate',
            t('table.columns.default.deadline'),
            {},
        ),
        new ListColumnCreator(
            'elements',
            eventBased
                ? t('table.columns.eventBased.selectedWorkStations')
                : t('table.columns.default.selectedElements'),
            {
                accessAttribute: task => task.elements[0]?.name,
            },
        ),
        new ListColumnCreator(
            'assignedTo',
            t('table.columns.default.assignedTo'),
            {
                customComparator: maintainerComparator,
            },
        ),
        new ListColumnCreator('cyclic', t('table.columns.default.cyclic'), {}),
        new ListColumnCreator('active', t('table.columns.default.active'), {}),
    ];

    const columnIds = columns.map(column => column.id);
    const getTaskListItemTemplate = task => (
        <ListItem
            key={task.id}
            onClick={props.onTaskClick(task.id)}
            columns={columnIds}
        >
            {task.name}
            {moment(task.startDate).format('DD/MM/YYYY')}
            <Elements elements={task.elements} />
            {task.assignedTo &&
                `${task.assignedTo.name} ${task.assignedTo.surname}`}
            <CyclicIcon active={task.repeat} />
            <IOSSwitch
                checked={task.active}
                name={task.id}
                onClick={props.onActiveStateChange}
            />
        </ListItem>
    );

    const getMobileTaskListItemTemplate = task => {
        const locationsNumber = task.elements.reduce((set, element) => {
            set.add(element.location.name);
            return set;
        }, new Set()).size;
        return (
            <ListItemMobile
                key={task.id}
                onClick={props.onTaskClick(task.id)}
                classes={columnIds}
            >
                <MobileHeader classes={['date']}>
                    {moment(task.startDate).format('DD/MM/YYYY')}
                </MobileHeader>
                <MobileDataDisplay
                    title={task.name}
                    withoutImage
                    left={t('plurals.location', {count: locationsNumber})}
                    right={t('plurals.element', {count: task.elements.length})}
                />
            </ListItemMobile>
        );
    };

    const {tasks} = props;
    return (
        <>
            <List
                columns={columns}
                rows={tasks}
                rowTemplate={getTaskListItemTemplate}
                name="tasks"
            />
            <MobileList
                columns={columns}
                rows={tasks}
                rowTemplate={getMobileTaskListItemTemplate}
            />
        </>
    );
};

export default TasksList;
