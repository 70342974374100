import React, {Component} from 'react';
import {isEqual} from 'lodash';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {
    List,
    ListItem,
    ListColumnCreator,
    MobileDataDisplay,
} from '../../../common/components/index';
import MobileList from '../../../common/components/list/MobileList';
import MobileListItem from '../../../common/components/list/listItem/ListItemMobile/ListItemMobile';

class LocationsList extends Component {
    state = {
        rows: [],
    };

    columns;

    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.locations,
        };
        const {t} = props;

        this.columns = [
            new ListColumnCreator(
                'name',
                this.props.organizationData.eventBased
                    ? t('table.columns.eventBased.name')
                    : t('table.columns.default.name'),
                {},
            ),
            new ListColumnCreator(
                'branchName',
                this.props.organizationData.eventBased
                    ? t('table.columns.eventBased.workStations')
                    : t('table.columns.default.branches'),
                {
                    accessAttribute: location => location.branch.name,
                },
            ),
        ];
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.locations, prevProps.locations)) {
            this.setState({rows: this.props.locations});
        }
    }

    render() {
        const {rows} = this.state;

        return (
            <>
                <List
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getLocationListItemTemplate}
                    name="locations"
                />
                <MobileList
                    columns={this.columns}
                    rows={rows}
                    rowTemplate={this.getLocationMobileListItemTemplate}
                />
            </>
        );
    }

    getLocationListItemTemplate = location => {
        const {onLocationClick, onRemoveLocation, userData} = this.props;
        return (
            <ListItem
                key={location.id}
                onClick={onLocationClick(location.id)}
                onDelete={() => onRemoveLocation(location.id)}
                deletable={userData.roles.admin}
            >
                <div className="element-name">{location.name}</div>
                {location.branch.name}
            </ListItem>
        );
    };

    getLocationMobileListItemTemplate = location => {
        const {onLocationClick, onRemoveLocation, userData} = this.props;
        return (
            <MobileListItem
                key={location.id}
                onClick={onLocationClick(location.id)}
                onDelete={() => onRemoveLocation(location.id)}
                deletable={userData.roles.admin}
            >
                <MobileDataDisplay title={location.name} withoutImage />
            </MobileListItem>
        );
    };
}

const mapStateToProps = state => ({
    elements: state.element.elements,
    userData: state.auth.userData,
    organizationData: state.auth.organizationData,
});

export default connect(mapStateToProps)(
    withTranslation('locations')(LocationsList),
);
