import React from 'react';
import {useTranslation} from 'react-i18next';
import Dialog from '../../common/components/Dialog/Dialog';
import {CustomTextField} from '../../common/components';

const ChangePasswordDialog = props => {
    const {t} = useTranslation('auth');

    return (
        <Dialog
            dialogTitle={t('changePassword.title')}
            loading={props.loading}
            handleClose={props.onClose}
            submitButtonDisabled={props.submitButtonDisabled}
            onSubmitHandler={props.onSubmit}
            submitButtonText={t('changePassword.actionBtn')}
        >
            <CustomTextField
                label={t('changePassword.email')}
                onChange={props.onChange}
                fullWidth
            />
        </Dialog>
    );
};

export default ChangePasswordDialog;
